<template>
	<bannerSwiper></bannerSwiper>
	<div class="container">
		<div class="navBox flex" style="justify-content: end;">
			<div class="title flex center_center">
				<span class="span1">{{agreementType == 8 ? "会员协议": agreementType ==9?"平台服务协议":'隐私条款'}}</span>
			</div>
			<div class="navText flex">
				当前位置：
				<el-breadcrumb :separator-icon="ArrowRight">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>{{agreementType == 8 ? "会员协议": agreementType ==9?"平台服务协议":'隐私条款'}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="detail" v-html="detail"></div>
	</div>
</template>

<script setup>
	import detailCom from '@/components/newsDetail/com/detail.vue'
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import bannerSwiper from '@/components/product/bannerSwiper.vue'
	import{getInfoApiFn} from '@/utils/getInfoApiFn'
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	import {
		nextTick,
		onMounted,
		ref,
		toRef,
		watchEffect
	} from 'vue'
	let detail = ref('')
	let activeIndex = ref(0);
	const route = useRoute();
	const router = useRouter();
	const agreementType = ref(route.query.type);
	onMounted(async()=>{
		detail.value = await getInfoApiFn(agreementType.value);
	})
</script>

<style lang="scss" scoped>
	@import url('@/assets/css/common_header.scss');

	.menuBox span {
		margin-right: 10px;
	}
	.detail {
		padding: 20px 0 75px;
		color: rgba(108, 108, 108, 1);
		font-size: 14px;
		font-family: SourceHanSansSC-regular;
		
		img {
			max-width: 100%;
		}
	}
</style>
