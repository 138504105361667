import {
	scientificIndexListApi,
	getNavIndexTwoPageApi,
	scientificvideoListApi
} from '@/api/index'
export function scientificIndexListApiFn (data) {
	return scientificIndexListApi(data)
}
export function scientificvideoListApiFn (data) {
	return scientificvideoListApi(data)
}
export function getNavIndexTwoPageApiFn (data) {
	return getNavIndexTwoPageApi(data)
}
