<template>
	<bannerSwiper></bannerSwiper>
	<div class="container">
		<div class="navBox flex">
			<div class="title flex center_center">
				<span class="span1">公司介绍</span>
			</div>
			<div class="menuBox flex" ref='menuBoxRef'>
				<span :class="activeIndex == 0 ? 'on':''" @click='changeActiveIndexFn(0)'>公司简介</span>
				<span :class="activeIndex == 1 ? 'on':''" @click='changeActiveIndexFn(1)'>拥有仪器</span>
				<span :class="activeIndex == 6 ? 'on':''" @click='changeActiveIndexFn(6)'>资质授权</span>
			</div>
			<div class="navText flex">
				当前位置：
				<el-breadcrumb :separator-icon="ArrowRight">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/company_index' }">公司介绍</el-breadcrumb-item>
					<el-breadcrumb-item>{{activeIndex == 0 ? "公司简介":activeIndex == 1 ? "拥有仪器" : "资质授权"}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="detail" v-loading='loading' v-html="detail"></div>
	</div>
</template>

<script setup>
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import bannerSwiper from '@/components/product/bannerSwiper.vue'
	import {
		nextTick,
		onMounted,
		ref,
		toRef,
		watchEffect
	} from 'vue'
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn'
	let detail = ref({})
	let activeIndex =ref(0);
	let loading = ref(true)
	const changeActiveIndexFn=async(type)=>{
		activeIndex.value = type;
		loading.value = true;
		const data = 20+type;
		detail.value =await getInfoApiFn(data);
		loading.value = false;
	}
	onMounted(()=>{
		changeActiveIndexFn(0)
	})
</script>

<style lang="scss" scoped>
	@import url('@/assets/css/common_header.scss');
	.menuBox span{
		margin-right: 10px;
	}
	.detail {
		padding: 20px 0 75px;
		color: rgba(108, 108, 108, 1);
		font-size: 14px;
		font-family: SourceHanSansSC-regular;
		
		::v-deep img{
		    max-width:100%;
		}
	}
</style>
