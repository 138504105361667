import request from '@/utils/request.js'

// 导航列表
export function getNavListApi() {
	return request({
		url: '/app/nav/index',
		method: 'get',
	})
}
// 获取二级菜单数据
export function getNavIndexTwoApi(params) {
	return request({
		url: '/app/nav/indexTwo',
		method: 'get',
		params
	})
}
// 首页 获取二级菜单数据
export function getNavIndexTwoPageApi(params) {
	return request({
		url: '/app/nav/indexTwoPage',
		method: 'get',
		params
	})
}
// 商品推荐列表
export function getRecProductApi() {
	return request({
		url: '/app/product/recProduct',
		method: 'get'
	})
}