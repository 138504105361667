import request from '@/utils/request.js'
// 变更用户信息
export function postUserPerfectApi(params) {
	return request({
		url: '/app/user/perfect',
		method: 'POST',
		params
	})
}
// 公告列表
export function announceListApi(params) {
	return request({
		url: '/app/announce/list',
		method: 'get',
		params
	})
}
// 地址列表
export function addressListApi(params) {
	return request({
		url: '/app/address/index',
		method: 'get',
		params
	})
}
// 新增收货地址
export function addressAddApi(params) {
	return request({
		url: '/app/address/add',
		method: 'post',
		params
	})
}
// 获取地区码
export function areaCodeApi() {
	return request({
		url: '/app/area/district',
		method: 'get',
	})
}
// 获取详情
export function addressDetailApi(params) {
	return request({
		url: '/app/address/detail',
		method: 'get',
		params
	})
}
// 删除地址
export function addressDelApi(params) {
	return request({
		url: '/app/address/del',
		method: 'get',
		params
	})
}
// 收藏列表
export function favoriteListApi(params) {
	return request({
		url: '/app/favorite/list',
		method: 'get',
		params:{
			...params
		}
	})
}
// 订单详情
export function getOrderDetailApi(params) {
	return request({
		url: '/app/order/detail',
		method: 'get',
		params:{
			...params
		}
	})
}
// 维权信息查看
export function getOrderRefundInfoApi(params) {
	return request({
		url: '/app/order/refundInfo',
		method: 'get',
		params:{
			...params
		}
	})
}
// 订单列表
export function getOrderListApi(params) {
	return request({
		url: '/app/order/list',
		method: 'get',
		params:{
			...params
		}
	})
}
// 关闭订单
export function getOrderCloseApi(params) {
	return request({
		url: '/app/order/close',
		method: 'get',
		params:{
			...params
		}
	})
}
// 获取订单自动关闭时间
export function getConfigOrderApi() {
	return request({
		url: '/app/config/order',
		method: 'get',
	})
}
// 查看订单物流信息
export function getOrderExpressApi(params) {
	// console.loglog("params",params)
	return request({
		url: '/app/order/orderExpress',
		method: 'get',
		params:{
			...params
		}
	})
}
// 订单确认收货
export function getOrderReceiveApi(params) {
	return request({
		url: '/app/order/receive',
		method: 'get',
		params:{
			...params
		}
	})
}
// 获取退款订单金额
export function getRefundFeeApi(params) {
	return request({
		url: '/app/order/getRefundFee',
		method: 'POST',
		params:{
			...params
		}
	})
}
// 申请售后
export function postApplyRefundApi(params) {
	return request({
		url: '/app/order/applyRefund',
		method: 'POST',
		params:{
			...params
		}
	})
}
// 获取退款订单列表
export function getRefundOrderListApi(params) {
	return request({
		url: '/app/order/getRefundOrderList',
		method: 'get',
		params:{
			...params
		}
	})
}
// 用户删除订单
export function getUserOrderDelApi(params) {
	return request({
		url: '/app/order/userOrderDel',
		method: 'get',
		params:{
			...params
		}
	})
}
// 获取会员等级
export function getCustomerLevelIndexApi(params) {
	return request({
		url: '/app/customerlevel/index',
		method: 'get',
		params:{
			page:1,
			page_size:3
		}
	})
}
// 添加发票
export function postInvoiceCreateApi(params) {
	return request({
		url: '/app/invoice/create',
		method: 'POST',
		params:{
			...params
		}
	})
}
// 发票列表
export function getInvoiceIndexApi(params) {
	return request({
		url: '/app/invoice/index',
		method: 'get',
		params:{
			...params
		}
	})
}
// 发票详情
export function getInvoiceDetailApi(params) {
	return request({
		url: '/app/invoice/detail',
		method: 'get',
		params:{
			...params
		}
	})
}
// 撤销维权
export function getCancelRefundApi(params) {
	return request({
		url: '/app/order/cancelRefund',
		method: 'get',
		params:{
			...params
		}
	})
}
// 维权订单创建物流信息
export function postRefundExpressApi(params) {
	return request({
		url: '/app/order/refundExpress',
		method: 'POST',
		params:{
			...params
		}
	})
}