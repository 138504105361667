<template>
	<div class="title">{{order_invoicing_type == 1 ? '申请发票':order_invoicing_type == 2 ? '修改发票信息' : '发票详情' }}</div>
	<div v-loading='loading'>
		<div class="statusBox flex between_center">
			<span class="text">
				电子发票与纸质发票具有同等法律效力，可支持报销入账；同时根据增值税管理办法要求，如需为企业开具增值税发票，需提供纳税人识别号或统一社会信用代码，否则该发票无法作为税收凭证。<span style="color:red">申请发票后不可退款</span>
			</span>
		</div>
		<el-form style="width: 600px;margin-top: 20px;" :model="formValue" label-width="120px" :disabled='order_invoicing_type==3'>
			<el-form-item label="发票类型：">
				<el-radio-group v-model="formValue.invoice_type">
					<template v-for='item in invoiceTypeOptions'>
						<el-radio :label="item.value" :name='item.value'>{{item.value}}</el-radio>
					</template>
					
				</el-radio-group>
			</el-form-item>
			<el-form-item label="抬头类型：">
				<el-radio-group v-model="formValue.invoice_title_type">
					<el-radio :label="1" :name='1'>个人</el-radio>
					<el-radio :label="2" :name='2'>企业</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票抬头：">
				<el-input size="small" v-model="formValue.invoice_title" />
			</el-form-item>
			<el-form-item label="纳税人识别号：" v-if='formValue.invoice_title_type == 2'>
				<el-input size="small" v-model="formValue.heading_code" />
			</el-form-item>
			<el-form-item label="发票内容：">
				<el-radio-group v-model="formValue.content_type">
					<el-radio :label="1" :name='1'>商品明细</el-radio>
					<el-radio :label="2" :name='2'>其他</el-radio>
				</el-radio-group>
				<el-input size="small" v-model="formValue.content" v-if='formValue.content_type == 2' />
			</el-form-item>
			<div style="display: flex;justify-content: flex-end;margin-top: 20px;">
				<div class="equitBtn" @click="goBack">{{order_invoicing_type == 3 ? '返回':'取消'}}</div>
				<div class="sureBtn" v-if='order_invoicing_type == 1' @click="sbumitFn">提交申请</div>
			</div>
		</el-form>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref,
		inject,
		computed
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getInvoiceDetailApi,
		postInvoiceCreateApi,
		getInfoTwoApi
	} from '@/api/index.js'
	import orderStatusCom from '@/components/person/order/orderStatusCom.vue'
	import {
		useStore
	} from 'vuex';
import { ElMessage } from 'element-plus';
	const store = useStore();
	const userInfo = computed(() => {
		return store.state.userInfo ? JSON.parse(store.state.userInfo) : ''
	})
	const route = useRoute();
	const router = useRouter()
	let order_invoicing_type = ref(route.query.type); //1  申请发票   2 修改发票   //发票详情
	let formValue = ref({
		customer_id: userInfo.value.id,
		order_id: route.query.orderId,
		invoice_type: '',
		invoice_title_type: 1,
		invoice_title: '',
		heading_code: '',
		content_type: 1,
		content: ''
	})
	const getInvoiceDetailApiFn =async () => {
		const res = await getInvoiceDetailApi({
			id: route.query.id
		});
		formValue.value = res.data;
	}
	const sbumitFn = async()=>{
		// console.log("formValue",formValue.value);
		const res =await postInvoiceCreateApi(formValue.value);
		ElMessage({
			type:"success",
			message:'已提交申请记录',
			onClose() {
				goBack()
			}
		})
	}
	let invoiceTypeOptions = ref([])
	onMounted(async() => {
		const res =await getInfoTwoApi({id:19});
		invoiceTypeOptions.value = res.data
		if (order_invoicing_type.value == 3) {
			getInvoiceDetailApiFn()
		}
	})
	let loading = ref(false);
	const goBack = () => {
		router.back()
	}
</script>

<style lang="scss" scoped>
	:deep(.el-form-item) {
		margin-bottom: 5px;
	}

	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
	}

	.equitBtn {
		width: 100px;
		height: 35px;
		line-height: 35px;
		border-radius: 4px;
		background-color: rgba(239, 239, 239, 0.5);
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		border: 1px solid rgba(187, 187, 187, 1);
		cursor: pointer;
	}

	.sureBtn {
		width: 100px;
		height: 35px;
		line-height: 35px;
		border-radius: 4px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		margin-left: 7px;
		cursor: pointer;
	}

	.statusBox {
		margin-top: 20px;
		padding: 0 10px;
		height: 60px;
		border-radius: 4px;
		background-color: rgba(255, 255, 255, 1);

		.text {
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: left;
			font-family: Roboto;
		}
	}
</style>
