<template>
	<div class="bigBox flex">
		<div class="flex" v-loading="loading">
			<div class="one_item" :class='item.id == activeAddress.id ? "active":""' v-for="(item,index) in addressList"
				@click="chooseAddressFn(item)">
				<img class="selectedImg" src="../../../assets/icon/beenhere.png" />
				<p class="name flex"><span class="line1">{{item.name}}</span><span>{{item.telephone}}</span></p>
				<p class="address line2">{{item.province_name}}{{item.city_name}}{{item.area_name}}{{item.address}}</p>
				<div class="optionBtn flex">
					<span @click.stop="lookAddressFn(item.id)">查看</span>
					<span @click.stop="modifyAddressFn(item.id)">修改</span>

					<span v-if='props.addressType == "addressList"' @click.stop="deleteAddressShowFn(item.id)">删除</span>
					<span v-else @click.stop>
						<el-popconfirm title="确定要删除该地址吗" width="180px" confirm-button-text="确定" cancel-button-text="取消"
							icon-color="#D30E0E" @confirm='deleteAddressFn(item.id)'>
							<template #reference>
								<span>删除</span>
							</template>
						</el-popconfirm>
					</span>

				</div>
			</div>
		</div>
		<div class="addNew flex center_center" @click="addNewAddress()">
			<img src="../../../assets/icon/add-square.png" />
			<span>添加新地址</span>
		</div>
	</div>
	<el-dialog class="cancellationBox" v-model="deleteVisible" title="删除地址提示" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<p class="tipsText">确认删除该条地址么？</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="deleteVisible = false">取消</div>
			<div class="sureBtn" @click="deleteAddressFn()">确定</div>
		</div>
	</el-dialog>
	<el-dialog class="cancellationBox" :title="modifyType=='add' ? '添加收货地址':'修改收货地址'" v-model="modifyVisible"
		width="40%" :append-to-body='true' :lock-scroll='false'>
		<addAddressCom :formValue='formValue' @goBackFn='goBack' @getAddressListApiFn='getAddressListApiFn'>
		</addAddressCom>
	</el-dialog>
	<el-dialog class="cancellationBox" title="收货地址详情" v-model="lookVisible" width="40%" :append-to-body='true'
		:lock-scroll='false'>
		<addAddressCom :formValue='formValue' :lookFlag='true'></addAddressCom>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		watchEffect,
		inject,
		onMounted
	} from 'vue';
	import {
		addressListApi,
		addressDetailApi,
		addressDelApi
	} from '@/api/index'
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	import addAddressCom from '@/components/person/address/addAddressCom.vue'
	import {
		ElMessage
	} from 'element-plus';
	const addressList = ref([]);
	const props = defineProps({
		activeAddress: Object,
		addressType: String
	})
	const emit = defineEmits(['chooseAddress']);
	let activeAddress = ref()
	watchEffect(() => {
		activeAddress = props.activeAddress
	})
	onMounted(() => {
		getAddressListApiFn();
	})
	let loading = ref(true);
	const getAddressListApiFn = () => {
		loading.value = true;
		addressListApi({
			page: 1,
			page_size: 10000
		}).then(res => {
			// console.log("res", res)
			addressList.value = res.data.list;
			modifyVisible.value = false;
			loading.value = false;
		});
	}
	const chooseAddressFn = (item) => {
		if (props.addressType == 'chooseAddress') {
			emit('chooseAddress', item)
		}
	}
	const router = useRouter();
	let modifyVisible = ref(false);
	let modifyType = ref(null);
	let formValue = ref({})
	// 添加新地址
	const addNewAddress = () => {
		// 选择地址时添加新地址
		if (props.addressType == 'chooseAddress') {
			modifyType.value = 'add';
			formValue.value = {}
			modifyVisible.value = true;
		} else {
			//地址管理中添加新地址
			router.push({
				name: 'addArddress',
				query: {
					type: 'add'
				}
			})
		}
	}
	const modifyAddressFn = async (id) => {
		// 选择地址时修改
		if (props.addressType == 'chooseAddress') {
			const data = {
				id
			}
			const res = await addressDetailApi(data);
			formValue.value = res.data;
			formValue.value.addressCode=[formValue.value.province,formValue.value.city,formValue.value.area]
			modifyType.value = 'modify';
			modifyVisible.value = true;
		} else {
			router.push({
				name: 'addArddress',
				query: {
					id,
					type: 'modify'
				}
			})
		}
	}
	const goBack = () => {
		modifyVisible.value = false;
	}
	let deleteVisible = ref(false);
	let deleteAddressId = ref(false);
	const deleteAddressShowFn = (id) => {
		deleteAddressId.value = id;
		if (props.addressType == 'chooseAddress') {

		} else {
			deleteVisible.value = true;
		}
	}
	const deleteAddressFn = (id) => {
		const data = {
			id: id ? id : deleteAddressId.value,
		}
		// // console.log("id",data)
		addressDelApi(data).then(res => {
			ElMessage({
				type: 'success',
				message: "删除成功",
				onClose() {
					deleteVisible.value = false;
					getAddressListApiFn()
				}
			})
		})
	}
	let lookVisible = ref(false);
	const lookAddressFn = async (id) => {
		const data = {
			id
		}
		const res = await addressDetailApi(data);
		formValue.value = res.data;
		formValue.value.addressCode=[formValue.value.province,formValue.value.city,formValue.value.area]
		lookVisible.value = true;
	}
</script>

<style lang="scss" scoped>
	.bigBox {
		flex-wrap: wrap;
		max-height: 670px;
		overflow-y: auto;

		.one_item {
			margin-right: 10px;
			margin-bottom: 10px;
			width: 200px;
			height: 100px;
			border-radius: 4px;
			background-color: rgba(255, 255, 255, 1);
			padding: 12px 12px 8px 12px;
			box-sizing: border-box;
			cursor: pointer;
			position: relative;
			transition: all 0.3s;

			.selectedImg {
				position: absolute;
				width: 20px;
				height: 20px;
				top: 0px;
				right: 0px;
				display: none;
				transition: all 0.3s;
			}

			.name {
				span {
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					font-family: AlibabaPuHui-regular;
				}

				& span:nth-of-type(1) {
					margin-right: 5px;
					width: calc(100% - 120px) !important;
				}

				& span:nth-of-type(2) {
					width: 120px;
				}
			}

			.address {
				color: rgba(16, 16, 16, 1);
				font-size: 12px;
				font-family: AlibabaPuHui-regular;
				margin-top: 5px;
			}

			.optionBtn {
				justify-content: flex-end;
				margin-top: 5px;

				span {
					width: 30px;
					color: #D30E0E;
					font-size: 12px;
					text-align: right;
					font-family: AlibabaPuHui-regular;
				}
			}

			&.active {
				border: 1px dashed #D30E0E;

				.selectedImg {
					display: block;
				}
			}
		}
	}

	.addNew {
		width: 200px;
		height: 100px;
		border-radius: 4px;
		background-color: rgba(255, 255, 255, 1);
		flex-direction: column;
		border: 1px solid transparent;
		cursor: pointer;

		span {
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: center;
			font-family: Roboto;
			margin-top: 5px;
		}

		img {
			width: 30px;
			height: 30px;
		}
	}
</style>
