<template>
	<div class="productNavBox flex between_center">
		<div class="container breadcrumb">
			<el-breadcrumb :separator-icon="ArrowRight">
				<el-breadcrumb-item :to="{ path: '/' }">网站首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/order' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/cart_index' }">购物车</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
	<div class="container carBox" v-loading="loading">
		<template v-if="paySuccess">
			<div class="title">
				您已付款成功
			</div>
			<p class="pText">收货地址：{{orderDetail.address.province_name}}{{orderDetail.address.city_name}}{{orderDetail.address.area_name}}{{orderDetail.address.receive_address}}</p>
			<p class="pText">实际付款：<span>￥{{orderDetail.total_price}}</span></p>
			<div class="btnBox flex">
				<div class="btn1" @click="goOrder">查看订单</div>
				<div class="btn2" @click="goDetail">查看订单详情</div>
			</div>
		</template>
		<template v-else>
			<div class="title">
				订单查询失败
			</div>
			<el-result icon="error" title="查询失败">
				<template #extra>
					<div class="btnBox flex" style="justify-content: center;">
						<div class="btn1" @click="goOrder">返回</div>
						<div class="btn2" @click="goDetail">查看订单详情</div>
					</div>
				</template>
			</el-result>
		</template>
	</div>
</template>

<script setup>
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import {
		ref,
		inject,
		onMounted
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getOrderDetailApi
	} from '@/api/index.js'
	const router = useRouter();
	const route = useRoute();
	let paySuccess = ref(false);
	let orderDetail = ref({address:{}})
	let loading = ref(false);
	onMounted(()=>{
		getOrderDetailApiFn()
	})
	const getOrderDetailApiFn = async () => {
		loading.value = true;
		let res = {}
		res = await getOrderDetailApi({
			id: route.query.order_id
		});
		paySuccess.value = res.data.order_status ===2000 ? true:false;
		orderDetail.value = res.data;
		loading.value = false;
	}
	const goDetail=()=>{
		router.push({
			name: 'order_detail',
			query: {
				id: route.query.order_id,
				type: 'order'
			}
		})
	}
	const goOrder= ()=>{
		router.push({
			name: 'order',
		})
	}
</script>

<style lang="scss" scoped>
	.btnBox {
		margin-top: 20px;
		justify-content: center;
		.btn1 {
			cursor: pointer;
			width: 100px;
			height: 40px;
			line-height: 40px;
			border-radius: 4px;
			background-color: rgba(211, 14, 14, 1);
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			text-align: center;
			font-family: Roboto;
			margin-right: 15px;
			border: 1px solid rgba(211, 14, 14, 1);
		}

		.btn2 {
			cursor: pointer;
			width: 100px;
			height: 40px;
			line-height: 40px;
			border-radius: 4px;
			color: rgba(211, 14, 14, 1);
			font-size: 14px;
			text-align: center;
			font-family: Roboto;
			border: 1px solid rgba(211, 14, 14, 1);
		}
	}

	.pText {
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		text-align: center;
		font-family: AlibabaPuHui-regular;
		margin-bottom: 10px;

		span {
			color: #D30E0E;
		}
	}

	:deep() {
		.router-link-exact-active {
			color: rgba(211, 14, 14, 1);
			font-weight: bolder;
		}
	}

	.productNavBox {
		height: 60px;

		:deep() {
			.el-breadcrumb__item:nth-of-type(3) .el-breadcrumb__inner.is-link {
				color: #D30E0E;
			}
		}
	}

	.carBox {
		padding: 20px 10px;
		box-sizing: border-box;
		background-color: #EFEFEF;
		margin-bottom: 32px;
		min-height: 445px;

		.title {
			color: #101010;
			font-size: 24px;
			font-weight: bolder;
			text-align: center;
			font-family: AlibabaPuHui-medium;
			margin-bottom: 23px;
		}
	}
</style>
