<template>
	<div class="title">物流信息</div>
	<div class='card' v-loading='loading'>
		<div class="redText">物流信息：</div>
		<div class="goodsBox" v-for="(item,index) in logisticsData">
			<div class="flex" style="margin-bottom: 10px;">
				<el-tag class="ml-2" type="success">包裹{{index+1}}</el-tag>
				<div class="code flex" v-if='item.express_type == 2'>
					<div class="code_text">物流类型：{{item.express_type_desc}}</div>

				</div>
				<div class="code flex" v-else>
					<div class="code_text">快递单号：{{item.express_no}}</div>
					<div class="telphone">
						<span @click="copyTextFn(item.express_no)">复制</span>
						<el-divider direction="vertical" />
						<span>物流公司：{{item.express_company_name}}</span>
					</div>
				</div>
			</div>
			<div class="flex" style="flex-wrap: wrap;padding-left: 30px;">
				<div class="goodsBox_item flex" v-for="goods_item in item.product_infos">
					<img :src="goods_item.product_pic_url" />
					<div class="centerBox">
						<div class="goods_title">{{goods_item.product_name}}</div>
						<div class="goods_text">货号：{{goods_item.product_spec_code}}</div>
						<div class="goods_text">规格：{{goods_item.product_spec_title == ''?"普通规格":goods_item.product_spec_title}}</div>
					</div>
					<div class="price">￥{{goods_item.product_spec_price}}×{{goods_item.product_count}}</div>
				</div>
			</div>
			<el-divider></el-divider>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		inject,
		onMounted
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getOrderExpressApi
	} from '@/api/index.js'
	import {
		copyTextFn
	} from "@/utils/copyText";
	const route = useRoute();
	let loading = ref(false);
	let logisticsData = ref({})
	const getOrderExpressApiFn = () => {
		loading.value = true;
		getOrderExpressApi({
			id: route.query.id
		}).then(res => {
			logisticsData.value = res.data;
			loading.value = false;
		})
	}
	onMounted(() => {
		getOrderExpressApiFn()
	})
</script>

<style lang="scss" scoped>
	.goodsBox_item {
		margin-bottom: 20px;
		cursor: pointer;
	}

	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 20px;
	}

	.card {
		max-height: 650px;
		overflow: auto;
		border-radius: 4px;
		background-color: rgba(255, 255, 255, 1);
		padding: 15px 13px;
	}

	.redText {
		color: rgba(211, 14, 14, 1);
		font-size: 18px;
		text-align: left;
		font-family: AlibabaPuHui-regular;
		margin-bottom: 12px;
	}

	.lineInfo {
		line-height: 25px;
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		text-align: left;
		font-family: AlibabaPuHui-regular;
	}

	.goodsBox {
		align-items: center;
		margin-bottom: 20px;

		img {
			width: 90px;
			margin: 0 20px;
		}

		.centerBox {
			width: 390px;
			margin-right: 20px;

			.goods_title {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-weight: bolder;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}

			.goods_text {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}
		}

		.price {
			width: 100px;
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: left;
			font-family: AlibabaPuHui-regular;
			margin-right: 20px;
		}

		.code {
			flex: 1;
			font-family: AlibabaPuHui-regular;
			margin-left: 20px;

			.code_text {
				width: 250px;
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
			}

			.telphone {
				flex: 1;
				color: rgba(16, 16, 16, 1);
				font-size: 12px;
				font-family: AlibabaPuHui-regular;

				span {
					cursor: pointer;
				}
			}
		}
	}
</style>
