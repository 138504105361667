<template>
	<div class="productNavBox flex between_center">
		<div class="container breadcrumb">
			<el-breadcrumb :separator-icon="ArrowRight">
				<el-breadcrumb-item :to="{ path: '/' }">网站首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/order' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/cart_index' }">购物车</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
	<div class="container carBox">
		<div class="title">
			我的购物车
			<span @click="goPage('product_index')">继续选购 ></span>
		</div>
		<el-table :data="orderList" border class="orderTable" :cell-style="{'text-align':'center'}"
			:header-cell-style="{'text-align':'center'}" style="width: 100%;" max-height="550" :empty-text="'暂无商品'"
			ref='tableRef' v-loading='loading' @selection-change="handleSelectionChange">
			<el-table-column type="selection" label="全选" width="80" />

			<el-table-column prop="info" label="商品名称" width="500">
				<template #default="scope">
					<el-row @click="goDetail(scope.row.product_id)" style="cursor: pointer;">
						<el-col :span='3'>
							<img style="width: 100%;" :src="scope.row.sku_pics[0]" />
						</el-col>
						<el-col :span='15'>
							{{scope.row.product_name}} 货号：{{scope.row.spec_code}}
						</el-col>
					</el-row>
				</template>
			</el-table-column>
			<el-table-column prop="spec_title" label="规格" width="130">
				<template #default="scope">
					{{scope.row.spec_title =='' ? '普通规格' :scope.row.spec_title}}
				</template>
			</el-table-column>
			<el-table-column prop="sale_price" label="价格(元)" width="130">
				<template #default="scope">
					￥{{scope.row.sale_price}}
				</template>
			</el-table-column>
			<el-table-column prop="count" label="数量" width="200">
				<template #default="scope">
					<el-input-number size="small" v-model="scope.row.count" :min="1"
						@change="numHandleChange(scope.row)" />
				</template>
			</el-table-column>
			<el-table-column label="小计(元)" width="200">
				<template #default="scope">
					￥{{actionOperationResult(scope.row.sale_price, scope.row.count)}}
				</template>
			</el-table-column>
			<el-table-column prop="name" label="操作">
				<template #default="scope">
					<el-space>
						<el-link :underline="false" @click="favoriteAddApiFn(scope.row.product_id)">移入收藏夹</el-link>
						<el-divider direction="vertical"></el-divider>
						<el-link :underline="false" @click="deleteVisibleFn(scope.row.id)">删除</el-link>
					</el-space>
				</template>
			</el-table-column>
		</el-table>
		<div class="footerBox flex between_center">
			<div class="leftBox">
				<el-space>
					<el-checkbox v-model="selectedAllFlag" :label="selectedAllFlag?'取消全选':'全选'"
						@change='selectedAllFn' />
					<el-link v-if='selectRow.length>0' :underline='false' @click="deleteSelectedFn">删除选中的商品</el-link>
					<el-link v-if='selectRow.length>0' :underline='false'>移入收藏夹</el-link>
				</el-space>
			</div>
			<div class="rightBox flex">
				<span class="span1">已选商品 {{selectRow.length}} 件，合计</span>
				<span class="span2">￥{{totalPrice}}</span>
				<div class="addBtn" :class="selectRow.length==0 ? 'disabled':''" @click="goSettleFn">去结算</div>
			</div>
		</div>
	</div>
	<el-dialog class="cancellationBox" v-model="deleteVisible" title="删除提示" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<p class="tipsText">是否移出购物车</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="deleteVisible = false">取消</div>
			<div class="sureBtn" @click="deleteOneFn">确定</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import {
		ArrowRight
	} from '@element-plus/icons-vue'

	import {
		Decimal
	} from 'decimal.js'
	import {
		computed,
		onMounted,
		ref,
		inject
	} from 'vue'
	import {
		carListApi,
		carBatchDelApi,
		favoriteAddApi,
		carEditApi
	} from '@/api/index'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		actionOperationResult
	} from '@/utils/actionOperationResult'
	import {
		ElMessage
	} from 'element-plus'
	const router = useRouter();
	const goPage = (name, query) => {
		router.push({
			name,
			query
		})
	}
	const goDetail = (id) => {
		router.push({
			name: 'product_detail',
			query: {
				id,
			}
		})
	}
	let loading = ref(false);
	let orderList = ref([]);
	const tableRef = ref(null);
	const pageOptions = ref({
		page: 1,
		page_size: 20,
		total: 0,
	})
	onMounted(() => {
		getCarListApiFn(0)
		const target = tableRef.value.$refs.bodyWrapper.getElementsByClassName("el-scrollbar__wrap")[0];
		target.addEventListener("scroll", () => {
			// 滚动距离
			let scrollTop = target.scrollTop;
			// 变量windowHeight是可视区的高度
			let windowHeight = target.clientHeight || target.clientHeight;
			// 变量scrollHeight是滚动条的总高度
			let scrollHeight = target.scrollHeight || target.scrollHeight;
			// 这里是触底 自己按自己业务需求是写逻辑
			if (scrollTop + windowHeight === scrollHeight) {
				// 获取到的不是全部数据 当滚动到底部 继续获取新的数据
				// console.log("到底了！！！");
				if (pageOptions.value.total > orderList.value.length) {
					pageOptions.value.page = pageOptions.value.page + 1;
					getCarListApiFn(1)
				}
			}
		})
	})
	const favoriteAddApiFn = async (id) => {
		const data = {
			product_id: id
		}
		const res = await favoriteAddApi(data);
		ElMessage({
			type: 'success',
			message: '收藏成功',
		})
	}
	const deleteSelectedFn = () => {
		deleteData.value = {
			ids: []
		};
		for (var i = 0; i < selectRow.value.length; i++) {
			deleteData.value.ids.push(selectRow.value[i].id)
		}
		deleteVisible.value = true;
	}
	const deleteOneFn = () => {
		carBatchDelApiFn()
	}
	const carBatchDelApiFn = async () => {
		await carBatchDelApi(deleteData.value);
		ElMessage({
			type: 'success',
			message: '删除成功',
			onClose() {
				deleteVisible.value = false;
				getCarListApiFn(0)
			}
		})
	}
	const getCarListApiFn = (type) => {
		loading.value = true;
		if (type == 0) {
			orderList.value = [];
			pageOptions.value.page = 1;
		}
		const data = {
			page: pageOptions.value.page,
			page_size: pageOptions.value.page_size
		}
		carListApi(data).then(res => {
			pageOptions.value.total = res.data.total
			orderList.value = [...orderList.value, ...res.data.list];
			loading.value = false;
		})
	}
	let deleteVisible = ref(false);
	let deleteData = ref('')
	const deleteVisibleFn = (id) => {
		deleteData.value = {
			ids: [id]
		};
		deleteVisible.value = true;
	}
	const numHandleChange = (obj) => {
		// console.log(obj);
		const data={
			id:obj.id,
			count:obj.count,
			method:1
		}
		carEditApi(data);
	}
	let selectRow = ref([])
	const handleSelectionChange = (obj) => {
		selectRow.value = obj;
	}
	const totalPrice = computed(() => {
		let total = 0;
		for (var i = 0; i < selectRow.value.length; i++) {
			const num1 = new Decimal(total);
			const num2 = new Decimal(actionOperationResult(selectRow.value[i].count, selectRow.value[i]
				.sale_price));

			total = num1.plus(num2);
		}
		return total
	})
	const selectedAllFlag = computed(() => {
		return selectRow.value.length!=0 && selectRow.value.length == orderList.value.length ? true : false
	})
	const selectedAllFn = () => {
		const flag = !selectedAllFlag.value;
		orderList.value.forEach((row) => {
			tableRef.value.toggleRowSelection(row, flag)
		})
	}
	const goSettleFn = () => {
		if (selectRow.value.length == 0)
			return;
		sessionStorage.setItem('order_carId', JSON.stringify(selectRow.value));
		goPage('cart_order_info')
	}
</script>

<style lang="scss" scoped>
	:deep() {
		.router-link-exact-active {
			color: rgba(211, 14, 14, 1);
			font-weight: bolder;
		}
	}

	.productNavBox {
		height: 60px;

		:deep() {
			.el-breadcrumb__item:nth-of-type(3) .el-breadcrumb__inner.is-link {
				color: #D30E0E;
			}
		}
	}

	.carBox {
		padding: 20px 10px;
		box-sizing: border-box;
		background-color: #EFEFEF;
		margin-bottom: 32px;
		min-height: 445px;

		.title {
			color: #101010;
			font-size: 24px;
			font-weight: bolder;
			text-align: center;
			font-family: AlibabaPuHui-medium;
			margin-bottom: 23px;
			position: relative;

			span {
				position: absolute;
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-weight: bolder;
				bottom: 5px;
				right: 0px;
				cursor: pointer;
			}
		}

		.footerBox {
			margin-top: 10px;
			padding: 0 14px 0 14px;
			height: 60px;
			background-color: rgba(255, 255, 255, 1);
			align-items: center;

			.rightBox {
				align-items: center;

				.span1 {
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					font-family: AlibabaPuHui-regular;
				}

				.span2 {
					color: rgba(211, 14, 14, 1);
					font-size: 20px;
					font-weight: bolder;
					font-family: AlibabaPuHui-medium;
					margin: 0 12px 0 8px;
				}

				.addBtn {
					width: 150px;
					height: 40px;
					line-height: 40px;
					border-radius: 30px;
					background-color: rgba(211, 14, 14, 1);
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					text-align: center;
					font-family: Roboto;
					cursor: pointer;

					&.disabled {
						background-color: #efefef;
						color: #303030;
						cursor: not-allowed;
					}

					&.disabled:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}
</style>
