<template>
	<el-space>
		<el-link v-if='comType == 0' :underline="false" @click="goPage('order_detail')">订单详情</el-link>
		<el-link v-else-if='comType == 1' :underline="false" @click="goPage('order_logistics')">查看物流</el-link>
		<el-link v-else-if='comType == 2' :underline="false" @click="deleteOrderFn">删除订单</el-link>
		<el-link v-else-if='comType == 3' :underline="false" @click="goPage2('order_invoicing')">申请开票</el-link>
		<div class="btn" v-else-if='comType == 4' @click="goPage('cart_settlement')">去付款</div>
		<el-link v-else-if='comType == 5' :underline="false" @click="cancellationOrderFn">取消订单</el-link>
		<div class="btn" v-else-if='comType == 7' @click="receiveOrderFn">确定收货</div>
		<div class="btn" v-else-if='comType == 8' @click="returnOrderFn">填写物流单号</div>
		<el-link v-else-if='comType == 9' :underline="false" @click="cancelRefundFn()">取消售后申请</el-link>
		<el-link v-else-if='comType == 10' :underline="false" @click="goPage('order_detail')">售后详情</el-link>
		<div v-else>{{comType}}</div>
	</el-space>
	<el-dialog class="cancellationBox" v-model="cancelRefundVisible" title="撤销维权记录提示" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<p class="tipsText">确定要撤销该维权记录吗？</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="cancelRefundVisible = false">取消</div>
			<div class="sureBtn" @click="getCancelRefundApiFn()">确定</div>
		</div>
	</el-dialog>
	<el-dialog class="cancellationBox" v-model="cancellationVisible" title="取消订单提示" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<p class="tipsText">确定要取消该订单吗？</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="cancellationVisible = false">取消</div>
			<div class="sureBtn" @click="getOrderCloseApiFn()">确定</div>
		</div>
	</el-dialog>
	<el-dialog class="cancellationBox" v-model="deleteVisible" title="删除订单提示" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<p class="tipsText">确定要删除该订单吗？</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="deleteVisible = false">取消</div>
			<div class="sureBtn" @click="getUserOrderDelApiFn()">确定</div>
		</div>
	</el-dialog>
	<el-dialog class="cancellationBox" v-model="returnVisible" title="提交退货物流单号" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<el-input v-model="codeInput" placeholder="请填写物流单号" />
		<div class="flex btnBox">
			<div class="equitBtn" @click="returnVisible = false">取消</div>
			<div class="sureBtn" @click='postRefundExpressApiFn'>确定</div>
		</div>
	</el-dialog>
	<el-dialog class="cancellationBox" v-model="receiveVisible" title="确认收货提示" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<p class="tipsText">您是已经收到货物，确定收货吗？</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="receiveVisible = false">取消</div>
			<div class="sureBtn" @click='getOrderReceiveApiFn'>确定</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import {
		ElMessage
	} from 'element-plus';
	import {
		ref,
		inject,
		watchEffect
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getOrderCloseApi,
		getOrderReceiveApi,
		getUserOrderDelApi,
		getCancelRefundApi,
		postRefundExpressApi
	} from '@/api/index'
	const router = useRouter();
	const getOrderDetailApiFn = inject('getOrderDetailApiFn');
	const props = defineProps({
		orderId: Number,
		orderNo: String,
		comType: Number,
		orderType: String
	})
	let orderId = ref('');
	let orderNo = ref('');
	let comType = ref('');
	let orderType = ref('')
	watchEffect(() => {
		orderId.value = props.orderId;
		orderNo.value = props.orderNo;
		comType.value = props.comType;
		orderType.value = props.orderType
	})
	const emit = defineEmits(['getOrderListApiFn']);
	const detailRefundFn = ()=>{
		router.push({
			name: 'detailRefund',
			query: {
				order_id: orderId.value
			}
		})
	}
	const goPage = (name) => {
		if (name == 'cart_settlement') {
			router.push({
				name: name,
				query: {
					order_id: orderId.value
				}
			})
		} else if (name == 'order_logistics') {
			router.push({
				name: name,
				query: {
					id: orderId.value,
				}
			})
		} else {
			router.push({
				name: name,
				query: {
					id: orderId.value,
					type: orderType.value
				}
			})
		}

	}
	const goPage2 = (name) => {
		router.push({
			name: name,
			query: {
				orderId: orderId.value,
				type: 1,
			}
		})
	}
	// // 撤销维权订单
	let cancelRefundVisible = ref(false)
	const cancelRefundFn = () => {
		cancelRefundVisible.value = true
	}
	const getCancelRefundApiFn = async () => {
		const res = await getCancelRefundApi({
			id: orderId.value
		});
		ElMessage({
			type: 'success',
			message: '已关闭维权订单',
			onClose() {
				cancelRefundVisible.value = false
				emit('getOrderListApiFn', 0)
			}
		})
	}
	// 取消订单
	let cancellationVisible = ref(false)
	const cancellationOrderFn = () => {
		cancellationVisible.value = true
	}
	const getOrderCloseApiFn = async () => {
		// console.log("0000")
		const res = await getOrderCloseApi({
			order_no: orderNo.value
		});
		ElMessage({
			type: 'success',
			message: '已关闭订单',
			onClose() {
				cancellationVisible.value = false
				emit('getOrderListApiFn', 0)
			}
		})
	}
	// 删除订单
	let deleteVisible = ref(false)
	const deleteOrderFn = () => {
		deleteVisible.value = true
	}
	const getUserOrderDelApiFn = async () => {
		// console.log("0000")
		const res = await getUserOrderDelApi({
			order_no: orderNo.value
		});
		ElMessage({
			type: 'success',
			message: '已删除订单',
			onClose() {
				deleteVisible.value = false
				emit('getOrderListApiFn', 0)
			}
		})
	}
	// 填写快递单号
	let returnVisible = ref(false)
	const returnOrderFn = () => {
		returnVisible.value = true
	}
	const codeInput = ref('');
	const postRefundExpressApiFn =async ()=>{
		const res = await postRefundExpressApi({
			express_no: codeInput.value,
			express_desc:'',
			express_company:'',
			id: orderId.value
		});
		
		ElMessage({
			type: 'success',
			message: '已提交快递单号',
			onClose() {
				deleteVisible.value = false
				emit('getOrderListApiFn', 0)
			}
		})
	}
	// 确定收货
	let receiveVisible = ref(false)
	const receiveOrderFn = () => {
		receiveVisible.value = true
	}
	const getOrderReceiveApiFn = () => {
		const data = {
			order_no: orderNo.value
		}
		getOrderReceiveApi(data).then(res => {
			ElMessage({
				type: 'success',
				message: '收货完成',
				onClose() {
					receiveVisible.value = false;
					try {
						if (getOrderDetailApiFn) {
							getOrderDetailApiFn()
						}
						emit('getOrderListApiFn', 0)
					} catch (err) {
						// console.log("err", err)
					}
				}
			})
		})
	}
</script>
<style lang="scss">
	.cancellationBox {
		.el-dialog__header {
			background-color: #efefef;
			margin-right: 0px;
			height: 50px;
			padding: 0px 0 0 20px;
			display: flex;
			align-items: center;

			.el-dialog__headerbtn {
				top: 0px;
				width: 50px;
				height: 50px;
			}
		}

		.el-dialog__body {
			padding: 30px 20px 20px;
		}
	}

	.cancellationBox {
		.tipsText {
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			font-weight: bolder;
			text-align: center;
			font-family: SourceHanSansSC-regular;
		}

		.btnBox {
			margin-top: 27px;
			justify-content: flex-end;
		}

		.equitBtn {
			width: 58px;
			height: 30px;
			line-height: 30px;
			border-radius: 4px;
			background-color: rgba(239, 239, 239, 0.5);
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: center;
			font-family: Roboto;
			border: 1px solid rgba(187, 187, 187, 1);
			cursor: pointer;
		}

		.sureBtn {
			width: 60px;
			height: 32px;
			line-height: 32px;
			border-radius: 4px;
			background-color: rgba(211, 14, 14, 1);
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			text-align: center;
			font-family: Roboto;
			margin-left: 7px;
			cursor: pointer;
		}
	}
</style>
<style lang="scss" scoped>
	:deep(.el-link__inner) {
		line-height: 30px;
	}

	.btn {
		min-width: 70px;
		height: 30px;
		line-height: 30px;
		border-radius: 4px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		padding: 0 10px;
		cursor: pointer;
	}
</style>
