<template>
	<div class='one-item flex' v-for="(item,index) in newsList" @click="goPage(item)">
		<!-- {{item}} -->
		<img v-if='item.is_new == 1' src="../../../assets/icon/ze-new.png" />
		<div class="title line1" :class='item.is_new == 0 ? "title_long":""'>{{item.title}}</div>
		<div class="time">{{item.publish_time}}</div>
	</div>
</template>

<script setup>
	import {
		ref,
		inject,
		watchEffect
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	const props = defineProps({
		newsList: Array,
		firstId: Number,
		secondId:Number,
		type: Number
	})
	let newsList = ref([]);
	let firstId = ref(0);
	let secondId = ref(0);
	watchEffect(() => {
		newsList.value = props.newsList;
		firstId.value = props.firstId;
		secondId.value = props.secondId;
	})
	const goPage = (item) => {
		// console.log('-----');
		let data={
			infoId:item.id,
			id: props.firstId,
			type: props.type
		}
		if(secondId.value !=props.firstId){
			data.secondId = secondId.value ;
		}
		router.push({
			name: 'scientific_detail',
			query: data
		})
	}
</script>

<style lang="scss" scoped>
	.one-item {
		align-items: center;
		height: 44px;
		border-bottom: 1px dashed rgba(187, 187, 187, 1);
		cursor: pointer;

		img {
			width: 16px;
			height: 16px;
		}

		.title {
			width: 65%;
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			transition: all 0.5s;

			&.title_long {
				width: calc(65% + 16px);
			}
		}

		.time {
			flex: 1;
			text-align: right;
			color: rgba(51, 51, 51, 1);
			font-size: 14px;
			transition: all 0.3s;
		}
	}
	// .title:hover + .time {
	// 	color: red;
	// }
	// .time:hover {
	// 	color: red;
	// }
	.one-item:hover .time {
		color: rgba(211, 14, 14, 1);
	}
	.one-item:hover .title {
		color: rgba(211, 14, 14, 1);
	}
</style>
