<template>
	<div class='one-item' v-for="item in newsList" @click="goPage(item.id)">
		<img :src="item.cover_url" />
		<div class="item_on_title flex">
			<p class="line1">{{item.title}}</p>
			<text class="line1" :class=" item.class_id %2!=0 ? 'type_dan':'type_shuang'">{{item.class_name}}</text>
		</div>
	</div>
</template>

<script setup>
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	const route = useRoute();
	import {
		ref,
		watchEffect,
		inject
	} from 'vue'
	const props = defineProps({
		newsList: Array,
		firstId: Number,
		secondId: Number,
		type: Number
	})
	let newsList = ref([]);
	let firstId = ref(0);
	let secondId = ref(0);
	watchEffect(() => {
		newsList.value = props.newsList;
		firstId.value = props.firstId;
		secondId.value = props.secondId;
	})
	const goPage = (id) => {
		if (route.query.id) {
			let query = {
				id: route.query.id,
				infoId: id,
				type: route.query.type,
			}
			if (route.query.secondId) {
				query.secondId = route.query.secondId
			}
			router.push({
				name: 'scientific_detail',
				query
			})
		} else {
			let data = {
				infoId: id,
				id: props.firstId,
				type: props.type
			}
			if (secondId.value != props.firstId) {
				data.secondId = secondId.value;
			}
			router.push({
				name: 'scientific_detail',
				query: data
			})
		}

	}
</script>

<style lang="scss" scoped>
	.one-item {
		width: 274px;
		height: 175px;
		border-radius: 4px;
		padding: 6px 8px 0;
		margin-bottom: 10px;
		box-sizing: border-box;
		background-color: #fff;
		cursor: pointer;
		border: 1px solid rgba(239, 239, 239, 1);
		transition: all 0.3s;

		&:nth-of-type(6),
		&:nth-of-type(7),
		&:nth-of-type(8),
		&:nth-of-type(9),
		&:nth-of-type(10) {
			margin-bottom: 0px;
		}

		img {
			width: 100%;
			height: 130px;
		}

		.item_on_title {
			margin: 3px 0 0 0;
			align-items: center;

			p {
				max-width: calc(100% - 100px);
				width: auto;
			}

			text {
				max-width: 90px;
				padding: 0 5px;
				box-sizing: border-box;
				height: 24px;
				line-height: 24px;
				border-radius: 4px;
				font-size: 12px;
				text-align: center;
				font-family: Roboto;
				color: rgba(16, 16, 16, 1);
				margin-left: 10px;
			}
		}
	}

	.one-item:hover {
		transform: translateY(-5px);
		border: 1px solid #D30E0E;
	}
</style>
