<template>
	<div class='chargeBox'>
		<img @click="closeActivateVipFn()" class='close' src="../assets/icon/close.png" />
		<div class="headerBox flex">
			<img class="headerImg" :src="userInfo.head_pic_url == ''?'../assets/img/logo.png':userInfo.head_pic_url" />
			<p>（{{userInfo.telephone ? getPhone(userInfo.telephone) :''}} 登录）</p>
		</div>
		<div class="priceBox flex">
			<div class="priceBox_left">
				<div class="flex">
					<div class="one_item" v-for=" (item,index) in vipPriceList">
						<div class="title" :class='vipPrice == index ? "active":""' @click="changePayNumFn(index)">
							{{item.name}}
						</div>
						<div class="price" :class='vipPrice == index ? "active border":""'>
							<p class="tip4">平台特惠
							</p>
							<p class="tip1">{{item.level_date}}</p>
							<p class="tip2">￥{{item.price}}</p>
							<p class="tip3">{{item.explain}}</p>
							<div v-for='(item,index) in 4' class="div" :class='"div"+(index+1)'></div>
						</div>
					</div>
				</div>
				<div class='desc line3' v-if='vipPriceList.length!=0'>{{vipPriceList[vipPrice].remark}}</div>
			</div>
			<div class="priceBox_right" v-if='vipPriceList.length!=0'>
				<div class="price_center">
					<p class="price">￥{{vipPriceList[vipPrice].price}}</p>
					<p class="youhui">{{vipPriceList[vipPrice].explain}}</p>
				</div>
				<div class="ercodeBox" v-loading='loading'>
					<template v-if='!agreeFlag'>
						<iframe :srcdoc="alipayForm" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"
							width="150" height="150">
						</iframe>
						<div class="mark">
							<p class="tips1">开通前请阅读</p>
							<p class="tips2" @click="goPage">《会员协议》</p>
							<div class="agreeBtn" @click="agreeFlag = true">同意并支付</div>
						</div>
					</template>
					<template v-else>
						<iframe :srcdoc="alipayForm" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"
							width="150" height="150">
						</iframe>
					</template>
				</div>
				<div class="payMetBox flex center_center">
					<!-- <img src="../assets/icon/ze-mail.png" />
					<img src="../assets/icon/ze-mail.png" /> -->
				</div>
				<div class="xieyi" @click="goPage">《会员协议》</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		getCustomerLevelIndexApi,
		orderVipPayApi,
		getVipOrderDetailApi,
		getUserInfoApi
	} from '@/api/index.js'
	import {
		ElMessage
	} from 'element-plus';
	import {
		onMounted,
		computed,
		ref
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	import {
		useStore
	} from 'vuex';
	const store = useStore();
	const userInfo = computed(() => {
		return store.state.userInfo ? JSON.parse(store.state.userInfo) : ''
	})
	const route = useRoute();
	const router = useRouter();
	const emit = defineEmits(['closeFn'])
	const vipPriceList = ref([]);
	let vipPrice = ref(0);
	let buyVipFlag = ref(false);
	let agreeFlag = ref(false);
	let timer = ref(null);
	let loading = ref(false);
	let order_no = ref('')
	const closeActivateVipFn = () => {
		emit('closeFn');
		clearInterval(timer.value)
		timer.value = null
	}
	const goPage = () => {
		router.push({
			name: 'agreement',
			query: {
				type: 8
			}
		})
	}
	const getPhone = (phone) => {
		if (phone == '')
			return ''
		const data = "" + phone[0] + phone[1] + phone[2] + '****' + phone[7] + phone[8] + phone[9] + phone[10]
		return data
	}
	const getvipPriceListFn = async () => {
		const res = await getCustomerLevelIndexApi();
		vipPriceList.value = res.data.list;
	}
	let alipayForm = ref('')
	onMounted(async () => {
		await getvipPriceListFn();
		await changePayNumFn(0)
	})
	const changePayNumFn = (index) => {
		vipPrice.value = index;
		loading.value = true;
		clearInterval(timer.value)
		timer.value = null;
		orderVipPayApi({
			level_id: vipPriceList.value[index].id,
			pay_type: 20
		}).then(res => {
			alipayForm.value = res.data.data;
			order_no.value = res.data.order_no;
			loading.value = false;
			timer.value = setInterval(() => {
				getVipOrderStatusFn()
			}, 2000)
		})
	}
	const getVipOrderStatusFn = async () => {
		const res = await getVipOrderDetailApi({
			order_no: order_no.value
		});
		if (res.data.pay_status === 1) { //已支付
			clearInterval(timer.value)
			timer.value = null;
			const userInfo = await getUserInfoApi();
			store.state.userInfo = JSON.stringify(userInfo.data);
			localStorage.setItem('userInfo', JSON.stringify(userInfo.data))
			ElMessage({
				type: 'success',
				message: '支付成功',
				onClose() {
					closeActivateVipFn()
				}
			})
		}
	}
</script>

<style lang="scss" scoped>
	@keyframes activeAni {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.chargeBox {
		width: 660px;
		height: 399px;
		border-radius: 4px;
		background-color: rgba(70, 79, 97, 0.9);
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		margin: auto;
		padding: 10px;
		box-sizing: border-box;
		z-index: 999;

		.close {
			position: absolute;
			top: 5px;
			right: 5px;
			width: 16px;
			height: 16px;
			cursor: pointer;
		}

		.headerBox {
			align-items: center;

			.headerImg {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				margin-right: 8px;
			}

			p {
				color: rgba(255, 255, 255, 1);
				font-size: 16px;
				font-family: AlibabaPuHui-regular;
			}
		}

		.priceBox {
			margin-top: 10px;

			.priceBox_left {
				width: 440px;
				height: 330px;
				border-radius: 16px;
				background-color: rgba(31, 32, 59, 1);
				margin-right: 10px;
				padding: 28px 20px;
				box-sizing: border-box;

				.one_item {
					flex: 1;

					.title {
						height: 37px;
						color: rgba(187, 187, 187, 1);
						font-size: 16px;
						font-weight: bolder;
						text-align: center;
						font-family: SourceHanSansSC-regular;
						border-bottom: 1px solid rgba(33, 84, 118, 1);
						cursor: pointer;
						transition: all 0.5s;
					}

					.title.active {
						color: rgba(252, 202, 0, 1);
						border-bottom: 1px solid rgba(252, 202, 0, 1);
						position: relative;
					}

					.title.active::after {
						position: absolute;
						content: '';
						bottom: 0px;
						left: 0px;
						right: 0px;
						margin: auto;
						width: 0px;
						height: 0px;
						border: 5px solid transparent;
						border-bottom: 5px solid #FCCA00;
						animation: activeAni 0.5s linear 3 forwards;
					}

					.price {
						width: 95%;
						height: 184px;
						margin: 13px auto 0;
						border-radius: 8px;
						background-color: rgba(44, 45, 71, 1);
						border: 1px solid transparent;
						transition: all 0.5s;

						.tip4 {
							width: 72px;
							height: 25px;
							line-height: 25px;
							border-radius: 8px 0px 8px 0px;
							background: linear-gradient(143.37deg, rgba(255, 248, 29, 1) 31.48%, rgba(252, 202, 0, 1) 78.68%);
							color: rgba(116, 78, 32, 1);
							font-size: 12px;
							text-align: center;
							font-family: Roboto;
							position: relative;
							z-index: 1;
						}

						.tip1 {
							margin-top: 28px;
							color: rgba(255, 255, 255, 1);
							font-size: 14px;
							text-align: center;
							font-family: SourceHanSansSC-regular;
						}

						.tip2 {
							margin-top: 3px;
							color: rgba(255, 255, 255, 1);
							font-size: 18px;
							font-weight: bolder;
							text-align: center;
							font-family: SourceHanSansSC-bold;
						}

						.tip3 {
							margin-top: 32px;
							color: rgba(255, 255, 255, 1);
							font-size: 14px;
							text-align: center;
							font-family: SourceHanSansSC-regular;
						}
					}
				}

				.desc {
					margin-top: 8px;
					color: rgba(108, 108, 108, 1);
					font-size: 12px;
					text-align: left;
					font-family: SourceHanSansSC-regular;
				}
			}

			.priceBox_right {
				flex: 1;

				.price_center {
					padding-bottom: 15px;
					border-bottom: 1px dashed rgba(187, 187, 187, 1);

					.price {
						color: rgba(255, 191, 107, 1);
						font-size: 48px;
						font-weight: bolder;
						text-align: center;
						font-family: SourceHanSansSC-bold;
					}

					.youhui {
						margin: 5px auto 0;
						width: 85px;
						height: 25px;
						line-height: 25px;
						border-radius: 8px;
						background-color: rgba(211, 14, 14, 1);
						color: rgba(255, 255, 255, 1);
						font-size: 12px;
						text-align: center;
						font-family: Roboto;
					}
				}

				.ercodeBox {
					width: 150px;
					height: 150px;
					margin: 15px auto 0;
					position: relative;

					img {
						width: 150px;
						height: 150px;
					}

					.mark {
						background-color: rgba(51, 51, 51, 0.75);
						position: absolute;
						top: 0px;
						left: 0px;
						width: 150px;
						height: 150px;
						padding-top: 34px;
						box-sizing: border-box;

						.tips1 {
							color: rgba(255, 255, 255, 1);
							font-size: 14px;
							font-weight: bolder;
							text-align: center;
							font-family: SourceHanSansSC-regular;
						}

						.tips2 {
							color: #D8A35D;
							font-size: 14px;
							font-weight: bolder;
							text-align: center;
							font-family: SourceHanSansSC-regular;
							cursor: pointer;
						}

						.agreeBtn {
							margin: 37px auto 0;
							width: 85px;
							height: 25px;
							line-height: 25px;
							border-radius: 30px;
							background-color: rgba(255, 191, 107, 1);
							color: rgba(44, 45, 71, 1);
							font-size: 12px;
							text-align: center;
							font-family: Roboto;
							cursor: pointer;
						}
					}
				}

				.payMetBox {
					margin: 10px 0 0px;

					img {
						width: 25px;
						height: 25px;
						cursor: pointer;
					}

					img:nth-of-type(1) {
						margin-right: 15px;
					}
				}

				.xieyi {
					color: rgba(255, 255, 255, 1);
					font-size: 12px;
					text-align: center;
					font-family: SourceHanSansSC-regular;
					cursor: pointer;
				}
			}
		}

	}

	.border {
		position: relative;
		overflow: hidden;

		.div {
			position: absolute;
			background: linear-gradient(to right, rgba(13, 12, 10, 1.0), rgba(252, 202, 0, 1), cyan);
		}
	}

	.border .div1 {
		width: 100%;
		height: 2px;
		top: 0;
		left: 0;
		animation: run1 1s linear infinite;
	}

	.border .div2 {
		top: 0;
		right: 0;
		height: 100%;
		width: 2px;
		animation: run2 1s linear infinite;
	}

	.border .div3 {
		bottom: 0;
		left: 0;
		height: 2px;
		width: 100%;
		animation: run3 1s linear infinite;
	}

	.border .div4 {
		top: 0;
		left: 0;
		height: 100%;
		width: 2px;
		animation: run4 1s linear infinite;
	}

	@keyframes run1 {
		from {
			transform: translateX(-100%)
		}

		to {
			transform: translateX(100%)
		}
	}

	@keyframes run2 {
		from {
			transform: translateY(-100%)
		}

		to {
			transform: translateY(100%)
		}
	}

	@keyframes run3 {
		from {
			transform: translateX(100%)
		}

		to {
			transform: translateX(-100%)
		}
	}

	@keyframes run4 {
		from {
			transform: translateY(100%)
		}

		to {
			transform: translateY(-100%)
		}
	}
</style>
