<template>
	<div class="classifyBox">
		<div class="title">{{props.title}}</div>
		<div class="classifyMenu flex between_center">
			<div class="one_item line1" v-for="(item,index) in classifyList" :class="activeIndex == index ?'on':''"
				@click="changeActiveIndex(index)">{{item.name}}
			</div>
			<div class="one_item" :class="activeIndex == classifyList.length ?'on':''" @click="goMore">查看更多&nbsp;></div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		watchEffect
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	const props = defineProps({
		classifyList: Array,
		activeIndex: Number,
		title: String,
		firstId:Number,
		type:Number
	})
	const emit = defineEmits(['getDataListFn']);
	const classifyList = ref([]);
	const activeIndex = ref('');
	watchEffect(() => {
		activeIndex.value = props.activeIndex;
		classifyList.value = props.classifyList
	});
	const changeActiveIndex = (index) => {
		emit('getDataListFn', index);
	}
	const goMore = () => {
		let data={}
		data.name = 'scientific_list'
		data.query = {
			id: props.firstId,
			type:props.type
		}
		// console.log(data)
		router.push(data)
	}
</script>

<style lang="scss" scoped>
	.classifyBox {
		width: 220px;
		height: 360px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		padding: 19px 13px;
		box-sizing: border-box;

		.title {
			height: 50px;
			line-height: 40px;
			color: rgba(255, 255, 255, 1);
			font-size: 24px;
			font-weight: bolder;
			font-family: SourceHanSansSC-regular;
			position: relative;
		}

		.title::after {
			content: '';
			width: 88px;
			height: 2px;
			background-color: rgba(255, 255, 255, 1);
			position: absolute;
			bottom: 0px;
			left: 0px;
		}

		.classifyMenu {
			margin-top: 53px;
			flex-wrap: wrap;

			.one_item {
				width: 92px;
				height: 40px;
				line-height: 40px;
				border-radius: 30px;
				color: rgba(255, 255, 255, 1);
				font-size: 14px;
				text-align: center;
				font-family: Roboto;
				border: 1px solid rgba(255, 255, 255, 1);
				margin-bottom: 8px;
				cursor: pointer;
				transition: all 0.5s;

				&.on {
					background-color: rgba(255, 255, 255, 1);
					color: rgba(211, 14, 14, 1);
					font-weight: bolder;
				}
			}
		}
	}
</style>
