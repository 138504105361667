<template>
	<div class="info">
		<div class="title">{{detail.data.title}}</div>
		<div class="time">发布时间：{{detail.data.created_at}}</div>
		<div class="summary">摘要：{{detail.data.summary}}</div>
		<div class="detail" v-html="detail.data.content"></div>
		<div class="tipsBox flex between_center" v-if='props.tipsFlag'>
			<div class="tips" v-if='detail.prev && detail.prev.title' @click="changeNewsIdFn(1)" >上一篇：{{detail.prev.title}}</div>
			<div class="tips2" v-else >上一篇：暂无内容</div>
			<div class="tips" v-if='detail.next && detail.next.title' @click="changeNewsIdFn(2)">下一篇：{{detail.next.title}}</div>
			<div class="tips2" v-else>下一篇：暂无内容</div>
		</div>
	</div>
</template>

<script setup>
	import{ ref, watchEffect } from 'vue';
	import {
		ElMessage
	} from 'element-plus';
	const props = defineProps({
		detail: Object,
		tipsFlag:Boolean
	})
	const emit =defineEmits(['changeNewsId'])
	let detail =ref();
	watchEffect(()=>{
		detail.value = props.detail
	})
	const changeNewsIdFn = (type)=>{
		emit("changeNewsId",type == 1 ? detail.value.prev.id : detail.value.next.id)
	}
</script>

<style lang="scss" scoped>
	.info {
		margin: 30px 0;
	
		.title {
			color: rgba(51, 51, 51, 1);
			font-size: 28px;
			font-weight: bolder;
			text-align: center;
			font-family: SourceHanSansSC-medium;
		}
	
		.time {
			height: 60px;
			line-height: 60px;
			color: rgba(51, 51, 51, 1);
			font-size: 12px;
			text-align: center;
			font-family: SourceHanSansSC-light;
			border-bottom: 1px solid rgba(190, 190, 190, 1);
		}
		.summary{
			color: rgba(51, 51, 51, 1);
			margin: 20px auto;
			font-size: 12px;
			text-align: center;
			font-family: SourceHanSansSC-light;
		}
		.detail {
			padding: 20px 0 75px;
			color: rgba(108, 108, 108, 1);
			font-size: 14px;
			font-family: SourceHanSansSC-regular;
	
			 ::v-deep img{
			     max-width:100%;
			 }
		}
	
		.tipsBox {
			.tips {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-family: AlibabaPuHui-regular;
				cursor: pointer;
				&:hover{
					color:rgba(211, 14, 14, 1);
					font-weight: bolder;
				}
			}
			.tips2{
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-family: AlibabaPuHui-regular;
			}
		}
	}
</style>