<template>
	<div class="statusBox flex between_center">
		<span class="text" v-if='status == 1000'>
			订单状态：未付款，请于订单创建 {{auto_close_time}} 分内进行付款！
		</span>
		<span class="text" v-else-if='status'>
			订单状态：{{statusOptions[status].status_desc}}
		</span>
		
		<span class="btn" v-if='status == 4001' @click="goPage()">再次选购</span>
		<template v-if='status == 1000'>
			<!-- 去支付 -->
			<orderOptionsCom :comType='4' :orderId='orderId' :orderNo='orderNo'></orderOptionsCom>
		</template>
		<template v-if='status == 3000'>
			<!-- 确定收货 -->
			<orderOptionsCom :comType='7' :orderId='orderId' :orderNo='orderNo'></orderOptionsCom>
		</template>
		<template v-if='status == 2'>
			<!-- 提交退货物流订单号 -->
			<orderOptionsCom :comType='8' :orderId='orderId' :orderNo='orderNo'></orderOptionsCom>
		</template>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref, watchEffect
	} from 'vue'
	import{getConfigOrderApi} from '@/api/index'
	import {
		getStatusFn
	} from '@/utils/data';
	import orderOptionsCom from '@/components/person/order/orderOptionsCom.vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	const props = defineProps({
		status:Number,
		orderId:String,
		orderNo:String
	})
	const emit =defineEmits(['getOrderDetailApiFn'])
	let status = ref('');
	let orderId = ref(0);
	let orderNo = ref('');
	watchEffect(()=>{
		status.value = props.status;
		orderId.value = Number(props.orderId);
		orderNo.value = props.orderNo;
	})
	const statusOptions = ref(getStatusFn());
	let auto_close_time = ref('')
	const getConfigOrderApiFn= ()=>{
		getConfigOrderApi().then(res=>{
			auto_close_time.value =res.data.auto_close_time;
		})
	}
	onMounted(()=>{
		getConfigOrderApiFn()
	})
	const goPage = () => {
		router.push({
			name: 'product_index'
		})
	}
</script>

<style lang="scss" scoped>
	.btn {
		min-width: 70px;
		height: 30px;
		line-height: 30px;
		border-radius: 4px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		padding: 0 10px;
		cursor: pointer;
	}
	.statusBox{
		margin-top: 20px;
		padding: 0 10px;
		height: 60px;
		border-radius: 4px;
		background-color: rgba(255, 255, 255, 1);
		.text{
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: left;
			font-family: Roboto;
		}
	}
</style>
