export function actionOperationResult(val1, val2) {
	const p = actionOperation(val1, val2);
	return ((val1 * p) * (val2 * p)) / (p * p);
}
const actionOperation = (val1, val2) => {
	const len1 = val1.toString().length - val1.toString().indexOf(".") - 1;
	const len2 = val2.toString().length - val2.toString().indexOf(".") - 1;
	let p = Math.max(len1, len2);
	// 避免最终求出结果的长度大于最大长度的时候导致精度丢失 开启下面
	p += p - Math.min(len1, len2); 
	return Math.pow(10, p);
}
