import request from '@/utils/request.js'

// 内容数据列表
export function scientificIndexListApi(params) {
	return request({
		url: '/app/nav/NavList',
		method: 'get',
		params
	})
}
// 详情
export function articleDetailsApi(params) {
	return request({
		url: '/app/nav/articleDetails',
		method: 'get',
		params
	})
}
// 视频数据列表
export function scientificvideoListApi(params) {
	return request({
		url: '/app/nav/videoList',
		method: 'get',
		params
	})
}
// 视频详情
export function scientificVideoDetailsApi(params) {
	return request({
		url: '/app/nav/videoDetails',
		method: 'get',
		params
	})
}
// 添加视频记录
export function userVideoCreateApi(params) {
	return request({
		url: '/app/uservideo/create',
		method: 'post',
		params
	})
}
// 视频记录
export function userVideoIndexApi(params) {
	return request({
		url: '/app/uservideo/index',
		method: 'get',
		params
	})
}