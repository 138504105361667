<template>
	<div class="title">个人资料</div>
	<div class="lineBox">
		<div class="leftText">当前头像：</div>
		<el-upload class="avatar-uploader" :action="uploadOpt.action" :headers='uploadOpt.headers' :data='{scene:"1"}'
			:show-file-list="false" :on-success="handleAvatarSuccess">
			<img v-if="imgUrl" :src="imgUrl" class="avatar" />
			<el-icon v-else class="avatar-uploader-icon">
				<Plus />
			</el-icon>
		</el-upload>
	</div>
	<div class="lineBox" style="margin-top: 47px;">
		<div class="leftText">用户名：</div>
		<p>{{userInfo.telephone}}</p>
	</div>
	<div class="lineBox" style="margin-top: 20px;">
		<div class="leftText">昵称：</div>
		<el-input style="width: 265px;" v-model="userInfo.nickname" />
	</div>
	<div class="btn" @click='keepInfo'>保存</div>
</template>

<script setup>
	import {
		ref,
		computed,
		onMounted
	} from 'vue'
	import {
		Plus
	} from '@element-plus/icons-vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import{getUserInfoApi,postUserPerfectApi} from '@/api/index'
	import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
	const store = useStore();
	const router = useRouter();
	const route = useRoute();
	const userInfo = ref({})
	let imgUrl = ref('');
	const initDataFn= ()=>{
		console.log(userInfo.value)
		userInfo.value = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
		imgUrl.value = userInfo.value.head_pic_url
	}
	onMounted(()=>{
		initDataFn()
		console.log('999999')
		getUserInfoApiFn()
	})
	const uploadOpt = ref({
		action: '/app/pic/upload',
		headers: {
			Accept: 'application/json',
			Authorization: "Bearer " + localStorage.getItem('token'),
			source: 6
		}
	})
	const handleAvatarSuccess = (res) => {
		// console.log("info", res);
		imgUrl.value = res.data.data
	}
	const keepInfo =async () => {
		const data = {
			op_type:8,
			nickname: userInfo.value.nickname,
			head_pic_url: imgUrl.value
		}
		// console.log("data", data);
		const res = await postUserPerfectApi(data);
		getUserInfoApiFn()
	}
	const getUserInfoApiFn = (data) => {
		getUserInfoApi(data).then(res => {
			console.log("res", res);
			store.state.userInfo =JSON.stringify(res.data);
			localStorage.setItem('userInfo',JSON.stringify(res.data))
			ElMessage({
				type: 'success',
				message: '修改成功！',
				onClose() {
					initDataFn()
				}
			})
		})
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed var(--el-border-color);
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		transition: var(--el-transition-duration-fast);
		background-color: #fff;
	}

	.avatar-uploader .el-upload:hover {
		border-color: var(--el-color-primary);
	}

	.el-icon.avatar-uploader-icon {
		font-size: 24px;
		color: #8c939d;
		width: 180px;
		height: 180px;
		text-align: center;
	}
</style>
<style lang="scss" scoped>
	.avatar{
		width: 180px;
		height: 180px;
	}
	.btn {
		width: 160px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		margin: 125px auto 0;
		cursor: pointer;
	}

	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 87px;
	}

	.lineBox {
		margin: 0 0 0 540px;
		display: flex;

		.leftText {
			width: 90px;
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			font-family: AlibabaPuHui-regular;
		}
	}
</style>
