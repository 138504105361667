<template>
	<div class="productNavBox flex between_center">
		<div class="container breadcrumb">
			<el-breadcrumb :separator-icon="ArrowRight">
				<el-breadcrumb-item :to="{ path: '/' }">网站首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/order' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/cart_index' }">购物车</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
	<div class="container carBox">
		<div class="title">
			核对订单信息
		</div>
		<p class="infoText">收货人信息</p>
		<addressCom :addressType='addressType' :activeAddress='activeAddress' @chooseAddress='chooseAddressFn'>
		</addressCom>
		<el-divider></el-divider>
		<p class="infoText">确认商品</p>
		<el-table :data="orderList" border class="orderTable" :cell-style="{'text-align':'center'}"
			:header-cell-style="{'text-align':'center'}" style="width: 100%;" max-height="550" :empty-text="'暂无商品'"
			ref='tableRef' v-loading='loading'>
			<el-table-column prop="info" label="商品名称">
				<template #default="scope">
					<el-row @click="goDetail(scope.row.product_id)" style="cursor: pointer;">
						<el-col :span='3'>
							<img style="width: 100%;"
								:src="scope.row.sku_pics ? scope.row.sku_pics[0]:''" />
						</el-col>
						<el-col :span='15'>
							{{scope.row.product_name}} 货号：{{scope.row.spec_code}}
						</el-col>
					</el-row>
				</template>
			</el-table-column>
			<el-table-column prop="spec_title" label="规格" width="130">
				<template #default="scope">
					{{scope.row.spec_title =='' ? '普通规格' :scope.row.spec_title}}
				</template>
			</el-table-column>
			<el-table-column prop="sale_price" label="价格(元)" width="180">
				<template #default="scope">
					￥{{scope.row.sale_price}}
				</template>
			</el-table-column>
			<el-table-column prop="count" label="数量" width="200">
				<template #default="scope">
					<el-input-number size="small" v-model="scope.row.count" :min="1"
						@change="numHandleChange(scope.row)" />
				</template>
			</el-table-column>
			<el-table-column label="小计(元)" width="200">
				<template #default="scope">
					￥{{new Decimal(actionOperationResult(scope.row.sale_price , scope.row.count))}}
				</template>
			</el-table-column>
		</el-table>
		<div class="footerBox flex between_center" style="margin-top: 0px;">
			<span style="width: 100px;font-size: 14px;">买家留言：</span>
			<el-input v-model="buyer_message"></el-input>
		</div>
		<div class="footerBox flex between_center">
			<div class="rightBox flex">
				<span class="span1">已选商品 {{orderList.length}} 件，合计(不含运费)：</span>
				<span class="span2">￥{{totalPrice}}</span>
			</div>
			<div class="rightBox flex">
				<span class="span1">运费：</span>
				<span class="span2">￥0</span>
			</div>
			<div class="rightBox flex">
				<span class="span1">应付总额：</span>
				<span class="span2">￥{{totalPrice}}</span>
				<div class="addBtn" @click="goSettleFn">提交订单</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import addressCom from '@/components/person/address/index'
	import {
		Decimal
	} from 'decimal.js'
	import {
		actionOperationResult
	} from '@/utils/actionOperationResult'
	import {
		orderCreateApi,
		carEditApi
	} from '@/api/index'
	import {
		computed,
		ref,
		onMounted,
		inject
	} from "vue"
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		ElMessage
	} from 'element-plus'
	const router = useRouter();
	const route = useRoute();
	let activeAddress = ref({
		id: ''
	});
	const addressType = 'chooseAddress'
	let buyer_message = ref('');
	const goDetail = (id) => {
		router.push({
			name: 'product_detail',
			query: {
				id,
			}
		})
	}
	const chooseAddressFn = (item) => {
		activeAddress.value = item
	}
	let loading = ref(false);
	let orderList = ref(JSON.parse(sessionStorage.getItem('order_carId')));
	// console.log("orderList", orderList.value)
	const totalPrice = computed(() => {
		let total = 0;
		for (var i = 0; i < orderList.value.length; i++) {
			const num1 = new Decimal(total);
			const num2 = new Decimal(actionOperationResult(orderList.value[i].count, orderList.value[i]
				.sale_price));

			total = num1.plus(num2);
		}
		return total
	})
	const numHandleChange = (obj) => {
		if (!obj.id) {
			sessionStorage.setItem('order_carId', JSON.stringify(orderList.value));
			return;
		}
		const data = {
			id: obj.id,
			count: obj.count,
			method: 1
		}
		carEditApi(data).then(res => {
			sessionStorage.setItem('order_carId', JSON.stringify(orderList.value))
		});

	}
	const goSettleFn = () => {
		if (activeAddress.value.id == '') {
			ElMessage({
				type: 'error',
				message: '请选择收货地址',
			});
			return;
		}
		let data = {
			pickup_address: {},
			delivery_type: 2,
			score: 0,
			buyer_message: buyer_message.value,
			buyer_telephone: JSON.parse(localStorage.getItem('userInfo')).telephone,
			total_price: totalPrice.value,
			delivery_money: 0,
			real_pay_money: totalPrice.value
		};
		data.products = [];
		for (var i = 0; i < orderList.value.length; i++) {
			data.products.push({
				product_id: orderList.value[i].product_id,
				count: orderList.value[i].count,
				product_price: orderList.value[i].sale_price,
				product_name: orderList.value[i].product_name,
				product_pic_url: orderList.value[i].sku_pics[0],
				product_sku: orderList.value[i].spec_id
			})
		}
		data.address = {
			receive_name: activeAddress.value.name,
			receive_telephone: activeAddress.value.telephone,
			province: activeAddress.value.province,
			city: activeAddress.value.city,
			area: activeAddress.value.area,
			receive_address: activeAddress.value.address,
			city_name: activeAddress.value.city_name,
			area_name: activeAddress.value.area_name,
			province_name: activeAddress.value.province_name,
		}
		// console.log("orderList", data, activeAddress.value);
		orderCreateApi(data).then(res => {
			router.push({
				name: 'cart_settlement',
				query: {
					order_id: res.data.id
				}
			})
		})

	}
</script>

<style lang="scss" scoped>
	:deep() {
		.router-link-exact-active {
			color: rgba(211, 14, 14, 1);
			font-weight: bolder;
		}
	}

	.productNavBox {
		height: 60px;

		:deep() {
			.el-breadcrumb__item:nth-of-type(3) .el-breadcrumb__inner.is-link {
				color: #D30E0E;
			}
		}
	}

	.carBox {
		padding: 20px 10px;
		box-sizing: border-box;
		background-color: #EFEFEF;
		margin-bottom: 32px;
		min-height: 445px;

		.title {
			color: #101010;
			font-size: 24px;
			font-weight: bolder;
			text-align: center;
			font-family: AlibabaPuHui-medium;
			margin-bottom: 23px;
		}

		.infoText {
			color: rgba(16, 16, 16, 1);
			font-size: 18px;
			font-family: AlibabaPuHui-medium;
			margin-bottom: 10px;
		}

		.footerBox {
			margin-top: 10px;
			padding: 0 14px 0 14px;
			height: 60px;
			background-color: rgba(255, 255, 255, 1);
			align-items: center;

			.rightBox {
				align-items: center;

				.span1 {
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					font-family: AlibabaPuHui-regular;
				}

				.span2 {
					color: rgba(211, 14, 14, 1);
					font-size: 20px;
					font-weight: bolder;
					font-family: AlibabaPuHui-medium;
					margin: 0 12px 0 8px;
				}

				.addBtn {
					width: 150px;
					height: 40px;
					line-height: 40px;
					border-radius: 30px;
					background-color: rgba(211, 14, 14, 1);
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					text-align: center;
					font-family: Roboto;
					cursor: pointer;

					&.disabled {
						background-color: #efefef;
						color: #303030;
						cursor: not-allowed;
					}

					&.disabled:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}
</style>
