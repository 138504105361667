import clipboard3 from 'vue-clipboard3'
import {
	ElMessage
} from 'element-plus' //复制颜色值到剪贴板
export function copyTextFn(text) {
	const { toClipboard } = clipboard3();
	const copy = async () => {
		try {
			await toClipboard(text);
			ElMessage.success('复制成功');
		} catch (error) {
			ElMessage.error('复制失败');
		}
	};
	copy()
}
