import request from '@/utils/request.js'
// 文件上传
export function picUploadApi(query) {
	return request({
		url: '/app/pic/upload',
		method: 'post',
		params: {
			...query
		}
	})
}
// 登录
export function userLoginApi(query) {
	return request({
		url: '/app/user/login',
		method: 'post',
		params: {
			...query
		}
	})
}
// 获取用户信息
export function getUserInfoApi() {
	return request({
		url: '/app/user/info',
		method: 'get',
	})
}
// 发送短信
export function postSendsmsApi(params) {
	return request({
		url: '/app/common/sendsms',
		method: 'POST',
		params: {
			...params
		}
	})
}
// 注册
export function postRegisterApi(params) {
	return request({
		url: '/app/user/register',
		method: 'POST',
		params: {
			...params
		}
	})
}
// 获取定义变量
export function getCommonEnumApi() {
	return request({
		url: '/app/common/enum',
	})
}
// 用户找回密码
export function postEditPassApi(params) {
	return request({
		url: '/app/user/edit_pass',
		method: 'POST',
		params: {
			...params
		}
	})
}
// 通用信息详情
export function getInfoApi(params) {
	// console.log("params",params)
	return request({
		url: '/app/common/info',
		method: 'GET',
		params: {
			...params
		}
	})
}
export function getInfoTwoApi(params) {
	// console.log("params",params)
	return request({
		url: '/app/common/infoTwo',
		method: 'GET',
		params: {
			...params
		}
	})
}