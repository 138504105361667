<template>
	<div class="productNavBox flex between_center">
		<div class="container breadcrumb">
			<el-breadcrumb :separator-icon="ArrowRight">
				<el-breadcrumb-item :to="{ path: '/' }">网站首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/order' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/order/cart_index' }">购物车</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
	<div class="container carBox" v-loading='loading'>
		<div class="title">
			收银台
		</div>
		<div style="display: flex;flex-wrap: wrap;">
			<div class="goodsBox flex" v-for="item in products">
				<img :src="item.product_pic_url" />
				<div class="centerBox">
					<div class="goods_title">{{item.product_name}}</div>
					<div class="goods_text">货号：{{item.product_sku_spec_code}}</div>
					<div class="goods_text">规格：
						<span v-if='item.is_multi == 1'>
							<template v-for="sku in item.product_sku_titles">
								{{sku.spec_title}}:{{sku.spec_value}}&nbsp;&nbsp;
							</template>
						</span>
						<span v-else>普通规格</span>
					</div>
				</div>
				<div class="price">￥{{item.product_price}}×{{item.count}}</div>
			</div>
		</div>
		<p class="priceBox">订单金额：<span>￥{{totalPrice}}</span></p>
		<el-divider></el-divider>
		<p class="choosePayText">请选择支付方式</p>
		<div class="choosePayImg">
			<img @click="payMethods =20" src="../../assets/icon/Icon_zhi.png" />
			<!-- <img @click="payMethods =1" src="../../assets/icon/ze-mail.png" /> -->
		</div>
		<div class="payBox flex">
			<iframe :srcdoc="alipayForm" frameborder="0" marginwidth="0" marginheight="0" scrolling="no" width="150"
				height="150">
			</iframe>
			<div class="rightBox">
				<p class="p1"> 订单金额： <br /><span>￥ {{totalPrice}}</span> </p>
				<p class="p2">{{payMethods == 1 ? '微信':'支付宝'}} 扫码付款</p>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import {
		getOrderDetailApi,
		orderPayApi
	} from '@/api/index.js'
	import {
		ref,
		inject,
		onMounted,
		onUnmounted
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		ElMessage
	} from 'element-plus';
	const router = useRouter();
	const route = useRoute();
	let payMethods = ref(20);
	let products = ref([]);
	let totalPrice = ref('');
	let loading = ref(true);
	let alipayForm = ref('');
	let timer = ref(null)
	onMounted(() => {
		/* /cart_settlement?order_id=43 */
		// console.log(route.query.order_id);
		loading.value = true;
		getOrderDetailApi({
			id: route.query.order_id
		}).then(res => {
			products.value = res.data.products;
			totalPrice.value = res.data.total_price;
			loading.value = false;
		})
		orderPayApi({
			order_id: route.query.order_id,
			pay_type: payMethods.value
		}).then(res => {
			alipayForm.value = res.data.data;
			timer.value = setInterval(() => {
				getOrderStatusFn()
			}, 1000)
		})
	})
	const getOrderStatusFn = async () => {
		const res = await getOrderDetailApi({
			id: route.query.order_id
		});
		if (res.data.order_status !== 1000) {
			goSettleFn()
		}
	}
	const goSettleFn = () => {
		router.push({
			name: 'pay_success',
			query: {
				order_id: route.query.order_id
			}
		})
	}
	onUnmounted(() => {
		// 每次离开当前界面时，清除定时器
		clearInterval(timer.value)
		timer.value = null
	})
</script>

<style lang="scss" scoped>
	.payBox {
		align-items: center;

		img {
			width: 222px;
			height: 222px;
			padding: 15px;
			margin-right: 20px;
			background-color: #fff;
		}

		.rightBox {
			margin-left: 10px;

			.p1 {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-family: AlibabaPuHui-regular;

				span {
					line-height: 40px;
					color: rgba(242, 90, 0, 1);
					font-size: 28px;
					font-weight: bolder;
					text-align: left;
					font-family: Roboto;
				}
			}

			.p2 {
				margin-top: 32px;
				color: rgba(153, 153, 153, 1);
				font-size: 16px;
				text-align: left;
				font-family: Roboto;
			}
		}
	}

	:deep() {
		.router-link-exact-active {
			color: rgba(211, 14, 14, 1);
			font-weight: bolder;
		}
	}

	.productNavBox {
		height: 60px;

		:deep() {
			.el-breadcrumb__item:nth-of-type(3) .el-breadcrumb__inner.is-link {
				color: #D30E0E;
			}
		}
	}

	.carBox {
		padding: 20px 10px;
		box-sizing: border-box;
		background-color: #EFEFEF;
		margin-bottom: 32px;
		min-height: 445px;

		.title {
			color: #101010;
			font-size: 24px;
			font-weight: bolder;
			text-align: center;
			font-family: AlibabaPuHui-medium;
			margin-bottom: 23px;
		}

		.goodsBox {
			align-items: center;
			margin-bottom: 10px;

			img {
				width: 90px;
				margin-right: 20px;
			}

			.centerBox {
				width: 400px;
				margin-right: 44px;

				.goods_title {
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					font-weight: bolder;
					text-align: left;
					font-family: AlibabaPuHui-regular;
				}

				.goods_text {
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					text-align: left;
					font-family: AlibabaPuHui-regular;
				}
			}

			.price {
				width: 100px;
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				text-align: left;
				font-family: AlibabaPuHui-regular;
				margin-right: 20px;
			}
		}
	}

	.priceBox {
		margin: 20px 0 0 0;
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		text-align: left;
		font-family: AlibabaPuHui-regular;

		span {
			color: rgba(211, 14, 14, 1);
			font-size: 20px;
			margin-left: 4px;
			font-weight: bolder;
			text-align: left;
			font-family: AlibabaPuHui-medium;
		}
	}

	.choosePayText {
		color: rgba(16, 16, 16, 1);
		font-size: 20px;
		font-weight: bolder;
		margin-bottom: 15px;
		text-align: left;
		font-family: AlibabaPuHui-medium;
	}

	.choosePayImg {
		img {
			width: 60px;
			height: 60px;
			margin-right: 10px;
			cursor: pointer;
		}

		margin-bottom: 20px;
	}
</style>
