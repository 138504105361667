export function getStatusFn() {
	/* 
	 			"1000": "待付款",
	 						"1001": "支付失败",
	 						"1002": "支付取消",
	 						"2000": "已支付",
	 						"3000": "已发货",
	 						"3001": "待使用",
	 						"4000": "收货",
	 						"4001": "已使用",
	 						"5000": "退款中",
	 						"6000": "退款完成",
	 						"7000": "已关闭",*/
							/* 	"1": "申请维权",
			"2": "买家待退货",
			"3": "卖家已拒绝",
			"4": "卖家待收货",
			"5": "待转账",
			"6": "维权结束",
			"7": "已关闭" */
	const status = {
		'4001': {
			status_desc: '订单已完成',
			title: '已完成',
			options: [0, 2, 3], //详情  物流  删除  开票
		},
		'4000': {
			status_desc: '订单已完成',
			title: '已完成',
			options: [0, 2, 3], //详情  物流  删除  开票
		},
		'1000': {
			title: '待付款',
			options: [4, 0, 5], //付款  详情 取消订单
		},
		'2000': {
			status_desc: '已付款，等待平台发货',
			title: '待发货',
			options: [0], //详情  物流 
		},
		'3000': {
			status_desc: '物流已配送，等待收货',
			title: '待收货',
			options: [7, 0], //收货  详情  物流
		},
		'1': {
			status_desc: '退款申请已提交，等待审核通过中',
			title: '退款待审核',
			options: [10,9], //详情  //取消申请
		},
		'2': {
			status_desc: '退款申请已通过，提交物流单号',
			title: '退款申请已通过',
			options: [8,10], // 提交物流单号 详情 
		},
		'3': {
			status_desc: '卖家已拒绝',
			title: '卖家已拒绝',
			options: [10], // 详情 
		},
		'4': {
			status_desc: '商家待收货',
			title: '商家待收货',
			options: [10], //详情
		},
		'5': {
			status_desc: '商家已收货，等待退款',
			title: '商家已收货,待转账',
			options: [10], //详情 
		},
		'6': {
			status_desc: '已结束',
			title: '维权订单已结束',
			options: [10], //详情 
		},
		'7': {
			status_desc: '已关闭',
			title: '维权订单已关闭',
			options: [10], //详情 
		},
		'6000': {
			status_desc: '退款成功',
			title: '退款成功',
			options: [10], //详情 
		},
		'7000': {
			status_desc: '订单已关闭',
			title: '订单已关闭',
			options: [0,2], //详情   删除订单
		},
	}
	return status;
}


export function getBannerList() {
	const bannerList = ["../../assets/img/banner.png", "../../assets/img/banner.png", "../../assets/img/banner.png"];
	return bannerList;
}
export function getProductList() {
	const List = [{
		img: '',
		title: '此处是产品名此处是产品名称此处是产品名称称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}, {
		img: '',
		title: '此处是产品名称此处是产品名称'
	}];
	return List;
}
export function getScientificNewsList() {
	const scientificNewsList = [{
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 1,
		id: 1,
	}, {
		title: '《科学》：破除癌细胞',
		time: '2023-09-23',
		is_new: 1,
		id: 2,
	}, {
		title: '科学家们揭开了关于癌症的百年之谜！',
		time: '2023-09-23',
		is_new: 0,
		id: 3,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 4,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 5,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 6,
	}]
	return JSON.parse(JSON.stringify([...scientificNewsList, ...scientificNewsList, ...scientificNewsList, ...
		scientificNewsList
	]));
}
export function getScientificClassify() {
	const list = [{
		name: '分类一',
		id: 1,
	}, {
		name: '分类一',
		id: 1,
	}, {
		name: '分类一',
		id: 1,
	}, {
		name: '分类一',
		id: 1,
	}, {
		name: '分类一',
		id: 1,
	}, {
		name: '分类一',
		id: 1,
	}, {
		name: '分类一',
		id: 1,
	}]
	return list
}
export function getArticleList() {
	const scientificNewsList = [{
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 1,
		id: 1,
	}, {
		title: '《科学》：破除癌细胞',
		time: '2023-09-23',
		is_new: 1,
		id: 2,
	}, {
		title: '科学家们揭开了关于癌症的百年之谜！',
		time: '2023-09-23',
		is_new: 0,
		id: 3,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 4,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 5,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 6,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 5,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		is_new: 0,
		id: 6,
	}]
	return scientificNewsList;
}
export function getOrganismNewsList() {
	const list = [{
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		type: 1,
		type_desc: '分类一',
		id: 6,
	}, {
		title: '香港大学孙红哲团队发现应对革兰氏阴性菌引起耐药性的新',
		time: '2023-09-23',
		type: 2,
		type_desc: '分类二',
		id: 6,
	}, ]
	return [...list, ...list, ...list, ...list, ...list, ];
}
export function getTeachingList() {
	const list = [{
		id: 1,
		typeId: 1,
		title: '此处是此处是培此处是此处是培训标题此处是培训标题此处是培训标题培训标题训标题此处是培训标题此处是培训标题培训标题1',
		type: '分类分类一分类一分类一一',
		content: '文字文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述'
	}, {
		id: 2,
		typeId: 2,
		title: '此处是培训标题2',
		type: '分类二',
		content: '文字描述文字描述文字描述文'
	}, {
		id: 3,
		typeId: 3,
		title: '此处是培训标题3',
		type: '分类一',
		content: '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述'
	}, {
		id: 4,
		typeId: 4,
		title: '此处是培训标题4',
		type: '分类二',
		content: '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述'
	}, {
		id: 8,
		typeId: 8,
		title: '此处是培训标题8',
		type: '分类一',
		content: '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述'
	}]
	return list;
}
export function getProductClassify() {
	const list = [{
		name: '分类分类一分类一分类一分类一一',
		id: 1,
	}, {
		name: '分类二',
		id: 1,
	}, {
		name: '分类三',
		id: 1,
	}, {
		name: '分类四',
		id: 1,
	}]
	return list
}
export function getVideoList() {
	const list = [{
		title: '此处是此处是培训标题此处是培训标题此处是培训标题培训标题',
		content: '文字文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述'
	}, {
		title: '此处是培训标题',
		content: '文字描述文字描述文描述'
	}, ]
	return [...list, ...list, ...list, ...list, ...list]
}
export function getsciMenuList() {
	const list = [{
			id: 1,
			name: '科研分类1'
		},
		{
			id: 2,
			name: '科研分类2'
		}, {
			id: 3,
			name: '科研分类3'
		}, {
			id: 4,
			name: '科研分类4'
		}, {
			id: 5,
			name: '科研分类5'
		}, {
			id: 6,
			name: '科研分类6'
		}, {
			id: 7,
			name: '科研分类7'
		}, {
			id: 8,
			name: '科研分类8'
		}, {
			id: 9,
			name: '科研分类9'
		}, {
			id: 10,
			name: '科研分类10'
		}, {
			id: 11,
			name: '科研分类11'
		}, {
			id: 12,
			name: '科研分类12'
		}
	]
	return list
}
export function getVideoMenuList() {
	const list = [{
			id: 1,
			name: '视频分类1'
		},
		{
			id: 2,
			name: '视频分类2'
		}, {
			id: 3,
			name: '视频分类3'
		}, {
			id: 4,
			name: '视频分类4'
		}, {
			id: 5,
			name: '视频分类5'
		}, {
			id: 6,
			name: '视频分类6'
		}, {
			id: 7,
			name: '视频分类7'
		}, {
			id: 8,
			name: '视频分类8'
		}, {
			id: 9,
			name: '视频分类9'
		}, {
			id: 10,
			name: '视频分类10'
		}, {
			id: 11,
			name: '视频分类11'
		}, {
			id: 12,
			name: '视频分类12'
		}
	]
	return list
}
export function getVipPriceList() {
	const list = [{
			title: '季度VIP',
			time: '三个月',
			price: '50',
			preferential: '15',
			desc: '三个月可免三个月可免费查看视频中心内所有视频内容三个月可免费查看视频中心内所有视频内容三个月可免费查看视频中心内所有视频内容三个月可免费查看视频中心内所有视频内容三个月可免费查看视频中心内所有视频内容费查看视频中心内所有视频内容'
		},
		{
			title: '半年VIP',
			time: '半年',
			price: '150',
			preferential: '50',
			desc: '半年可免费查看视频中心内所有视频内容'
		},
		{
			title: '年度VIP',
			time: '一年',
			price: '280',
			preferential: '20',
			desc: '一年可免费查看视频中心内所有视频内容'
		}
	]
	return list
}
export function getGoodDataFn() {
	const data = {
		skus: [{
			id: 1,
			specs: [{
					name: '颜色',
					valueName: '黑色'
				},
				{
					name: '产地',
					valueName: '中国'
				},
				{
					name: '尺寸',
					valueName: '20cm'
				}
			],
		}, {
			id: 2,
			inventory: 0,
			oldPrice: 100,
			price: 90,
			specs: [{
					name: '颜色',
					valueName: '黑色'
				},
				{
					name: '产地',
					valueName: '中国'
				},
				{
					name: '尺寸',
					valueName: '30cm'
				}
			],
		}, {
			id: 3,
			inventory: 10,
			oldPrice: 100,
			price: 90,
			specs: [{
					name: '颜色',
					valueName: '绿色'
				},
				{
					name: '产地',
					valueName: '中国'
				},
				{
					name: '尺寸',
					valueName: '30cm'
				}
			],
		}, {
			id: 4,
			inventory: 10,
			oldPrice: 100,
			price: 90,
			specs: [{
					name: '颜色',
					valueName: '蓝色'
				},
				{
					name: '产地',
					valueName: '中国'
				},
				{
					name: '尺寸',
					valueName: '30cm'
				}
			],
		}],
		specs: [{
			"name": "颜色",
			values: [{
					"name": "黑色",
				},
				{
					"name": "绿色",
				},
				{
					"name": "蓝色",
				}
			]
		}, {
			"name": "产地",
			values: [{
				"name": "中国",
			}, ]
		}, {
			"name": "尺寸",
			values: [{
					"name": "20cm",
				},
				{
					"name": "30cm",
				},
			]
		}, ]
	}
	return data;
}
export function getOrderListFn() {
	const list = [{
			goods: [{
				name: '商品名称--已完成',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 3,
			price: '1230',
			status: 1,
			id: 1,
		},
		{
			goods: [{
					name: '商品名称2--代付款',
					sku: '黑色 中国 23cm',
					image: '',
					price: '240',
					count: 1,
				},
				{
					name: '商品名称2',
					sku: '黑色 中国 23cm',
					image: '',
					price: '80',
					count: 2,
				},
				{
					name: '商品名称2',
					sku: '黑色 中国 23cm',
					image: '',
					price: '80',
					count: 2,
				}
			],
			count: 3,
			price: '1230',
			status: 2,
			id: 2,
		},
		{
			goods: [{
				name: '商品名称--待发货',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 1,
			price: '1230',
			status: 3,
			id: 3,
		},
		{
			goods: [{
				name: '商品名称--待收货',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 1,
			price: '1230',
			status: 4,
			id: 4,
		},
		{
			goods: [{
				name: '商品名称--退款待审核',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 1,
			price: '1230',
			status: 5,
			id: 5,
		},
		{
			goods: [{
				name: '商品名称--退款申请已通过',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 1,
			price: '1230',
			status: 6,
			id: 6,
		},
		{
			goods: [{
				name: '商品名称--商家待收货',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 1,
			price: '1230',
			status: 7,
			id: 7,
		},
		{
			goods: [{
				name: '商品名称--商家已收货，等待退款',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 1,
			price: '1230',
			status: 8,
			id: 8,
		}, {
			goods: [{
				name: '商品名称--退款成功',
				sku: '黑色 中国 23cm',
				image: '',
				price: '240',
				count: 1,
			}],
			count: 1,
			price: '1230',
			status: 9,
			id: 9,
		},
	]
	return list;
}
export function getInvoicingListFn() {
	const list = [{
			time: '2024-01-11',
			goods: [{
					name: '商品名称--商家已收货，等待退款',
					sku: '黑色 中国 23cm',
					image: '',
					price: '240',
					count: 1,
				},
				{
					name: '商品名称--商家已收货，等待退款',
					sku: '黑色 中国 23cm',
					image: '',
					price: '240',
					count: 1,
				}
			],
			price: '1234',
			content: '发票内容',
			status: '1'
		},
		{
			time: '2024-01-11',
			goods: [{
					name: '商品名称--商家已收货，等待退款',
					sku: '黑色 中国 23cm',
					image: '',
					price: '240',
					count: 1,
				},
				{
					name: '商品名称--商家已收货，等待退款',
					sku: '黑色 中国 23cm',
					image: '',
					price: '240',
					count: 1,
				}
			],
			price: '1234',
			content: '发票内容',
			status: '2'
		}
	]
	return list;
}
