<template>
	<div class="footer">
		<div class="container flex">
			<div class='leftBox'>
				<div class="title">快捷方式</div>
				<div class="menuBox flex">
					<span class='nav-item' :style="{'color':item.label_colour}"
						:class="item.id ? route.query.id == item.id ? 'nav-item-active':'':item.url == route.path ?'nav-item-active':''"
						v-for="item in menuList" @click="goPageFn(item.url,item.id,item.is_type)">{{item.name}}</span>
				</div>
			</div>
			<div class="centerBox">
				<div class="title">{{ firm }}</div>
				<div class="infoLine">
					<img src="../assets/icon/ze-mobile.png" />
					<text>{{Service_hotline}}</text>
				</div>
				<div class="infoLine">
					<img src="../assets/icon/ze-mail.png" />
					<text>{{mailbox}}</text>
				</div>
				<div class="infoLine">
					<img src="../assets/icon/ze-location.png" />
					<text>{{address}}</text>
				</div>
			</div>
			<div class="rightBox">
				<img :src="customer_service" />
			</div>
		</div>
	</div>
	<div class="bootom">
		<div class="container flex center_center">
			<p>版权所有：{{ firm }} &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index"
					target="_blank">ICP备案证书号：{{ filings }}</a></p>
			<p>服务热线：{{Service_hotline}}</p>
		</div>
	</div>
	<div class="rightNavigation">
		<div class="rightNavigation_center flex">
			<div class="one_item flex" @click="goPage('cart_index')">
				<!-- <div class="badge" v-if='store.state.carNum>0'>{{store.state.carNum}}</div> -->
				<img src='../assets/icon/cart-icon.png' />
				购物车
			</div>

			<el-divider></el-divider>
			<div class="one_item flex">
				<img src='../assets/icon/service-icon.png' />
				联系客服
				<div class="drop-aside">
					<div class="drop-aside-content">
						<img :src="customer_service" />
						<p>扫码联系客服</p>
					</div>
				</div>
			</div>
			<el-divider></el-divider>
			<div class="one_item flex" @click="goPage('order')">
				<img src='../assets/icon/user-icon.png' />
				会员中心
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref,
		computed
	} from 'vue'
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		useStore
	} from 'vuex';
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn'
	import{getNavListApi} from '@/api/index'
	const router = useRouter();
	const route = useRoute();
	const store = useStore();
	let menuList = ref([])
	const getNavListApiFn = async () => {
		const res = await getNavListApi();
		const list = [{
			name: '网站首页',
			url: '/home'
		}, {
			name: '产品中心',
			url: '/product_index'
		}]
		menuList.value = [...list, ...res.data, {
			name: '公司介绍',
			url: '/company_index'
		}];
		// sessionStorage.setItem('menuList',JSON.stringify(menuList.value));
		// searchList.value = [list[1],...res.data];
		// // console.log("menuList", menuList.value)
	}
	const goPageFn = (url, id, type) => {
		let data = {
			name: ''
		}
		if (url) {
			data.name = url.split('/')[1];
		} else {
			data.name = 'scientific_list'
		}
		if (id) {
			data.query = {
				id: id,
				type
			}
		}
		router.push(data)
	}
	const goPage = (name) => {
		router.push({
			name: name,
		})
	}
	let tel = ref('');
	let customer_service = ref('');
	let mailbox = ref('');
	let address = ref('');
	let Service_hotline = ref('');
	let logo = ref('')
	let firm = ref('')
	let filings = ref('')
	onMounted(async() => {
		tel.value =await getInfoApiFn(16);
		customer_service.value =await getInfoApiFn(15);
		customer_service.value = JSON.parse(customer_service.value)[0].url
		mailbox.value =await getInfoApiFn(14);
		address.value =await getInfoApiFn(13);
		Service_hotline.value =await getInfoApiFn(11);
		logo.value =await getInfoApiFn(12);
		firm.value =await getInfoApiFn(23);
		filings.value = await getInfoApiFn(25);
		console.log("firm.value ",firm.value )
		getNavListApiFn()
	})
</script>

<style lang="scss" scoped>
	.badge {
		padding: 0 4px;
		background-color: #D30E0E;
		color: #fff;
		font-size: 14px;
		text-align: center;
		line-height: 20px;
		box-sizing: border-box;
		min-width: 20px;
		height: 20px;
		border-radius: 10px;
		position: absolute;
		top: -10px;
		right: -5px;
	}

	.rightNavigation {
		position: fixed;
		z-index: 9;
		bottom: 300px;
		right: 60px;

		:deep(.el-divider--horizontal) {
			margin: 10px 0;
		}

		.rightNavigation_center {
			width: 55px;
			// height: 180px;
			border-radius: 10px 10px 10px 10px;
			background-color: rgba(255, 255, 255, 1);
			box-shadow: 0px 2px 6px 0px rgba(190, 190, 190, 1);
			flex-direction: column;
			justify-content: space-between;
			padding: 10px 5px;
			box-sizing: border-box;

			.one_item {
				flex-direction: column;
				color: rgba(16, 16, 16, 1);
				font-size: 11px;
				font-family: AlibabaPuHui-regular;
				align-items: center;
				cursor: pointer;
				position: relative;

				img {
					width: 16px;
					height: 16px;
					margin-bottom: 4px;
				}

				.drop-aside {
					position: absolute;
					box-sizing: border-box;
					top: 0px;
					left: 0px;
					transform: translateY(0%);
					padding-right: 10px;
					z-index: -1;
					opacity: 0;
					transition: all .4s;
					pointer-events: none;

					.drop-aside-content {
						display: block;
						border-radius: 8px;
						background: #fff;
						padding: 10px;

						img {
							width: 150px;
							height: 150px;
							display: flex;
						}

						p {
							line-height: 25px;
							font-size: 14px;
							text-align: center;
							color: #D30E0E;
							cursor: pointer;
						}
					}
				}

				&:hover .drop-aside {
					opacity: 1;
					transform: translate(-100%, 0%);
					pointer-events: all;
				}
			}
		}
	}

	.footer {
		background-color: rgba(190, 190, 190, 1);

		.container {
			height: 255px;
			align-items: center;

			.leftBox {
				.title {
					line-height: 25px;
					color: rgba(51, 51, 51, 1);
					font-size: 18px;
					text-align: left;
					font-family: AlibabaPuHui-medium;
					margin-bottom: 20px;
				}

				.menuBox {
					flex-wrap: wrap;
					width: 440px;

					.nav-item {
						width: 110px;
						height: 30px;
						line-height: 30px;
						color: rgba(51, 51, 51, 1);
						font-size: 14px;
						text-align: left;
						font-family: Roboto;
						cursor: pointer;
					}

					.nav-item-red {
						color: #D30E0E;
					}

					.nav-item-active {
						color: rgba(211, 14, 14, 1) !important;
						font-weight: bolder;
					}
				}
			}

			.centerBox {
				margin: 0 465px 0 197px;

				.title {
					line-height: 25px;
					color: rgba(51, 51, 51, 1);
					font-size: 18px;
					text-align: left;
					font-family: AlibabaPuHui-medium;
					margin-bottom: 20px;
				}

				.infoLine {
					margin-bottom: 10px;

					img {
						width: 16px;
						height: 16px;
						margin-right: 8px;
					}

					text {
						color: rgba(51, 51, 51, 1);
						font-size: 14px;
						font-family: AlibabaPuHui-bold;
					}
				}
			}

			.rightBox {
				width: 190px;
				height: 190px;
				background-color: rgba(255, 255, 255, 1);
				padding: 16px;
				box-sizing: border-box;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.bootom {
		background-color: rgba(51, 51, 51, 1);

		.container {
			height: 100px;
			flex-direction: column;

			p {
				height: 22px;
				color: rgba(239, 239, 239, 1);
				font-size: 12px;
				text-align: center;
				font-family: AlibabaPuHui-regular;

				a {
					color: rgba(239, 239, 239, 1);
				}
			}
		}

	}
</style>
