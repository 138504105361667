<template>
	<div class="scientificBox">
		<div class="container flex center_center">
			<classifyBox :title='"基金申请"' :classifyList='fundClassify' :activeIndex='fundIndex' :firstId='fundId' :type='1' @getDataListFn='getFundDataFn'></classifyBox>
			<div class="scientificNews" style="margin-right: 20px;">
				<newsLineCom :newsList='fundList' :firstId='fundId' :type='1' :secondId='fundSecondId'></newsLineCom>
			</div>
			<classifyBox :title='"软件/统计"' :classifyList='statisticsClassify' :activeIndex='statisticsIndex' :firstId='statisticsId' :type='1' @getDataListFn='getstatisticsDataFn'></classifyBox>
			<div class="scientificNews">
				<newsLineCom :newsList='statisticsList' :firstId='statisticsId' :type='1' :secondId='statisticsSecondId'></newsLineCom>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		computed,
		onMounted,
		ref
	} from 'vue'
	import {
		scientificIndexListApiFn,
		getNavIndexTwoPageApiFn
	} from '@/utils/home.js'
	import classifyBox from './com/classifyBox.vue'
	import newsLineCom from './com/news.vue'
	const type=1;
	const fundList = ref([]);
	const fundId=13;
	const fundClassify = ref([]);
	let fundIndex = ref(-1);
	const statisticsList = ref([]);
	const statisticsId=1;
	const statisticsClassify = ref([]);
	let statisticsIndex = ref(-1);
	let fundSecondId = computed(() => {
		return fundIndex.value == fundClassify.value.length ? fundId : fundIndex.value == -1 ? 0 : fundClassify.value[fundIndex.value].id;
	})
	let statisticsSecondId = computed(() => {
		return statisticsIndex.value == statisticsClassify.value.length ? statisticsId : statisticsIndex.value == -1 ? 0 : statisticsClassify.value[statisticsIndex.value].id;
	})
	onMounted(async () => {
		const res = await getNavIndexTwoPageApiFn({
			id:fundId,
			page: 1,
			page_size: 7
		});
		fundClassify.value = res.data.list;
		await getFundDataFn(res.data.list.length);
		const res2 = await getNavIndexTwoPageApiFn({
			id:statisticsId,
			page: 1,
			page_size: 7
		});
		statisticsClassify.value = res2.data.list;
		// console.log("statisticsClassify.value",statisticsClassify.value)
		await getstatisticsDataFn(res2.data.list.length);
	})
	const getFundDataFn =async (index) => {
		fundIndex.value = index;
		const data = {
			id: fundIndex.value == fundClassify.value.length ? fundId : fundClassify.value[
				fundIndex.value].id,
			page: 1,
			page_size: 24
		}
		const res = await scientificIndexListApiFn(data);
		fundList.value =res.data.list
	}
	const getstatisticsDataFn =async (index) => {
		statisticsIndex.value = index;
		const data = {
			id: statisticsIndex.value == statisticsClassify.value.length ? statisticsId : statisticsClassify.value[
				statisticsIndex.value].id,
			page: 1,
			page_size: 24
		}
		const res = await scientificIndexListApiFn(data);
		statisticsList.value =res.data.list
	}
</script>

<style lang="scss" scoped>
	.scientificBox {
		background-color: #fff;

		.container {
			height: 420px;
			.scientificNews {
				margin-left: 10px;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				width: 580px;
				height: 360px;
			}
		}
	}
</style>
