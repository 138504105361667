<template>
	<div class="title">发票管理</div>
	<el-table :data="invoicingList" border class="orderTable" :cell-style="{'text-align':'center'}"
		:header-cell-style="{'text-align':'center'}" style="width: 100%;" max-height="550" :empty-text="'暂无相关订单'"
		ref='tableRef' v-loading='loading'>
		<el-table-column prop="created_at" label="日期" width="250" />
		<el-table-column prop="order_order_no" label="订单号" width="350">
		</el-table-column>
		<el-table-column prop="order_real_pay_money" label="金额" width="130">
			<template #default="scope">
				￥{{scope.row.order_real_pay_money}}
			</template>
		</el-table-column>
		<el-table-column prop="invoice_type" label="发票类型" width="130">
		</el-table-column>
		<el-table-column prop="content" label="内容" width="200">
			<template #default="scope">
				{{scope.row.content_type == 1 ? "商品明细":scope.row.content}}
			</template>
		</el-table-column>
		<el-table-column prop="name" label="操作">
			<template #default="scope">
				<el-space>
					<el-link :underline="false" @click="goPage('order_invoicing',3,scope.row.id)">查看</el-link>
					<!-- <el-link :underline="false" @click="goPage('order_invoicing',2)">修改</el-link>
					<el-link :underline="false" @click="cancellationFn(scope.row.id)">取消</el-link> -->
				</el-space>
			</template>
		</el-table-column>
	</el-table>
	<el-dialog class="cancellationBox" v-model="cancellationVisible" title="取消申请发票提示" width="20%" :append-to-body='true'
		:lock-scroll='false'>
		<p class="tipsText">确定要取消本次申请的发票吗？</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="cancellationVisible = false">取消</div>
			<div class="sureBtn">确定</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import {
		onMounted,
		ref,
		inject
	} from 'vue'
	import {
		getInvoiceIndexApi
	} from '@/api/index.js'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	let loading = ref(false)
	const invoicingList = ref([]);
	const tableRef = ref(null);
	const pageOptions = ref({
		page: 1,
		page_size: 20,
		total: 0,
	})
	const getInvoiceIndexApiFn = (type) => {
		loading.value = true;
		if (type == 0) {
			invoicingList.value = [];
			pageOptions.value.page = 1;
		}
		const data = {
			page: pageOptions.value.page,
			page_size: pageOptions.value.page_size,
		}
		getInvoiceIndexApi(data).then(res => {
			pageOptions.value.total = res.data.total
			invoicingList.value = [...invoicingList.value, ...res.data.list];
			loading.value = false;
		})
	}
	onMounted(() => {
		getInvoiceIndexApiFn(0)
		const target = tableRef.value.$refs.bodyWrapper.getElementsByClassName("el-scrollbar__wrap")[0];
		target.addEventListener("scroll", () => {
			// 滚动距离
			let scrollTop = target.scrollTop;
			// 变量windowHeight是可视区的高度
			let windowHeight = target.clientHeight || target.clientHeight;
			// 变量scrollHeight是滚动条的总高度
			let scrollHeight = target.scrollHeight || target.scrollHeight;
			// 这里是触底 自己按自己业务需求是写逻辑
			if (scrollTop + windowHeight === scrollHeight) {
				// 获取到的不是全部数据 当滚动到底部 继续获取新的数据
				// console.log("到底了！！！");
				if (pageOptions.value.total > orderList.value.length) {
					pageOptions.value.page = pageOptions.value.page + 1;
					getInvoiceIndexApiFn(1)
				}
			}
		})
	})
	const goPage = (name, type,id) => {
		router.push({
			name: name,
			query: {
				id: id,
				type: type,
			}
		})
	}
	let cancellationVisible = ref(false);
	const cancellationFn = () => {
		cancellationVisible.value = true;
	}
</script>
<style>
	.orderTable .el-table__header th {
		background-color: rgba(51, 51, 51, 1) !important;
		border-right-color: #EFEFEF !important;
	}

	.orderTable .el-table__header th .cell {
		color: #fff !important;
	}
</style>
<style lang="scss" scoped>
	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 15px;
	}
</style>
