<template>
	<div class="one_item" v-for="item in productList" @click="goPage(item.id)">
		<img class="one_item_img" :src="item.is_multi==1 ? item.skus[0].sku_pics[0] :item.pic_urls[0]" />
		<p class="title line1">{{item.name}}</p>
		<p class="sku line1" v-if='item.is_multi==1'>
			<template v-for='(sku_attrs_item,value) in item.skus[0].sku_attrs'>
				{{value}}:{{sku_attrs_item}}&nbsp;&nbsp;
			</template>
		</p>
		<p class="sku line1" v-else>规格：普通规格</p>
		<div class="priceBox flex between_center">
			<div class="leftBox">
				<div class="now_price">￥{{item.is_multi==1 ? item.skus[0].price:item.sale_price}}
				</div>
				<div class="old_price">
					原价：{{item.is_multi==1 ? item.skus[0].delete_price:item.delete_price}}元</div>
			</div>
			<div class="buyBtn" @click.stop="addCarFn($event,item)">加入购物车</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		watchEffect,
		inject,
		computed
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		carAddApi
	} from '@/api/index'
	import {
		ElMessage
	} from 'element-plus';
	const router = useRouter();
	const props = defineProps({
		productList: Array
	})
	let productList = ref([]);
	watchEffect(() => {
		productList.value = props.productList
	})
	const goPage = (id) => {
		router.push({
			name: 'product_detail',
			query: {
				id,
			}
		})
	}
	const addCarFn = async (event, item) => {
		if(item.skus[0].stock==0){
			ElMessage({
				type: 'error',
				message: '库存不足，请选择其他规格加入购物车！'
			})
			return;
		}
		const data = {
			product_id: item.id,
			spec_id: item.skus[0].id,
			count: 1,
		}
		const res = await carAddApi(data);
		ElMessage({
			type: 'success',
			message: '成功加入购物车！'
		})
	}
</script>

<style lang="scss" scoped>
	.one_item {
		width: 265px;
		height: 300px;
		padding: 10px;
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 1);
		border: 1px solid #fff;
		cursor: pointer;
		transition: all 0.3s;

		.one_item_img {
			width: 100%;
			height: 162px;
			display: block;
		}

		.title {
			height: 30px;
			line-height: 30px;
			color: rgba(16, 16, 16, 1);
			font-size: 16px;
			font-family: Roboto;
		}

		.sku {
			height: 30px;
			line-height: 30px;
			color: rgba(108, 108, 108, 1);
			font-size: 12px;
			text-align: center;
			font-family: Roboto;
		}

		.priceBox {
			.now_price {
				height: 30px;
				line-height: 30px;
				color: rgba(211, 14, 14, 1);
				font-size: 16px;
				text-align: left;
				font-family: Roboto;
			}

			.old_price {
				height: 30px;
				line-height: 30px;
				color: rgba(154, 154, 154, 1);
				font-size: 12px;
				text-align: left;
				font-family: Roboto;
				text-decoration: line-through;
			}

			.buyBtn {
				width: 100px;
				height: 30px;
				line-height: 30px;
				border-radius: 30px;
				color: rgba(211, 14, 14, 1);
				font-size: 14px;
				text-align: center;
				font-family: Roboto;
				border: 1px solid rgba(211, 14, 14, 1);
				transition: all 0.3s;
			}
		}
	}

	.one_item:nth-of-type(1),
	.one_item:nth-of-type(2),
	.one_item:nth-of-type(3),
	.one_item:nth-of-type(4),
	.one_item:nth-of-type(5),
	.one_item:nth-of-type(6) {
		margin-bottom: 10px;
	}

	.one_item:hover {
		border: 1px solid rgba(211, 14, 14, 1);
		transform: translateY(-5px);
	}

	.one_item:hover .buyBtn {
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
	}
</style>
