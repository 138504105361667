<template>
	<div class="one_item flex" @click="goPage(item.id)" v-for="(item,index) in newsList">
		<img class="one_item_img" :src="item.cover_url" />
		<div class="rightBox">
			<div class="titleBox flex">
				<div class="leftBox flex">
					<p class="title line1">{{item.title}}</p>
					<p class="type line1" :class='item.class_id %2==0 ?"type_dan":"type_shuang"'>{{item.class_name}}</p>
				</div>
				<p class="detail">查看详情 &gt;&gt;</p>
			</div>
			<div class="time">发布时间：{{item.publish_time}}</div>
			<div class="detail line2">{{item.summary}}</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		watchEffect,
		inject
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	const route = useRoute();
	const props = defineProps({
		newsList: Array,
		detailUrl: String
	})
	let newsList = ref([]);
	watchEffect(() => {
		newsList.value = props.newsList
	})
	const goPage = (id) => {
		let query = {
			id: route.query.id,
			infoId:id,
			type: route.query.type,
		}
		if (route.query.secondId) {
			query.secondId = route.query.secondId
		}
		router.push({
			name: 'scientific_detail',
			query
		})
	}
</script>

<style lang="scss" scoped>
	.one_item {
		padding: 0 0 15px 0;
		margin-bottom: 15px;
		border-bottom: 1px dashed rgba(190, 190, 190, 1);
		margin-right: 20px;
		width: calc(50% - 10px);
		box-sizing: border-box;
		cursor: pointer;
		align-items: flex-end;
		transition: all 0.3s;

		.one_item_img {
			width: 140px;
			height: 117px;
			margin-right: 10px;
			border: 1px solid transparent;
			transition: all 0.3s;
		}

		.rightBox {
			width: calc(100% - 150px);

			.titleBox {
				width: 100%;
				height: 26px;
				align-items: center;

				.leftBox {
					width: calc(100% - 100px);

					.title {
						width: auto;
						max-width: calc(100% - 100px);
						color: rgba(51, 51, 51, 1);
						font-size: 18px;
						font-weight: bolder;
						text-align: left;
						font-family: SourceHanSansSC-medium;
						transition: all 0.3s;
					}

					.type {
						margin-left: 5px;
						max-width: 90px;
						padding: 0 5px;
						box-sizing: border-box;
						height: 24px;
						line-height: 24px;
						border-radius: 4px;
						font-size: 12px;
						text-align: center;
						font-family: Roboto;
						color: rgba(16, 16, 16, 1);
						margin-left: 10px;
					}
				}

				.detail {
					width: 100px;
					height: 24px;
					line-height: 24px;
					color: rgba(16, 16, 16, 1);
					font-size: 12px;
					text-align: right;
					font-family: Roboto;
				}
			}

			.time {
				color: rgba(51, 51, 51, 1);
				font-size: 12px;
				text-align: left;
				font-family: SourceHanSansSC-light;
				margin: 15px 0 10px;
			}

			.detail {
				height: 40px;
				line-height: 20px;
				color: rgba(51, 51, 51, 1);
				font-size: 14px;
				text-align: left;
				font-family: SourceHanSansSC-light;
			}
		}

	}
	.one_item:hover {
		transform: translateY(-5px);
	}

	.one_item:hover .one_item_img {
		border-color: #D30E0E;
	}

	.one_item:hover .rightBox .titleBox .leftBox .title {
		color: #D30E0E;
	}

	.one_item:nth-of-type(2n) {
		margin-right: 0px;
	}
</style>
