<template>
	<div class="one_item" @click="goPage(item.id,item.firstId,item.secondId)" v-for="item in videoList">
		<div class="imgBox">
			<img v-if='item.is_vip == 1' class="vip" src='../../../assets/icon/ze-vip-card.png' />
			<img class="play" src="../../../assets/icon/ze-play-alt.png" />
			<img class="one_item_img" :src="item.video_img" />
		</div>
		<p class="title line1">{{item.title}}</p>
		<!-- <p class="info line1">{{item.intro}}</p> -->
	</div>
</template>
<script setup>
	import {
		ref,
		watchEffect,
		inject
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	const route = useRoute();
	const props = defineProps({
		videoList: Array,
		firstId:Number
	})
	let videoList = ref([]);
	let firstId = ref(0);
	watchEffect(() => {
		videoList.value = props.videoList
		firstId.value = props.firstId
	})
	const goPage = (id,newFirstId,newSecondId) => {
		if(firstId.value ==5){
			router.push({
				name: 'video_detail',
				query: {
					id: firstId.value,
					infoId:id
				}
			})
		}else{
			router.push({
				name: 'video_detail',
				query: {
					id: route.query.id ?route.query.id :newFirstId,
					secondId:route.query.secondId ? route.query.secondId :newSecondId,
					infoId:id
				}
			})
		}
		
	}
</script>

<style lang="scss" scoped>
	.one_item:hover .play{
		transform: rotate(360deg);
	}
	.one_item {
		width: 320px;
		height: 294px;
		background-color: #fff;
		cursor: pointer;
		transition: all 0.3s;
		margin-bottom: 10px;
		.imgBox {
			width: 320px;
			height: 224px;
			position: relative;

			.one_item_img {
				width: 100%;
				height: 224px;
				display: block;
			}

			.vip {
				position: absolute;
				width: 30px;
				height: 30px;
				top: 4px;
				right: 4px;
			}

			.play {
				width: 50px;
				height: 50px;
				position: absolute;
				top: 0px;
				bottom: 0px;
				left: 0px;
				right: 0px;
				margin: auto;
				transition: all 0.5s;
			}
		}

		.title {
			height: 30px;
			line-height: 30px;
			color: rgba(16, 16, 16, 1);
			font-size: 16px;
			text-align: left;
			font-family: Roboto;
			margin-top: 4px;
			padding: 0 10px;
			box-sizing: border-box;
		}

		.info {
			height: 30px;
			line-height: 30px;
			color: rgba(154, 154, 154, 1);
			font-size: 12px;
			text-align: left;
			font-family: Roboto;
			padding: 0 10px;
			box-sizing: border-box;
		}
	}
	.one_item:nth-of-type(1),
	.one_item:nth-of-type(2),
	.one_item:nth-of-type(3),
	.one_item:nth-of-type(4),
	.one_item:nth-of-type(5) {
		margin-bottom: 10px;
	}

	.one_item:hover {
		transform: translateY(-5px);
		background-color: #D30E0E;
	}

	.one_item:hover .title,
	.one_item:hover .info {
		color: #fff;
	}
</style>
