<template>
	<el-form style="width: 600px;" label-width="100px">
		<el-form-item label="姓名">
			<el-input v-model="formValue.name" placeholder="请输入收货人姓名" :readonly='props.lookFlag'></el-input>
		</el-form-item>
		<el-form-item label="联系电话">
			<el-input v-model="formValue.telephone" placeholder="请输入收货人联系电话" :readonly='props.lookFlag'></el-input>
		</el-form-item>
		<el-form-item label="所在省份">
			<!-- {{formValue.addressCode}} -->
			<el-cascader v-model="formValue.addressCode" :options="cityData" :props='{value:"code",label:"name"}' placeholder="请选择省市区" :readonly='props.lookFlag' />
		</el-form-item>
		<!-- <el-form-item label="联系电话">
			<el-input placeholder="请输入收货人联系电话" :readonly='props.lookFlag'></el-input>
		</el-form-item> -->
		<el-form-item label="详细地址">
			<el-input v-model="formValue.address" placeholder="请输入小区/楼号" :readonly='props.lookFlag'></el-input>
		</el-form-item>
		<!-- <el-form-item label="邮箱">
			<el-input placeholder="请输入邮箱" :readonly='props.lookFlag'></el-input>
		</el-form-item> -->
		<el-form-item label="是否设为默认地址" label-width="150px">
			<el-radio-group v-model="formValue.is_default" :disabled='props.lookFlag'>
				<el-radio :label="1">是</el-radio>
				<el-radio :label="0">否</el-radio>
			</el-radio-group>
		</el-form-item>
		<div v-if='!props.lookFlag' style="display: flex;justify-content: flex-end;margin-top: 20px;">
			<div class="equitBtn" @click="goBack">取消</div>
			<div class="sureBtn" @click='keepInfo'>保存</div>
		</div>
	</el-form>
</template>

<script setup>
	import {
		addressAddApi,
		areaCodeApi
	} from '@/api/index'
	import {
		toRef,
		ref,
		watchEffect
	} from 'vue';
	import {
		ElMessage
	} from 'element-plus';
	const props = defineProps({
		lookFlag: Boolean,
		formValue: Object,
	})
	const emit = defineEmits(['goBackFn', 'getAddressListApiFn']);
	let formValue = ref({});
	let cityData = ref([]);
	const getAreaCodeApiFn = async () => {
		const res = await areaCodeApi();
		// console.log("res", res)
		cityData.value = res.data;
	}
	getAreaCodeApiFn()
	watchEffect(() => {
		formValue.value = props.formValue
	})
	const goBack = () => {
		emit('goBackFn')
	}
	const keepInfo = () => {
		let data = {
			...formValue.value,
			province: formValue.value.addressCode ? formValue.value.addressCode[0] : '',
			city: formValue.value.addressCode ? formValue.value.addressCode[1] : '',
			area: formValue.value.addressCode ? formValue.value.addressCode[2] : '',
		}
		delete data.addressCode;
		// console.log("formValue.value");
		if (formValue.value.id) {

		} else {
			addressAddApi(data).then(res => {
				ElMessage({
					type: "success",
					message: "添加成功！",
					onClose() {
						emit('getAddressListApiFn')
					}
				})
			})
		}

	}
</script>

<style lang="scss" scoped>
	.equitBtn {
		width: 100px;
		height: 35px;
		line-height: 35px;
		border-radius: 4px;
		background-color: rgba(239, 239, 239, 0.5);
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		border: 1px solid rgba(187, 187, 187, 1);
		cursor: pointer;
	}

	.sureBtn {
		width: 100px;
		height: 35px;
		line-height: 35px;
		border-radius: 4px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		margin-left: 7px;
		cursor: pointer;
	}
</style>
