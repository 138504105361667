<template>
	<div class="container">
		<div class="detailBox flex" v-loading = 'loading'>
			<div class="leftBox">
				<!-- 视频收费观看  未登录不能看  非会员不能看 -->
				<template
					v-if='(videoData.video.is_vip== 1 && userInfo =="") || (videoData.video.is_vip== 1 && userInfo.level == 0)'>
					<img class="videoImg" :src="videoData.video.video_img" />
					<img class="img_icon" src="../../assets/icon/pause.png" />
					<div class="tips">
						开通会员可观看完整视频
					</div>
					<template v-if="buyVipFlag">
						<activateVip @closeFn='closeActivateVipFn'></activateVip>
					</template>
				</template>
				<!-- 视频免费观看 -->
				<template v-else>
					<video controls class="videoImg" :src="videoData.video.video_file?.[0].url"></video>
					<template v-if="buyVipFlag">
						<activateVip @closeFn='closeActivateVipFn'></activateVip>
					</template>
				</template>
			</div>
			<div class="rightBox">
				<div class="title">{{videoData.collect.title}}</div>
				<div class="time">发布时间：{{videoData.collect.created_at}}</div>
				<div class="desc">
					{{videoData.collect.intro}}
				</div>
				<div class="listBox">
					<div class="title">视频选集<span>（{{video_num}}/{{videoData.video_collect_list.length}}）</span></div>
					<ul ref='infiniteUlRef' class="infinite-list" style="overflow: auto">
						<li v-for="(item,index) in videoData.video_collect_list" :key="index"
							class="infinite-list-item flex between_center" :class='video_num == index ? "on":""'
							@click='goVideoFn(item.id)'>
							<el-popover :show-after='1000' placement="top-start" :width="200" trigger="hover"
								content="课课程视频课程视频课程视频课程视频课程视频课程视频课程视频程视频">
								<template #reference>
									<p class="line1">
										<img v-if='video_num == index'
											src="../../assets/icon/riLine-voiceprint-line.png" />
										{{ index + 1}} {{item.title}}
									</p>
								</template>
							</el-popover>

							<span>{{item.video_file?.[0].video_time}}</span>
						</li>
					</ul>
				</div>
				<div class="vipBox">
					<img src="../../assets/icon/ze-diamond.png" />
					<div class="title">视频免费看</div>
					<div class="buyBtn" @click="activeVipFn">开通会员</div>
				</div>
			</div>
		</div>
		<div>
			<div v-html="videoData.video.intro"></div>
		</div>
	</div>
</template>

<script setup>
	import activateVip from '@/components/activateVip.vue'
	import {
		onMounted,
		ref,
		watch,
		computed
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		scientificVideoDetailsApi,
		userVideoCreateApi
	} from '@/api/index.js'
	import {
		useStore
	} from 'vuex';
	const store = useStore();
	const userInfo = computed(() => {
		return store.state.userInfo ? JSON.parse(store.state.userInfo) : ''
	})
	let infiniteUlRef = ref();
	const router = useRouter();
	const route = useRoute();
	let video_num = ref();
	let videoData = ref({
		collect: {},
		video: {
			video_file: [{}]
		},
		video_collect_list: []
	})
	let loading = ref(true)
	const getScientificVideoDetailsApiFn = () => {
		loading.value = true;
		const data = {
			id: route.query.infoId
		}
		scientificVideoDetailsApi(data).then(res => {
			videoData.value = res.data;
			video_num.value = res.data.video_collect_list.findIndex(item => item.id == route.query.infoId);
			setTimeout(() => {
				infiniteUlRef.value.scrollTop = video_num.value * 45;
			}, 10)
			loading.value = false;
		})
		// 添加视频记录
		if (userInfo.value == '') {
			return ;
		}else{
			userVideoCreateApi({v_id:route.query.infoId})
			// console.log("看了视频",route.query.infoId)
		}
	}
	onMounted(async () => {
		await getScientificVideoDetailsApiFn()
	})
	watch(() => router.currentRoute.value, async (newValue) => {
		await getScientificVideoDetailsApiFn();
	})
	const goVideoFn = (id) => {
		router.push({
			name: 'video_detail',
			query: {
				id: route.query.id,
				secondId: route.query.secondId,
				infoId: id
			}
		})
	}
	let buyVipFlag = ref(false);
	const closeActivateVipFn = () => {
		buyVipFlag.value = false;
	}
	const activeVipFn = () => {
		if (userInfo.value == '') {
			router.push({
				name: 'login',
				query: {
					type: 1
				}
			})
		}else{
			buyVipFlag.value =true
		}
	}
</script>

<style lang="scss" scoped>
	::-webkit-scrollbar {
		width: 5px;
	}

	@keyframes tipsApi {
		from {
			bottom: 70px;
		}

		to {
			bottom: 57px;
		}
	}

	.detailBox {
		margin: 30px 0;

		.leftBox {
			width: 1320px;
			height: 869px;
			border-radius: 4px;
			position: relative;

			.videoImg {
				width: 1320px;
				height: 800px;
				display: block;
			}

			.img_icon {
				width: 30px;
				height: 30px;
				left: 17px;
				bottom: 12px;
				position: absolute;
			}

			.tips {
				width: 212px;
				height: 50px;
				line-height: 50px;
				border-radius: 4px;
				background-color: rgba(70, 79, 97, 1);
				color: rgba(255, 255, 255, 1);
				font-size: 14px;
				text-align: center;
				font-family: Roboto;
				left: 30px;
				bottom: 57px;
				position: absolute;
				animation: tipsApi 0.8s infinite linear forwards;
			}

			.tips::after {
				width: 0px;
				height: 0px;
				border: 8px solid transparent;
				border-top: 8px solid rgba(70, 79, 97, 1);
				content: '';
				position: absolute;
				bottom: -15px;
				left: 8px;
			}
		}

		.rightBox {
			margin-left: 20px;

			.title {
				color: rgba(51, 51, 51, 1);
				font-size: 28px;
				font-weight: bolder;
				text-align: left;
				font-family: SourceHanSansSC-medium;
				margin-bottom: 10px;
			}

			.time {
				color: rgba(51, 51, 51, 1);
				font-size: 12px;
				text-align: left;
				font-family: SourceHanSansSC-light;
				margin-bottom: 15px;
			}

			.desc {
				flex: 1;
				color: rgba(108, 108, 108, 1);
				font-size: 14px;
				font-family: SourceHanSansSC-regular;
				margin-bottom: 20px;
			}

			.listBox {
				width: 300px;
				height: 520px;
				border-radius: 4px;
				background-color: rgba(239, 239, 239, 1);
				padding: 17px 0 0 16px;
				box-sizing: border-box;
				margin: 0 0 17px 0;

				.title {
					color: rgba(51, 51, 51, 1);
					font-size: 20px;
					font-weight: bolder;
					text-align: left;
					font-family: SourceHanSansSC-medium;

					span {
						color: rgba(108, 108, 108, 1);
						font-size: 16px;
						font-weight: lighter;
						text-align: left;
						font-family: SourceHanSansSC-regular;
					}
				}

				.infinite-list {
					height: 450px;
					list-style: none;
				}

				.infinite-list .infinite-list-item {
					height: 45px;
					border-radius: 4px;
					padding: 0 9px 0 6px;
					cursor: pointer;

					p {
						flex: 1;
						margin-right: 10px;
						color: rgba(16, 16, 16, 1);
						font-size: 14px;
						font-family: SourceHanSansSC-regular;

						img {
							width: 16px;
							height: 16px;
						}
					}

					span {
						text-align: end;
						color: rgba(16, 16, 16, 1);
						font-size: 14px;
						font-family: SourceHanSansSC-regular;
					}
				}

				.infinite-list .infinite-list-item.on {
					background-color: rgba(255, 255, 255, 1);
				}
			}

			.vipBox {
				width: 300px;
				height: 100px;
				padding: 15px 21px;
				box-sizing: border-box;
				border-radius: 4px;
				background-color: rgba(51, 51, 51, 1);
				color: rgba(255, 255, 255, 1);
				font-size: 14px;
				font-family: Roboto;
				position: relative;
				cursor: pointer;

				img {
					position: absolute;
					width: 100px;
					height: 100px;
					top: 0px;
					right: 0px;
				}

				.title {
					line-height: 17px;
					color: rgba(255, 255, 255, 1);
					font-size: 12px;
					text-align: left;
					font-family: SourceHanSansSC-extraLight;
					margin-bottom: 11px;
				}

				.buyBtn {
					width: 260px;
					height: 40px;
					line-height: 40px;
					border-radius: 4px;
					background-color: rgba(211, 14, 14, 1);
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					text-align: center;
					font-family: Roboto;
				}
			}
		}
	}
</style>
