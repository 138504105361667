<template>
	<div class="title">视频记录</div>
	<div class="videoBox flex between_center" v-loading='loading'>
		<videoCom :videoList='videoList'></videoCom>
	</div>
	<el-empty :description='"暂无观看过的视频"' v-if='pageOption.total == 0' />
	<el-pagination v-else background layout="prev, pager, next" :total="pageOption.total" class="mt-4"
		v-model:page-size='pageOption.page_size' @current-change='handleCurrentChange' />
</template>
<script setup>
	import videoCom from '@/components/home/com/video.vue'
	import {
		onMounted,
		watch,
		ref
	} from 'vue'
	import {
		userVideoIndexApi
	} from '@/api/index.js'
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	const videoList = ref([]); //视频数据
	const route = useRoute();
	const router = useRouter();
	const pageOption = ref({
		page: 1,
		page_size: 8,
		total: 0,
	})
	let loading = ref(true)
	const userVideoIndexApiFn = (name) => {
		loading.value = true;
		const data = {
			page: pageOption.value.page,
			page_size: pageOption.value.page_size,
		}
		userVideoIndexApi(data).then(res => {
			pageOption.value.total = res.data.total
			videoList.value = res.data.list.map(item => ({
				id: item.v_id,
				intro: item.v_intro,
				is_vip: item.v_is_vip,
				title: item.v_title,
				video_img: item.v_video_img,
				firstId:item.v_ac_id_s,
				secondId:item.v_ac_id
			}));
			loading.value = false;
		})
	}
	onMounted(() => {
		userVideoIndexApiFn()
	})
	const handleCurrentChange = (current) => {
		pageOption.value.page = current;
		userVideoIndexApiFn()
	}
</script>

<style lang="scss" scoped>
	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 23px;
	}

	.el-pagination {
		margin-bottom: 0px;
	}

	.videoBox {
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-top: 18px;

		:deep() {
			.one_item {
				border: 1px solid rgba(239, 239, 239, 1);
				margin-bottom: 10px;
				margin-right: 20px;
			}
		}
	}
</style>
