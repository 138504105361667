<template>
	<div class='headerHair'>
		<div class="container flex between_center">
			<div class="leftBox flex">
				<span class="logoText">业迈生物 TEL:{{tel}}</span>
				<span></span>
				<span class="loginText" v-if='userInfo == ""' @click="goLoginFn(1)">请登录</span>
				<span class="registerText" v-if='userInfo == ""' @click="goLoginFn(2)">免费注册</span>
				<span class="Tips">温馨提示：如页面显示不全，请调整浏览器网页比例为100%，可正常查看</span>
			</div>
			<div class="rightBox flex">
				<div class="one_item flex" @click="goPage('message')">
					<img src="../assets/icon/ze-message.png" />
					系统消息
				</div>
				<div class="one_item flex" @click="collectFn()" @keydown.ctrl.d="handleKeyDown">
					<img src="../assets/icon/ze-star.png" />
					收藏本站
				</div>
				<div class="one_item flex" @click="goPage('order')">
					<img src="../assets/icon/ze-orders.png" />
					我的订单
				</div>
			</div>
		</div>
	</div>
	<div class="logoBox">
		<div class="container flex between_center">
			<div class="leftBox flex" style="margin-top: 10px;">
				<img class="logo" :src="logo" style="width: 280px;"/>
				<!-- <text class="logoText">业迈生物</text> -->
			</div>
			<div class='rightBox' style="margin-top: 20px;width: 800px;">
				<div class='searchBox flex' style="width: 800px;">
					<el-select v-model="searchType" class="m-2 searchOpt" placeholder="请选择" size="large">
						<el-option v-for="item in searchList" :key="item.name" :label="item.name" :value="item.name" />
					</el-select>
					<input v-model="searchText" placeholder="搜索商品、文章、会议、通知" />
					<div class='btnBox flex center_center' @click="searchFn">
						<img src="../assets/icon/ze-search.png" />
					</div>
				</div>
				<div style="line-height: 40px;font-size: 14px;font-weight: bolder;color:#d30e0e">搜索前请选择下拉菜单中对应的分类</div>
				<!-- 搜索前请选择下拉菜单中对应的分类 -->
			</div>
		</div>
	</div>
	<div class="menuBox">
		<div class="container flex">
			<div class="product flex center_center">
				<img src="../assets/icon/ze-bars.png" />
				全部产品分类
			</div>
			<div class="navBox flex between_center">
				<span class='nav-item'
					:style="{'color':item.is_label == 1 ?item.label_colour:'#333'}"
					:class="item.id ? route.query.id == item.id ? 'nav-item-active':'':item.url == route.path ?'nav-item-active':''"
					v-for="item in menuList" @click="goPageFn(item.url,item.id,item.is_type)">{{item.name}}</span>
				<!-- <router-link class="nav-item" to="/home">网站首页</router-link>
				<router-link v-for="item in menuList" class="nav-item" :class="item.title == '润色/翻译'?'nav-item-red':''" :to="item.url">{{item.title}}</router-link> -->

			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		computed,
		onMounted
	} from 'vue'
	import {
		getNavListApi
	} from '@/api/index'
	import {
		useStore
	} from 'vuex';
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn'
	import {
		ElMessage
	} from 'element-plus'
	const router = useRouter();
	const route = useRoute();
	const store = useStore();
	const userInfo = computed(() => {
		return store.state.userInfo ? JSON.parse(store.state.userInfo) : ''
	})
	let searchText = ref(sessionStorage.getItem('searchInfo') ? JSON.parse(sessionStorage.getItem('searchInfo')).text :'');
	let menuList = ref([]);
	let searchList = ref([]);
	let searchType = ref(sessionStorage.getItem('searchInfo') ? JSON.parse(sessionStorage.getItem('searchInfo')).searchType :'产品中心');
	const getNavListApiFn = async () => {
		const res = await getNavListApi();
		const list = [{
			name: '网站首页',
			url: '/home'
		}, {
			name: '产品中心',
			url: '/product_index'
		}]
		menuList.value = [...list, ...res.data, {
			name: '公司介绍',
			url: '/company_index'
		}];
		sessionStorage.setItem('menuList',JSON.stringify(menuList.value));
		searchList.value = [list[1],...res.data];
		// console.log("menuList", menuList.value)
	}
	let tel = ref('');
	let logo = ref('');
	onMounted(async() => {
		getNavListApiFn();
		tel.value =await getInfoApiFn(16);
		logo.value = await getInfoApiFn(12);
		logo.value = JSON.parse(logo.value)[0].url
	})
	const searchFn = () => {
		if (searchType.value != '' && searchText.value != '') {
			const result = searchList.value.find(item => item.name === searchType.value);
			const searchInfo = {
				text:searchText.value,
				searchType:searchType.value
			}
			// console.log('searchInfo',searchInfo);
			sessionStorage.setItem('searchInfo',JSON.stringify(searchInfo));
			let data = {
				name:'',
				query:{
					text: searchText.value
				}
			}
			if(result.url){
				data.name = result.url.split('/')[1];
			}else{
				data.name = 'scientific_list'
			}
			if (result.id) {
				data.query.id= result.id;
				data.query.type=result.is_type
			}
			// console.log('data', data)
			router.push(data)
		}

	}
	const goLoginFn = (type) => {
		router.push({
			name: 'login',
			query: {
				type: type
			}
		})
	}
	const goPage = (name) => {
		router.push({
			name: name,
		})
	}
	const goPageFn = (url, id,type) => {
		let data = {
			name:''
		}
		if(url){
			data.name = url.split('/')[1];
		}else{
			data.name = 'scientific_list'
		}
		if (id) {
			data.query = {
				id: id,
				type
			}
		}
		// console.log('data', data)
		router.push(data)
	}
	const collectFn = () => {
		const pageTitle = document.title ? document.title : '业迈生物'; // 获取当前页面的标题
		const pageUrl = window.location.href; // 获取当前页面的URL
		try {
			window.external.AddFavorite(pageUrl, pageTitle); // 调用浏览器的AddFavorite方法
		} catch (e) {
			if (window.sidebar && window.sidebar.addPanel) { // 兼容Firefox
				window.sidebar.addPanel(pageTitle, pageUrl, '');
			} else {
				ElMessage.error('您的浏览器不支持自动添加到收藏夹，请使用Ctrl+D手动添加。');
			}
		}
	}
	const handleKeyDown = () => {
		// console.log("document.querySelector")
	}
</script>

<style lang="scss" scoped>
	:deep() {
		.router-link-exact-active {
			color: rgba(211, 14, 14, 1);
			font-weight: bolder;
		}
	}

	.headerHair {
		height: 40px;
		background-color: rgba(51, 51, 51, 1);

		.container {
			.leftBox {
				align-items: center;
				height: 40px;

				.logoText {
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
				}

				.loginText {
					color: rgba(209, 14, 15, 1);
					font-size: 14px;
					margin-left: 50px;
					cursor: pointer;
				}

				.registerText {
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					margin-left: 20px;
					cursor: pointer;
				}
				
				.Tips {
					color: rgba(255,255,255,1);
					font-size: 14px;
					margin-left: 50px;
				}
			}

			.rightBox {
				align-items: center;
				height: 40px;

				.one_item {
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					margin-left: 20px;
					align-items: center;
					cursor: pointer;

					img {
						width: 16px;
						height: 16px;
						margin-right: 7px;
					}
				}
			}
		}
	}

	.logoBox {
		height: 100px;

		.container {
			.leftBox {
				height: 100px;
				align-items: center;

				.logo {
					width: 80px;
					height: 80px;
				}

				.logoText {
					color: rgba(211, 14, 14, 1);
					font-size: 40px;
					margin-left: 10px;
					font-weight: bolder;
				}
			}

			.rightBox {
				width: 500px;

				.searchBox {
					width: 500px;
					height: 40px;
					border: 1px solid rgba(211, 14, 14, 1);
					box-sizing: border-box;

					.searchOpt {
						width: 150px;
						height: 36px;
						border: none;
					}

					:deep() {
						.el-select .el-input__wrapper {
							box-shadow: none !important;
							height: 36px;
						}
					}

					input {
						flex: 1;
						border: none;
						padding-left: 15px;
						box-sizing: border-box;
						outline: none;
						border-left: 1px solid rgba(211, 14, 14, 1);
					}

					.btnBox {
						width: 100px;
						height: 38px;
						background-color: rgba(211, 14, 14, 1);
						cursor: pointer;

						img {
							width: 28px;
							height: 28px;
						}
					}
				}

				.hotBox {
					margin-top: 5px;

					span {
						line-height: 20px;
						font-size: 14px;
						margin-right: 10px;
						cursor: pointer;
					}

					span:hover {
						color: rgba(211, 14, 14, 1);
					}

					span:nth-last-of-type(1) {
						margin-right: 0px;
					}
				}
			}
		}
	}

	.menuBox {
		border-bottom: 2px solid #E05959;
		margin-top: 30px;

		.container {
			.product {
				width: 280px;
				height: 50px;
				background-color: rgba(211, 14, 14, 1);
				color: rgba(255, 255, 255, 1);
				font-size: 18px;
				cursor: pointer;
				position: relative;

				img {
					width: 28px;
					height: 28px;
					margin-right: 10px;
				}
			}

			.navBox {
				flex: 1;
				margin-left: 19px;

				.nav-item {
					height: 50px;
					line-height: 50px;
					font-size: 18px;
					text-align: center;
					font-family: Roboto;
					cursor: pointer;
				}

				.nav-item-red {
					color: rgb(211, 14, 14);
				}

				.nav-item-active {
					color: rgba(211, 14, 14, 1) !important;
					font-weight: bolder;
				}
			}

		}
	}
</style>
