<template>
	<div class="productNavBox flex between_center">
		<div class="container breadcrumb">
			<el-breadcrumb :separator-icon="ArrowRight">
				<el-breadcrumb-item :to="{ path: '/' }">网站首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/order' }">个人中心</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
	<div class="container centerBox flex">
		<div class="leftBox">
			<div class="headerHair">
				<div class="imgBox">
					<img class="class" :src="userInfo.level!=0 ? userInfo.level_icon : vip_img" />
					<img class="headerImg" :src="userInfo.head_pic_url" />
				</div>
				<div class="helloText">
					您好<br />
					{{userInfo.telephone ? getPhone(userInfo.telephone) :''}}
				</div>
				<div class="descBox flex">
					<span class="span1 flex center_center"
						v-if='userInfo.level==0 && userInfo.level_time!=null && userInfo.level_time!=""'>会员已过期</span>
					<span class="span1 flex center_center"
						v-if='userInfo.level!=0 && userInfo.level_time!=""'>{{userInfo.level_time}}到期</span>
					<span class="span1 flex center_center"
						v-if='userInfo.level==0&&userInfo.level_time==null'>您还不是vip会员</span>
					<span class="span2"
						@click="buyVipBtnFn">{{userInfo.level==0&&userInfo.level_time==null ? '开通会员':userInfo.level!=0 && userInfo.level_time!=""?userInfo.name:'续费会员'}}</span>
				</div>
				<div v-if='userInfo!=""' class="loginOut" @click="loginOutFn">退出登录</div>
			</div>
			<div class="person_menu">
				<div class="first flex between_center">
					交易管理
					<img src="../../assets/icon/ze-arrow-up.png" />
				</div>
				<div class="second">
					<router-link to='/order'>我的订单</router-link>
				</div>
				<div class="second">
					<router-link to='/afterSales'>退款/售后</router-link>
				</div>
				<div class="first flex between_center">
					我的账户
					<img src="../../assets/icon/ze-arrow-up.png" />
				</div>
				<div class="second">
					<router-link to='/index'>个人资料</router-link>
				</div>
				<div class="second">
					<router-link to='/addressList'>地址管理</router-link>
				</div>
				<div class="second">
					<router-link to='/message'>消息通知</router-link>
				</div>
				<div class="second">
					<router-link to='/collect'>我的收藏</router-link>
				</div>
				<div class="second">
					<router-link to='/password'>密码设置</router-link>
				</div>
				<div class="second">
					<router-link to='/video'>视频记录</router-link>
				</div>
				<div class="second">
					<router-link to='/invoicingList'>发票管理</router-link>
				</div>
			</div>
		</div>
		<div class="rightBox">
			<router-view></router-view>
		</div>
		<template v-if="buyVipFlag">
			<activateVip @closeFn='closeActivateVipFn'></activateVip>
		</template>
	</div>
</template>

<script setup>
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import {
		ref,
		computed,
		onMounted
	} from 'vue'
	import activateVip from '@/components/activateVip.vue'
	import {
		ElMessage
	} from 'element-plus';
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn'
	import {
		useStore
	} from 'vuex';
	const store = useStore();
	const userInfo = computed(() => {
		return store.state.userInfo ? JSON.parse(store.state.userInfo) : ''
	})
	const buyVipBtnFn = ()=>{
		if((userInfo.value.level==0&&userInfo.value.level_time==null) ||(userInfo.value.level==0 && userInfo.value.level_time!="")){
			buyVipFlag.value = true
		}
	}
	const getPhone = (phone) => {
		if (phone == '')
			return ''
		const data = "" + phone[0] + phone[1] + phone[2] + '****' + phone[7] + phone[8] + phone[9] + phone[10]
		return data
	}
	const buyVipFlag = ref(false);
	const closeActivateVipFn = () => {
		buyVipFlag.value = false;
	}
	const loginOutFn = () => {
		ElMessage({
			type: 'success',
			message: '成功退出账号',
			onClose() {
				localStorage.clear();
				window.location.href = '/'
			}
		})
	}
	let vip_img = ref('')
	onMounted(async () => {
		console.log('userInfo',userInfo.value)
		vip_img.value = await getInfoApiFn(18);
		vip_img.value = JSON.parse(vip_img.value)[0].url
	})
</script>

<style lang="scss" scoped>
	:deep() {
		.router-link-exact-active {
			color: rgba(211, 14, 14, 1);
			font-weight: bolder;
		}
	}

	.productNavBox {
		height: 60px;

		:deep() {
			.el-breadcrumb__item:nth-of-type(2) .el-breadcrumb__inner.is-link {
				color: #D30E0E;
			}
		}
	}

	.centerBox {
		margin-bottom: 30px;

		.leftBox {
			width: 200px;
			margin-right: 20px;

			.headerHair {
				width: 200px;
				border-radius: 4px;
				background-color: rgba(211, 14, 14, 1);
				padding: 0 14px 16px;
				box-sizing: border-box;

				.imgBox {
					position: relative;
					padding-top: 22px;

					.headerImg {
						display: block;
						margin: 0 auto;
						width: 80px;
						height: 80px;
						border-radius: 4px;
					}

					.class {
						position: absolute;
						width: 58px;
						height: 46px;
						top: -2px;
						left: 0px;
						right: 0px;
						margin: auto;
					}
				}

				.helloText {
					margin-top: 10px;
					color: rgba(255, 255, 255, 1);
					font-size: 18px;
					text-align: center;
					font-family: AlibabaPuHui-regular;
				}

				.descBox {
					margin-top: 12px;

					.span1 {
						width: 106px;
						height: 40px;
						border-radius: 40px 0px 0px 40px;
						background-color: rgba(51, 51, 51, 1);
						color: rgba(255, 255, 255, 1);
						font-size: 12px;
						text-align: center;
						font-family: Roboto;
					}

					.span2 {
						width: 66px;
						height: 40px;
						line-height: 40px;
						border-radius: 0px 40px 40px 0px;
						background-color: rgba(255, 248, 29, 1);
						color: rgba(51, 51, 51, 1);
						font-size: 12px;
						text-align: center;
						font-family: Roboto;
						cursor: pointer;
					}
				}

				.loginOut {
					margin-top: 16px;
					color: rgba(255, 255, 255, 1);
					font-size: 12px;
					text-align: center;
					font-family: AlibabaPuHui-regular;
					cursor: pointer;
				}
			}

			.person_menu {
				margin-top: 15px;
				width: 200px;
				box-sizing: border-box;
				border-radius: 4px;
				background-color: rgba(239, 239, 239, 0.5);
				padding: 0 20px 68px;

				.first {
					height: 55px;
					color: rgba(16, 16, 16, 1);
					font-size: 15px;
					font-weight: bolder;
					text-align: left;
					font-family: Roboto;

					img {
						width: 16px;
						height: 16px;
						transform: rotate(180deg);
					}
				}

				.second {
					color: rgba(16, 16, 16, 1);
					line-height: 35px;
					font-size: 15px;
					text-align: center;
					font-family: Roboto;
				}
			}
		}

		.rightBox {
			width: calc(100% - 220px);
			padding: 20px 10px;
			box-sizing: border-box;
			background-color: #EFEFEF;
		}
	}
</style>
