import { createStore } from 'vuex'
import {getSearchList} from '@/utils/data.js'
export default createStore({
  state: {
	userInfo:localStorage.getItem('userInfo') ? localStorage.getItem('userInfo') :'',
  },
  getters: {
	  
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
