import request from '@/utils/request.js'

// 购物车列表
export function carListApi(params) {
	return request({
		url: '/app/car/list',
		method: 'get',
		params
	})
}
// 购物车删除
export function carBatchDelApi(params) {
	return request({
		url: '/app/car/batchDel',
		method: 'post',
		params
	})
}
// 购物车编辑
export function carEditApi(params) {
	return request({
		url: '/app/car/edit',
		method: 'post',
		params
	})
}
// 下单
export function orderCreateApi(params) {
	return request({
		url: '/app/order/create',
		method: 'post',
		params
	})
}
// 支付订单
export function orderPayApi(params) {
	return request({
		url: '/app/order/pay',
		method: 'post',
		params
	})
}
// 支付订单
export function orderVipPayApi(params) {
	return request({
		url: '/app/order/vipPay',
		method: 'post',
		params
	})
}
// 
export function getVipOrderDetailApi(params) {
	return request({
		url: '/app/viporder/detail',
		method: 'get',
		params:{
			...params
		}
	})
}