<template>
	<div class="title">{{pageType == 'add' ? '添加收货地址':'修改收货地址'}}</div>
	<addAddressCom v-loading="loading" :formValue='formValue' @goBackFn='goBack'></addAddressCom>
</template>

<script setup>
	import {
		ref,
		watchEffect,
		inject,
		onMounted
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	import {
		addressDetailApi,
		areaCodeApi,
	} from '@/api/index'
	import addAddressCom from '@/components/person/address/addAddressCom.vue'
	const route = useRoute();
	const router = useRouter();
	let pageType = ref(null);
	const formValue = ref({
		name: ''
	})
	let loading = ref(true)
	onMounted(async () => {
		await getAreaCodeApiFn();
		pageType.value = route.query.type;
		loading.value = true;
		if (pageType.value == 'add') { //新增
			loading.value = false;
		} else {
			const data = {
				id: route.query.id
			}
			const res = await addressDetailApi(data);
			formValue.value = res.data;
			formValue.value.addressCode = [formValue.value.province, formValue.value.city, formValue.value.area]
			loading.value = false;
		}
	});
	let cityData = ref()
	const getAreaCodeApiFn = async () => {
		const res = await areaCodeApi();
		// console.log("res", res)
		cityData.value = res.data;
	}
	const goBack = () => {
		router.back()
	}
</script>

<style lang="scss" scoped>
	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 23px;
	}
</style>
