<template>
	<bannerSwiper></bannerSwiper>
	<div class="container">
		<div class="peoduct_classify">
			<div class="line_one flex">
				<span class="title">一级分类：</span>
				<div class="classify_type flex">
					<span :class="firstId == '' ? 'on':''" @click="changeFirstDataFn('')">全部</span>
					<span :class="firstId == item.id ? 'on':''" v-for="(item,index) in classifyList"
						@click="changeFirstDataFn(item.id)">{{item.name}}</span>
				</div>
			</div>
			<div class="line_one flex" v-if='firstId != ""'>
				<span class="title">二级分类：</span>
				<div class="classify_type flex">
					<span :class="secondId == '' ? 'on':''" @click="changeSecondDataFn('')">全部</span>
					<span :class="secondId == item.id ? 'on':''" v-for="(item,index) in secondClassifyList"
						@click="changeSecondDataFn(item.id)">{{item.name}}</span>
				</div>
			</div>
			<div class="line_one flex">
				<span class="title">品牌：</span>
				<div class="classify_type flex">
					<span :class="brand_id == '' ? 'on':''" @click="changebrandIdFn('')">全部</span>
					<span :class="brand_id == item.id ? 'on':''" v-for="(item,index) in brandList"
						@click="changebrandIdFn(item.id)">{{item.name}}</span>
				</div>
			</div>
			<div class="line_one flex">
				<span class="title">筛选：</span>
				<div class="classify_type flex">
					<span @click="changeSortFn(1,1)" :class="sort_type == 1 ? 'on':''">综合</span>
					<span @click="changeSortFn(2,2)" :class="sort_type == 2 ? 'on':''">
						销售量
						<div class="upDownBox">
							<el-icon size="12" :color="sort_type==2 &&sort==2 ? '#D30E0E':'#333'"
								@click.stop="changeSortFn(2,2)">
								<ArrowUp />
							</el-icon>
							<el-icon size="12" :color="sort_type==2 &&sort==1 ? '#D30E0E':'#333'"
								@click.stop="changeSortFn(2,1)">
								<ArrowDown />
							</el-icon>
						</div>
					</span>
					<span @click="changeSortFn(3,2)" :class="sort_type == 3 ? 'on':''">
						价格
						<div class="upDownBox">
							<el-icon size="12" :color="sort_type==3 &&sort==2 ? '#D30E0E':'#333'"
								@click.stop="changeSortFn(3,2)">
								<ArrowUp />
							</el-icon>
							<el-icon size="12" :color="sort_type==3 &&sort==1 ? '#D30E0E':'#333'"
								@click.stop="changeSortFn(3,1)">
								<ArrowDown />
							</el-icon>
						</div>
					</span>

				</div>
			</div>
		</div>
		<div class="productBox flex between_center" v-loading='loading'>
			<productCom :productList='productList'></productCom>
		</div>
		<el-empty :description='"暂无商品"' description="description" v-if='pageOption.total == 0' />
		<el-pagination v-else background layout="prev, pager, next" :total="pageOption.total" class="mt-4"
			v-model:page-size='pageOption.page_size' @current-change='currentChange' />

	</div>
</template>

<script setup>
	import bannerSwiper from '@/components/product/bannerSwiper.vue'
	import productCom from '@/components/home/com/product.vue'
	import {
		computed,
		onMounted,
		ref,
		watch
	} from 'vue'
	import {
		getProductBrand,
		getProductClass,
		getProductClassProducts
	} from '@/api/index'
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	const route = useRoute();
	const router = useRouter();
	const classifyList = ref(); //一级分类数据
	const secondClassifyList = ref(); //一级分类数据
	let brandList = ref(); //品牌数据
	const productList = ref([]); //产品数据
	let firstId = ref(''); //一级分类id
	let secondId = ref(''); //二级分类id
	let brand_id = ref(''); //品牌id
	let sort_type = ref(1); //筛选方式
	let sort = ref(1); //1 升序   2降序
	const pageOption = ref({
		page: 1,
		page_size: 24,
		total: 0,
	})
	let loading = ref(true)
	const getProductListFn = (name) => {
		loading.value = true;
		const data = {
			class_id: secondId.value == '' ? firstId.value : secondId.value,
			brand_id: brand_id.value,
			sort_type: sort_type.value,
			sort: sort.value,
			page: pageOption.value.page,
			page_size: pageOption.value.page_size,
			name,
		}
		getProductClassProducts(data).then(res => {
			pageOption.value.total = res.data.total
			productList.value = res.data.list;
			loading.value = false;
		})
	}
	onMounted(async () => {
		getProductBrand().then(res => {
			brandList.value = res.data;
		})
		classifyList.value = await getClassifyListFn(0);
		if (route.query.firstId) {
			await changeFirstDataFn(route.query.firstId)
			await changeSecondDataFn(route.query.secondId)
		}
		await getProductListFn(route.query.text);
	})
	watch(() => router.currentRoute.value, (newValue) => {
		// console.log('newValue', newValue);
		getProductListFn(route.query.text);
	}, {
		immediate: true
	})
	const getClassifyListFn = async (pid) => {
		let data = {};
		if (pid == 0) {
			data.pid = 0;
			data.level = 1;
		} else {
			data.pid = pid;
			data.level = 2;
		}
		const res = await getProductClass(data);
		return res.data;
	}
	const changeFirstDataFn = async (newfirstId) => {
		firstId.value = newfirstId;
		secondClassifyList.value = await getClassifyListFn(newfirstId);
		secondId.value = '';
		getProductListFn()
	}
	const changeSecondDataFn = (newsecondId) => {
		secondId.value = newsecondId;
		getProductListFn()
	}
	const changebrandIdFn = (newValue) => {
		brand_id.value = newValue;
		getProductListFn()
	}
	const changeSortFn = (newValue1, newValue2) => {
		sort_type.value = newValue1;
		sort.value = newValue1 == 1 ? '' : newValue2;
		getProductListFn()
	}
	const currentChange = (current) => {
		// console.log("current", current);
		pageOption.value.page = current;
		getProductListFn()
	}
</script>

<style lang="scss" scoped>
	.upDownBox {
		display: flex;
		flex-direction: column;
	}

	.container {
		.peoduct_classify {
			padding: 20px;
			box-sizing: border-box;
			border-radius: 4px;
			border: 1px solid rgba(187, 187, 187, 1);
			margin: 30px 0;

			.line_one {
				.title {
					height: 40px;
					line-height: 40px;
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					text-align: left;
					font-family: Roboto;
				}

				.classify_type {
					flex-wrap: wrap;
					flex: 1;

					span {
						min-width: 100px;
						padding: 0 20px;
						box-sizing: border-box;
						line-height: 40px;
						text-align: center;
						cursor: pointer;
						font-size: 14px;
						position: relative;

						.upDownBox {
							width: 12px;
							height: 24px;
							position: absolute;
							top: 0px;
							bottom: 0px;
							right: 2px;
							margin: auto;

						}
					}

					span.on,
					span:hover {
						color: #D30E0E;
					}

					span:hover .upDownBox {
						color: #333;
					}
				}
			}
		}

		.productBox {
			flex-wrap: wrap;
			justify-content: flex-start;

			:deep() {
				.one_item {
					border: 1px solid rgba(239, 239, 239, 1);
					margin-bottom: 10px;
					margin-right: calc((100% - (265px * 6))/5);
				}

				.one_item:nth-of-type(6n) {
					margin-right: 0px;
				}

				.one_item:hover {
					border: 1px solid rgba(211, 14, 14, 1);
				}
			}
		}
	}
</style>
