<template>
	<div class="bannerBox">
		<div class="container flex">
			<div class="classifyBox">
				<div class="firstMenuBox">
					<template v-for='(item,index) in classifyList'>
						<text class="line1" @mouseenter='changeFirstDataFn(index)'>{{item.name}}</text>
						<el-divider v-if='(index+1)%6==0'></el-divider>
					</template>
				</div>
				<div class="secondMenuBox">
					<p class="firstP">{{classifyList.length>0 ? classifyList[hoverIndex].name :''}}</p>
					<template v-for="item in secondClassifyList">
						<text @click="goProductIndex(classifyList[hoverIndex].id,item.id)">{{item.name}}</text>
					</template>
				</div>
			</div>
			<div class="rightBox">
				<el-carousel height="350px">
					<el-carousel-item v-for="(item,index) in bannerList" :key="index" @click="goDetail(item.id)">
						<img style='height:350px' :src="item.url" />
					</el-carousel-item>
				</el-carousel>
				<div class='productBox'>
					<swiper style="height: 137px;" :navigation="productSwiper_navigation" :loop='true'
						:modules="productSwiper_modules" :slides-per-view="5" :space-between="5">
						<swiper-slide v-for="(item,index) in productList">
							<div class="product_one" @click="goDetail(item.id)">
								<img class="hotImg" src="../../assets/icon/ze-hot-sale.png" />
								<img class='productImg' :src="item.pic_urls && item.pic_urls.length>0?item.pic_urls[0]:''" />
								<p class='title line1'>{{item.name}}</p>
							</div>
						</swiper-slide>
					</swiper>
					<div class="product-swiper-button product-swiper-button-prev">
						<img src="../../assets/icon/ze-arrow-left.png" />
					</div>
					<div class="product-swiper-button product-swiper-button-next">
						<img src="../../assets/icon/ze-arrow.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<scientificCom></scientificCom>
	<articleCom></articleCom>
	<organismCom></organismCom>
	<experimentCom></experimentCom>
	<centerCom></centerCom>
	<fundCom></fundCom>
	<el-backtop :right="60" :bottom="200" visibility-height='600'>
		<div class="back_one_item flex center_center">
			<img src="../../assets/icon/vertical_align_top.png"/>
			返回顶部
		</div>
	</el-backtop>
</template>

<script setup>
	import {
		ref,
		inject,
		onMounted
	} from 'vue'
	import {
		getProductClass,
		getRecProductApi
	} from '@/api/index'
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn.js'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import {
		Navigation
	} from "swiper/modules";
	import 'swiper/css';
	import "swiper/scss/navigation";
	import scientificCom from '@/components/home/scientific.vue';
	import articleCom from '@/components/home/article.vue';
	import organismCom from '@/components/home/organism.vue';
	import experimentCom from '@/components/home/experiment.vue';
	import centerCom from '@/components/home/center.vue';
	import fundCom from '@/components/home/fund.vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const router = useRouter();
	const classifyList = ref([]); //分类数据
	const secondClassifyList = ref(); //分类数据
	const bannerList = ref([]); //轮播图数据
	let hoverIndex = ref(0); //划入的index
	const productList = ref([]); //产品数据
	const productSwiper_navigation = ref({
		nextEl: '.product-swiper-button-next',
		prevEl: '.product-swiper-button-prev',
	});
	const productSwiper_modules = [Navigation];
	const goDetail = (id) => {
		if(id){
			router.push({
				name: 'product_detail',
				query: {
					id,
				}
			})
		}
	}
	onMounted(async () => {
		classifyList.value = await getClassifyListFn(0);
		bannerList.value = await getInfoApiFn(5);
		bannerList.value = JSON.parse(bannerList.value);
		getRecProductApiFn()
	})
	const getRecProductApiFn = async()=>{
		const res =await getRecProductApi();
		productList.value = res.data;
	}
	const changeFirstDataFn = async (index) => {
		secondClassifyList.value =[]
		hoverIndex.value = index;
		secondClassifyList.value = await getClassifyListFn(classifyList.value[index].id);
	}
	const getClassifyListFn = async (pid) => {
		let data = {};
		if (pid == 0) {
			data.pid = 0;
			data.level = 1;
		} else {
			data.pid = pid;
			data.level = 2;
		}
		const res = await getProductClass(data);
		return res.data;
	}
	const goPage = () => {
		router.push({
			name: 'product_detail',
			query: {
				id:1
			}
		})
	}
	const goProductIndex = (firstId,secondId)=>{
		router.push({
			name: 'product_index',
			query: {
				firstId,
				secondId,
			}
		})
	}
</script>

<style lang="scss" scoped>
	.el-backtop{
		width: 55px;
		height: 55px;
		background-color: #fff;
		border-radius: 10px;
	}
	.back_one_item {
		width: 55px;
		height: 55px;
		flex-direction: column;
		color: rgba(16, 16, 16, 1);
		font-size: 11px;
		font-family: AlibabaPuHui-regular;
		border-bottom: 1px solid #efefef;
		cursor: pointer;
		border-radius: 10px;
		box-shadow: 0px 2px 6px 0px rgba(190, 190, 190, 1);
		&:nth-of-type(3) {
			border: none;
		}

		img {
			width: 16px;
			height: 16px;
			margin-bottom: 4px;
		}
	}

	.bannerBox {
		height: 527px;
		background-color: #efefef;

		.classifyBox {
			transition: all 0.3s;
			position: relative;

			text {
				display: inline-block;
				width: 100%;
				margin-bottom: 10px;
				cursor: pointer;
			}

			:deep() {
				.el-divider--horizontal {
					margin: 8px auto;
				}
			}

			.firstMenuBox {
				width: 280px;
				height: 517px;
				padding: 15px 25px;
				box-sizing: border-box;
				background-color: #fff;
				overflow-y:auto;
			}

			.firstMenuBox:hover+.secondMenuBox,
			.secondMenuBox:hover {
				display: block;
			}

			.secondMenuBox {
				position: absolute;
				top: 0px;
				left: 280px;
				z-index: 9;
				background: linear-gradient(to right, #efefef 10px, white 10px);
				width: 425px;
				height: 517px;
				padding: 15px 20px;
				box-sizing: border-box;
				display: none;

				.firstP {
					color: rgba(211, 14, 14, 1);
					font-size: 16px;
					font-weight: bolder;
					text-align: left;
					font-family: AlibabaPuHui-medium;
					margin-bottom: 20px;
				}
			}
		}

		.rightBox {
			width: calc(100% - 290px);
			margin-left: 10px;

			.productBox {
				height: 157px;
				background-color: #fff;
				margin-top: 10px;
				padding: 10px;
				box-sizing: border-box;
				position: relative;

				.product-swiper-button {
					width: 30px;
					height: 60px;
					border-radius: 4px;
					position: absolute;
					z-index: 2;
					top: 0px;
					bottom: 0;
					margin: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					img {
						width: 30px;
						height: 30px;
					}
				}

				.product-swiper-button-prev {
					background-color: rgba(0, 0, 0, 0.5);
					left: 0px;
				}

				.product-swiper-button-next {
					background-color: rgba(205, 166, 87, 1);
					right: 0px;
				}

				.product_one {
					width: 258px;
					height: 137px;
					border: 1px solid rgba(239, 239, 239, 1);
					position: relative;
					cursor: pointer;

					.productImg {
						width: 100%;
						height: 107px;
						display: block;
					}

					.hotImg {
						position: absolute;
						top: 0px;
						right: 0px;
						width: 30px;
						height: 30px;
					}

					.title {
						height: 30px;
						line-height: 30px;
						padding: 0 5px;
						box-sizing: border-box;
						background-color: rgba(239, 239, 239, 1);
						color: rgba(16, 16, 16, 1);
						font-size: 16px;
						font-family: Roboto;
					}
				}
			}
		}
	}

	:deep() {
		.el-carousel__button {
			width: 8px;
			height: 8px;
			border-radius: 8px;
			background-color: #333;
		}
	}
</style>
