<template>
	<div class="title">密码设置</div>
	<el-form style="width: 600px;" label-width="100px">
		<el-form-item label="登录密码：">
			<el-input type="password" show-password v-model="formValue.old_password" placeholder="请输入原始密码"></el-input>
		</el-form-item>
		<el-form-item label="新密码：">
			<el-input type="password" show-password v-model="formValue.password" placeholder="请输入新密码"></el-input>
		</el-form-item>
		<el-form-item label="确认密码：">
			<el-input type="password" show-password v-model="formValue.rep_password" placeholder="请再次输入新密码"></el-input>
		</el-form-item>
		<div style="display: flex;justify-content: flex-end;margin-top: 20px;">
			<div class="sureBtn" @click="submitFn">确定</div>
		</div>
	</el-form>
</template>

<script setup>
	import {
		ref
	} from 'vue'
	import {
		postUserPerfectApi
	} from '@/api/index'
	import {
		ElMessage
	} from 'element-plus';
	const formValue = ref({
		op_type: 7,
		old_password: '',
		password: '',
		rep_password:''
	})
	const submitFn = () => {
		postUserPerfectApi(formValue.value).then(res => {
			ElMessage({
				type: 'success',
				message: '修改成功'
			})
		})
	}
</script>

<style lang="scss" scoped>
	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 23px;
	}

	.equitBtn {
		width: 100px;
		height: 35px;
		line-height: 35px;
		border-radius: 4px;
		background-color: rgba(239, 239, 239, 0.5);
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		border: 1px solid rgba(187, 187, 187, 1);
		cursor: pointer;
	}

	.sureBtn {
		width: 100px;
		height: 35px;
		line-height: 35px;
		border-radius: 4px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		margin-left: 7px;
		cursor: pointer;
	}
</style>
