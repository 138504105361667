<template>
	<div class="container">
		<el-carousel height="250px">
			<el-carousel-item v-for="(item,index) in bannerList" :key="index">
				<img style='height:250px' :src="item.url" @click="goDetail(item.id)"/>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref
	} from 'vue'
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn.js'
	const bannerList = ref([]); //轮播图数据
	const goDetail = (id) => {
		if(id){
			router.push({
				name: 'product_detail',
				query: {
					id,
				}
			})
		}
	}
	onMounted(async()=>{
		bannerList.value = await getInfoApiFn(17);
		bannerList.value = JSON.parse(bannerList.value)
	})
</script>

<style lang="scss" scoped>
	:deep() {
		.el-carousel__button {
			width: 8px;
			height: 8px;
			border-radius: 8px;
			background-color: #333;
		}
		
	}
</style>