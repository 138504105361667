<template>
	<div class="scientificBox">
		<div class="container flex">
			<classifyBox :title='"生物信息学"' :classifyList='scientificClassify' :activeIndex='scientificIndex' @getDataListFn='getDataFn' :firstId='firstId' :type='type'></classifyBox>
			<div class="scientificNews flex">
				<organismNewsCom :newsList='scientificNewsList' :firstId='firstId' :type='type' :secondId='secondId'></organismNewsCom>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		computed,
		onMounted
	} from 'vue'
	import classifyBox from './com/classifyBox.vue'
	import organismNewsCom from './com/organismNews.vue'
	import {
		scientificIndexListApiFn,
		getNavIndexTwoPageApiFn
	} from '@/utils/home.js'
	const scientificNewsList = ref([]);
	const scientificClassify = ref([]);
	let scientificIndex = ref(-1);
	const firstId =9;
	const type=3;
	let secondId = computed(() => {
		// console.log(scientificIndex.value, scientificClassify.value.length)
		return scientificIndex.value == scientificClassify.value.length ? firstId : scientificIndex.value == -1 ? 0 : scientificClassify.value[scientificIndex.value].id;
	})
	onMounted(async () => {
		const res = await getNavIndexTwoPageApiFn({
			id: firstId,
			page: 1,
			page_size: 7
		});
		scientificClassify.value = res.data.list;
		await getDataFn(res.data.list.length)
	})
	const getDataFn =async (index) => {
		scientificIndex.value = index;
		const data = {
			id: scientificIndex.value == scientificClassify.value.length ? firstId : scientificClassify.value[
				scientificIndex.value].id,
			page: 1,
			page_size: 24
		}
		const res = await scientificIndexListApiFn(data);
		scientificNewsList.value =res.data.list
	}
</script>

<style lang="scss" scoped>
	.scientificBox {
		// background-color: #efefef;

		.container {
			height: 390px;

			.scientificNews {
				flex: 1;
				margin-left: 10px;
				// justify-content: space-between;
				flex-wrap: wrap;
				height: 360px;
				:deep(){
					.one-item {
						margin-right: calc((100% - 274 * 5px)/4);
						border: 1px solid rgba(239, 239, 239, 1);
					}
					.one-item:nth-of-type(5n) {
						margin-right: 0px;
					}
				}
			}
		}

	}
</style>
