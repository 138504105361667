<template>
	<bannerSwiper></bannerSwiper>
	<div class="container">
		<div class="navBox flex">
			<div class="title flex center_center">
				<span class="span1">{{currentUrl?.name}}</span>
				<span class="span2" v-if="currentUrl?.name_vice">{{currentUrl?.name_vice}}</span>
			</div>
			<div class="menuBox" ref='menuBoxRef'>
				<swiper style="height: 40px;" :navigation="swiper_navigation" :modules="swiper_modules"
					:slides-per-view="7" :space-between="5" :key='initial_slide' :initial-slide="initial_slide">
					<swiper-slide>
						<span  @click="changeActiveIndex(-1)" :class='classifyId ==-1 ? "on":""'>全部</span>
					</swiper-slide>
					<swiper-slide v-for="(item,index) in navTwoList">
						<span @click="changeActiveIndex(item.id)"
							:class='classifyId == item.id ? "on":""'>{{item.name}}</span>
					</swiper-slide>
				</swiper>
				<div class="swiper-button-next">
					<img src="../../assets/icon/ze-arrow.png" />
				</div>
			</div>
			<div class="navText flex">
				当前位置：
				<el-breadcrumb :separator-icon="ArrowRight">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-if="route.path.includes('list')">{{currentUrl?.name}}{{currentUrl?.name_vice}}
					</el-breadcrumb-item>
					<template v-else>
						<el-breadcrumb-item :to="{ path: 'scientific_list' ,query:{id:currentUrl?.id,type:currentUrl?.is_type}}">
							{{currentUrl?.name}}{{currentUrl?.name_vice}}
						</el-breadcrumb-item>
						<el-breadcrumb-item>详情</el-breadcrumb-item>
					</template>
				</el-breadcrumb>`
			</div>
		</div>
	</div>
	<router-view></router-view>
</template>

<script setup>
	import bannerSwiper from '@/components/product/bannerSwiper.vue'
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import {
		Navigation
	} from "swiper/modules";
	import 'swiper/css';
	import "swiper/scss/navigation";
	import {
		onMounted,
		ref,
		watch,
		onBeforeMount,
		nextTick
	} from 'vue'
	import {
		useRouter,
		useRoute
	} from 'vue-router';
	import {
		getNavIndexTwoApi,
	} from '@/api/index'
	const menuList = ref();
	let listFlag = ref(true);
	const swiper_navigation = ref({
		nextEl: '.swiper-button-next',
	});
	const swiper_modules = [Navigation];
	let firstId = ref(1); //一级分类id
	let classifyId = ref(-1); //二级分类id
	const router = useRouter();
	const route = useRoute();
	let currentUrl = ref();
	let initial_slide = ref();
	let navTwoList = ref([]); //二级数据
	const getNavIndexTwoApiFn = async (id) => {
		// console.log("id", id)
		const data = {
			id
		}
		const res = await getNavIndexTwoApi(data);
		navTwoList.value = res.data;
		// console.log("res", res)
	}
	onBeforeMount(() => {
		
	})
	onMounted(() => {
		setTimeout(() => {
			console.log(JSON.parse(sessionStorage.getItem('menuList')))
			menuList.value = JSON.parse(sessionStorage.getItem('menuList'))
			currentUrl.value = menuList.value.find(obj => obj.id == route.query.id)
			initial_slide.value = route.query.secondId ? route.query.secondId : 0;
			classifyId.value = route.query.secondId ? route.query.secondId : -1;
			firstId.value = route.query.id;
			getNavIndexTwoApiFn(route.query.id);
		},1500)
		// nextTick(() => {
		// 	console.log("999",JSON.parse(sessionStorage.getItem('menuList')))
		// })
	})
	watch(() => router.currentRoute.value, (newValue) => {
		firstId.value = newValue.query.id;
		classifyId.value = newValue.query.secondId ? newValue.query.secondId : -1;
		initial_slide.value = Number(newValue.query.secondId ? newValue.query.secondId : -1);
		currentUrl.value = menuList.value.find(obj => obj.id == newValue.query.id);
		getNavIndexTwoApiFn(newValue.query.id);
	})
	const changeActiveIndex = (id) => {
		let query = {
			id: firstId.value,
			type:currentUrl.value.is_type,
		}
		if(id != -1){
			query.secondId = id;
		}
		router.push({
			name: 'scientific_index',
			query
		})
	}
</script>

<style lang="scss" scoped>
	// @import url('@/assets/css/common_header.scss');
	.swiper-button-next:after,
		.swiper-rtl .swiper-button-prev:after {
			content: "";
		}
	
		.navBox {
			position: relative;
	
			.title {
				width: 200px;
				height: 100px;
				border-radius: 4px;
				background-color: rgba(51, 51, 51, 1);
				position: absolute;
				top: -50px;
				left: 0px;
				flex-direction: column;
				.span1{
					color: rgba(255, 255, 255, 1);
					font-size: 28px;
				}
				.span2{
					margin-top: 5px;
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					font-family: SourceHanSansSC-regular;
				}
			}
	
			.menuBox {
				width: calc(100% - 550px);
				height: 40px;
				margin-top: 10px;
				margin-left: 210px;
				padding-right: 50px;
				box-sizing: border-box;
				position: relative;
	
				span {
					display: block;
					min-width: 129px;
					padding: 0 10px;
					box-sizing: border-box;
					height: 40px;
					line-height: 40px;
					border-radius: 4px;
					background-color: rgba(239, 239, 239, 1);
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					text-align: center;
					font-family: Roboto;
					cursor: pointer;
				}
	
				span.on {
					background-color: rgba(211, 14, 14, 1);
					color: rgba(255, 255, 255, 1);
				}
	
				.swiper-button-next {
					width: 40px;
					height: 40px;
					border-radius: 4px;
					background-color: rgba(211, 14, 14, 1);
					position: absolute;
					bottom: 0px;
					right: 0px;
	
					img {
						width: 15px;
						height: 15px;
					}
				}
			}
	
			.navText {
				width: 330px;
				align-items: center;
				justify-content: flex-end;
				line-height: 20px;
				margin-top: 30px;
				padding-left: 20px;
				color: rgba(51, 51, 51, 1);
				font-size: 14px;
				text-align: right;
				font-family: AlibabaPuHui-regular;
	
				span {
					cursor: pointer;
				}
			}
		}
</style>
