import {
	createRouter,
	createWebHistory
} from 'vue-router'
import index from '@/views/index.vue'
import login from '@/views/login/index.vue'
import home from '@/views/home/index.vue'
import product_index from '@/views/product/index.vue'
import product_detail from '@/views/product/detail.vue'
import scientific_index from '@/views/scientific/index.vue'
import scientific_list from '@/views/scientific/list.vue'
import scientific_detail from '@/views/scientific/detail.vue'
import video_detail from '@/views/video/detail.vue'
import company_index from '@/views/company/index.vue'
import agreement from '@/views/company/agreement.vue'
import person from '@/views/person/index.vue'
import order from '@/views/person/order/index.vue'
import order_detail from '@/views/person/order/detail.vue'
import order_logistics from '@/views/person/order/logistics.vue'
import order_afterSales from '@/views/person/sales/afterSales.vue'
import order_applySales from '@/views/person/sales/applySales.vue'
import order_invoicing from '@/views/person/invoicing/index.vue'
import personIndex from '@/views/person/person/index.vue'
import invoicingList from '@/views/person/invoicing/list.vue'
import addressList from '@/views/person/address/list.vue'
import addArddress from '@/views/person/address/add.vue'
import password from '@/views/person/password/index.vue'
import collect from '@/views/person/collect/index.vue'
import message from '@/views/person/message/index.vue'
import person_video from '@/views/person/video/index.vue'
import cart_order_info from '@/views/cart/orderInfo.vue'
import cart_index from '@/views/cart/index.vue'
import cart_settlement from '@/views/cart/settlement.vue'
import pay_success from '@/views/cart/pay_success.vue'

const routes = [{
	path: '/',
	name: 'index',
	component: index,
	redirect: '/home',
	children: [{
			path: '/home',
			name: 'home',
			component: home
		},
		{
			path: '/product_index',
			name: 'product_index',
			component: product_index
		},
		{
			path: '/product_detail',
			name: 'product_detail',
			component: product_detail
		},
		{
			path: '/scientific_index',
			name: 'scientific_index',
			component: scientific_index,
			redirect: '/scientific_list',
			children: [
				{
					path: '/scientific_list',
					name: 'scientific_list',
					component: scientific_list,
				},
				{
					path: '/scientific_detail',
					name: 'scientific_detail',
					component: scientific_detail
				},
				{
					path: '/video_detail',
					name: 'video_detail',
					component: video_detail,
				},
			]
		},{
			path: '/company_index',
			name: 'company_index',
			component: company_index
		}, {
			path: '/agreement',
			name: 'agreement',
			component: agreement
		},{
			path: '/cart_order_info',
			name: 'cart_order_info',
			component: cart_order_info
		}, {
			path: '/cart_index',
			name: 'cart_index',
			component: cart_index
		}, {
			path: '/cart_settlement',
			name: 'cart_settlement',
			component: cart_settlement
		}, {
			path: '/pay_success',
			name: 'pay_success',
			component: pay_success
		}, {
			path: '/person',
			name: 'person',
			component: person,
			redirect: '/order',
			children: [{
					path: '/order',
					name: 'order',
					component: order
				}, {
					path: '/order_detail',
					name: 'order_detail',
					component: order_detail
				}, {
					path: '/order_logistics',
					name: 'order_logistics',
					component: order_logistics
				}, {
					path: '/afterSales',
					name: 'order_afterSales',
					component: order_afterSales
				}, {
					path: '/applySales',
					name: 'order_applySales',
					component: order_applySales
				}, {
					path: '/invoicing',
					name: 'order_invoicing',
					component: order_invoicing
				}, {
					path: '/invoicingList',
					name: 'invoicingList',
					component: invoicingList
				}, {
					path: '/addressList',
					name: 'addressList',
					component: addressList
				}, {
					path: '/addArddress',
					name: 'addArddress',
					component: addArddress
				}, {
					path: '/password',
					name: 'password',
					component: password
				}, {
					path: '/collect',
					name: 'collect',
					component: collect
				}, {
					path: '/message',
					name: 'message',
					component: message
				}, {
					path: '/video',
					name: 'person_video',
					component: person_video
				},
				{
					path: '/index',
					name: 'personIndex',
					component: personIndex
				}
			]
		},
	]
}, {
	path: '/login',
	name: 'login',
	component: login
}]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
