<template>
	<div class="title">申请售后/退款</div>
	<div v-loading='loading'>
		<div class="orderDetail flex">
			<div class="one_item flex">
				<div class="text_tit">订单编号：</div>
				<div class="text_info">{{goodsInfo.order_no}} </div>
			</div>
		</div>
		<div class="redText">商品信息：</div>
		<div class="goodsBox flex" v-for="item in goodsInfo.products">
			<img :src="item.product_pic_url" />
			<div class="centerBox">
				<div class="goods_title">{{item.product_name}}</div>
				<div class="goods_text">货号：{{item.product_sku_spec_code}}</div>
				<div class="goods_text">规格：
					<!-- <span v-if='item.is_multi == 1'>
						<template v-for="sku in item.product_sku_titles">
							{{sku.spec_title}}:{{sku.spec_value}}&nbsp;&nbsp;
						</template>
					</span>
					<span v-else>普通规格</span> -->
					<span v-if='item.is_multi == 1'>
						<template v-if="Object.prototype.toString.call(item.product_sku_titles) == '[object Array]'">
							<template v-for="sku in item.product_sku_titles">
								{{sku.spec_title}}:{{sku.spec_value}}&nbsp;&nbsp;
							</template>
						</template>
						<template v-else>
							原规格被删除或更改
						</template>
					</span>
					<span v-else>普通规格</span>
				</div>
			</div>
			<div class="price">￥{{item.product_price}}×{{item.count}}</div>
		</div>
		<el-form style="width: 600px;margin-top: 40px;" :model="formValue" label-width="120px">
			<el-form-item label="退款类型：">
				<el-select v-model="formValue.refund_type" placeholder="请选择退款类型" style="width: 100%">
					<el-option v-for="item in returnTypeOptions" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="退款原因：">
				<el-select v-model="formValue.refund_reason" placeholder="请选择退款原因" style="width: 100%">
					<el-option v-for="item in returnOptions" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="退款金额：">
				<el-input v-model='returnPrice'></el-input>
				<p class="tips">最多￥{{returnPrice}}</p>
			</el-form-item>
			<el-form-item label="退款说明：">
				<el-input type="textarea" size="small" v-model="formValue.refund_remark" />
			</el-form-item>
			<!-- <el-form-item label="上传凭证：">
				<el-upload v-model:file-list="fileList"
					action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" list-type="picture-card"
					:on-remove="handleRemove">
					<el-icon>
						<Plus />
					</el-icon>
				</el-upload>
			</el-form-item> -->
			<div style="display: flex;justify-content: flex-end;margin-top: 20px;">
				<div class="sureBtn" @click="submitFn">提交</div>
			</div>
		</el-form>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref,
		inject,
		computed
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getCommonEnumApi,
		getRefundFeeApi,
		postApplyRefundApi
	} from '@/api/index.js'
	import {
		Plus
	} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus';
import router from '@/router';
	const route = useRoute();
	let goodsInfo = ref(JSON.parse(sessionStorage.getItem('applySalesInfo')));
	const getReturnOptionsFn = () => {
		getCommonEnumApi().then(res => {
			returnOptions.value = Object.entries(res.data.refund_reason).map(([value, label]) => ({
				value,
				label
			}));
			returnTypeOptions.value = Object.entries(res.data.refund_type).map(([value, label]) => ({
				value,
				label
			}));
		})
	}
	onMounted(() => {
		getReturnOptionsFn()
		getReturnPriceFn();
	})
	let loading = ref(false);
	const formValue = ref({
		refund_type: '',
		refund_reason:'',
		refund_remark:''
	})
	let returnPrice = ref('')
	let getReturnPriceFn = async () => {
		let data = {
			order_id: goodsInfo.value.order_id,
			products: [{
				product_id: goodsInfo.value.products[0].product_id,
				product_sku: goodsInfo.value.products[0].product_sku,
			}],
		}
		const res = await getRefundFeeApi(data);
		returnPrice.value = res.data.total_refund_fee
	}
	const returnOptions = ref([]);
	const returnTypeOptions = ref([]);
	const fileList = [{
		name: 'food.jpeg',
		url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
	}, ]
	const submitFn = () => {
		let data = {
			order_id: goodsInfo.value.order_id,
			products: [{
				product_id: goodsInfo.value.products[0].product_id,
				product_sku: goodsInfo.value.products[0].product_sku,
			}],
			is_repeat: goodsInfo.value.refund_id == 0 ? 0 : 1,
			...formValue.value
		}
		if (goodsInfo.value.refund_id != 0) {
			data.org_refund_id = goodsInfo.value.refund_id
		}
		postApplyRefundApi(data).then(res=>{
			ElMessage({
				type:'success',
				message:'已提交申请',
				onClose() {
					router.back();
				}
			})
		})
	}
</script>

<style lang="scss" scoped>
	.sureBtn {
		width: 100px;
		height: 35px;
		line-height: 35px;
		border-radius: 4px;
		background-color: rgba(211, 14, 14, 1);
		color: rgba(255, 255, 255, 1);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		margin-left: 7px;
		cursor: pointer;
	}

	:deep(.el-upload--picture-card) {
		width: 100px;
		height: 100px;
	}

	:deep(.el-upload-list--picture-card .el-upload-list__item) {
		width: 100px;
		height: 100px;
	}

	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
	}

	.orderDetail {
		margin: 20px 0 20px;

		.one_item {
			margin-right: 20px;

			.text_tit {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-weight: bolder;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}

			.text_info {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}
		}
	}

	.redText {
		color: rgba(211, 14, 14, 1);
		font-size: 18px;
		text-align: left;
		font-family: AlibabaPuHui-regular;
		margin-bottom: 12px;
		margin-top: 20px;
	}

	.goodsBox {
		align-items: center;
		margin-bottom: 10px;

		img {
			width: 90px;
			margin-right: 20px;
		}

		.centerBox {
			width: 400px;
			margin-right: 44px;

			.goods_title {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-weight: bolder;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}

			.goods_text {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}
		}

		.price {
			width: 100px;
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: left;
			font-family: AlibabaPuHui-regular;
			margin-right: 20px;
		}
	}

	.tips {
		color: rgba(108, 108, 108, 1);
		font-size: 14px;
		text-align: left;
		margin-top: 10px;
		font-family: AlibabaPuHui-regular;
	}
</style>
