<template>
	<div class="title">消息通知</div>
	<div v-loading="loading">
		<div class="one-item" v-for='item in announceListData'>
			<el-tooltip effect="dark" :content="item.title" placement="top-start" show-after='1500'>
				<p class="message_title line1">·
					{{item.title}}
				</p>
			</el-tooltip>
			<el-tooltip effect="dark" :content="item.content" placement="top-start" show-after='1500'>
				<p class="desc line2">
					{{item.content}}
				</p>
			</el-tooltip>
		</div>
		<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="pageOptions.total" :page-size="5" class="mt-4" />
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref
	} from 'vue';
	import {
		announceListApi
	} from '@/api/index'
	const pageOptions = ref({
		page: 1,
		page_size: 5,
		total: 0
	})
	onMounted(() => {
		getAnnounceListApiFn()
	})
	const announceListData = ref([]);
	let loading = ref(true);
	const getAnnounceListApiFn = () => {
		loading.value = true;
		const data = {
			page: pageOptions.value.page,
			page_size: pageOptions.value.page_size
		}
		announceListApi(data).then(res => {
			announceListData.value = res.data.list;
			pageOptions.value.total = res.data.total;
			loading.value = false;
		})
	}
	const handleCurrentChange = (current) => {
		pageOptions.value.page = current;
		getAnnounceListApiFn()
	}
</script>

<style lang="scss" scoped>
	:deep(.el-pagination) {
		margin-bottom: 10px;
	}

	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 23px;
	}

	.one-item {
		padding: 20px;
		margin-bottom: 10px;
		border-radius: 4px;
		background-color: rgba(255, 255, 255, 1);

		.message_title {
			color: rgba(16, 16, 16, 1);
			font-size: 16px;
			font-weight: bolder;
			font-family: AlibabaPuHui-medium;
			cursor: pointer;
		}

		.desc {
			margin-top: 10px;
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			font-family: AlibabaPuHui-regular;
			cursor: pointer;
		}
	}
</style>
