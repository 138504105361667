<template>
	<div class="container" v-loading = 'loading'>
		<detailCom :detail='detail' :tipsFlag='true' @changeNewsId='getarticleDetailsApiFn'></detailCom>
	</div>
</template>

<script setup>
	import detailCom from '@/components/newsDetail/com/detail.vue'
	import {
		onMounted,
		ref,
		inject
	} from 'vue'
	import {
		articleDetailsApi
	} from '@/api/index.js'
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	const route = useRoute();
	const router = useRouter();
	let detail = ref({
		data:{},
		prev:{},
		next:{}
	})
	let loading = ref(false)
	const getarticleDetailsApiFn = (id)=>{
		loading.value=true;
		const data = {
			id
		}
		articleDetailsApi(data).then((res)=>{
			detail.value = res.data;
			loading.value=false;
		})
	}
	onMounted(()=>{
		getarticleDetailsApiFn(route.query.infoId)
	})
	
</script>
