<template>
	<div class="title">地址管理</div>
	<addressCom :addressType='addressType' :activeAddress='activeAddress' @chooseAddress = 'chooseAddressFn'></addressCom>
</template>

<script setup>
	import addressCom from '@/components/person/address/index'
	import {ref,onMounted} from "vue"
	let activeAddress = ref({id:''});
	const addressType ='addressList'
	const chooseAddressFn = (item)=>{
		activeAddress.value = item
	}
</script>

<style lang="scss" scoped>
	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 23px;
	}
</style>