import axios from 'axios'
import {
	ElMessage
} from 'element-plus';
import {
	useRoute,
	useRouter
} from 'vue-router'
const router = useRouter();
const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,
	timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(config => {
		const token = localStorage.getItem('token');
		config.headers['source'] = 6;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		if (config.url == "/api/manage/upload") {
			config.headers['Content-Type'] = 'multipart/form-data';
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(response => {
		const {
			code
		} = response.data
		if (code !== 200) {
			ElMessage.error(response.data.message)
			return Promise.reject(new Error(response.data.message))
		} else {
			return response.data
		}
	},
	// 失败
	error => {
		if (error.response.status == 401) {
			localStorage.clear();
			ElMessage({
				type: 'error',
				message: '请重新登录',
				onClose() {
					try {
						window.location.href='/login?type=1'
						return Promise.reject(error)
					} catch (err) {
						// console.log("err", err)
					}
				}
			})
		} else {
			ElMessage.error('系统错误，详情请联系工作人员')
		}
		return Promise.reject(new Error(error.response))
	}
)

export default service
