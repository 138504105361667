<template>
	<div class="goods-sku">
		<dl v-for="(spec, idx) in goodsData.specs" :key="idx">
			<dt>{{ spec.name }}:</dt>
			<dd>
				<template v-for="value in spec.values" :key="value.name">
					<span class="sku-state-mixin" @click="clickSpecs(value, spec.values)" :class="{ selected: value.selected, disabled: value.disabled }">{{ value.name }}</span>
				</template>
			</dd>
		</dl>

	</div>
</template>
<script>
	// import { ref } from 'vue'
	import bwPowerSet from '@/utils/power-set'
	const spliter = '★'
	// 根据skus数据得到路径字典对象
	const getPathMap = (skus) => {
		// // console.log(skus.forEach(it => { // console.log(it) }))
		const pathMap = {}
		skus.forEach(sku => {
			// 1. 过滤出有库存有效的sku
			if (sku.stock) {
				// 2. 得到sku属性值数组
				const specs = sku.specs.map(spec => spec.valueName)
				// 3. 得到sku属性值数组的子集
				const powerSet = bwPowerSet(specs)
				// // console.log(specs)
				// // console.log(powerSet)
				// 4. 设置给路径字典对象
				powerSet.forEach(set => {
					const key = set.join(spliter)
					if (pathMap[key]) {
						// 已经有key往数组追加
						pathMap[key].push(sku.id)
					} else {
						// 没有key设置一个数组
						pathMap[key] = [sku.id]
					}
				})
			}
		})
		// console.log(pathMap)
		return pathMap
	}

	// 1.获取用户已经选中的条件
	const getSelectedArr = specs => {
		return specs.map(spec => {
			// 找到这个属性下，用户的选择
			const value = spec.values.find(it => it.selected === true)

			return value ? value.name : undefined
		})
		// return [undefined,'中国',undefined]
	}
	// 2.对于每个按钮来说：在组合当前的按钮对应的
	// 更新按钮的禁用状态
	const updateDisabledStatus = (pathMap, specs) => {
		// 用户的选择[undefined,'中国',undefined]
		const _selectedArr = getSelectedArr(specs)

		specs.forEach((spec, idx) => {
			const selectedArr = [..._selectedArr]
			spec.values.forEach(btn => {
				// 已经选中的
				if (btn.name === selectedArr[idx]) {
					return
				}
				// 将最后一选项填入用户选择的最后一项
				selectedArr[idx] = btn.name

				// 去掉undefined拼接字符串,再去查询
				const key = selectedArr.filter(v => v).join(spliter)
				// 若找不到 设置为true
				btn.disabled = !pathMap[key] // （undefined取反）
			})
		})
	}

	// 根据skuId还原用户选中的规格
	const initSelectedStatus = (goodsData, skuId) => {
		// 1.找到选中的具体的规格
		const sku = goodsData.skus.find(sku => sku.id === skuId)
		if (sku) {
			const selectArr = sku.specs.map(it => it.valueName)
			goodsData.specs.forEach((spec, idx) => {
				spec.values.forEach(value => {
					value.selected = (value.name === selectArr[idx])
				})
			})
		}
		// 2.设置对应的按钮selected为true
	}
	export default {
		name: 'GoodsSku',
		props: {
			goodsData: {
				type: Object,
				default: () => ({
					specs: [],
					skus: []
				})
			},
			skuId: {
				type: String,
				default: ''
			}
		},
		setup(props, {
			emit
		}) {
			const clickSpecs = (value, values) => {
				// 如果是禁用状态则不做处理
				if (value.disabled) {
					return
				}
				if (value.selected) {
					value.selected = false // 已选中改为未选中
				} else {
					// 把兄弟全改为未选中
					values.forEach(it => {
						it.selected = false
					})
					value.selected = true // 自己：未选中改为已选中
				}
				updateDisabledStatus(pathMap, props.goodsData.specs)
				// 向父组件抛出事件更新skuId
				tryEmit()
			}

			// 向父组件抛出事件更新skuId
			const tryEmit = () => {
				// 触发change事件将sku数据传递出去
				const selectedArr = getSelectedArr(props.goodsData.specs).filter(v => v)
				if (selectedArr.length === props.goodsData.specs.length) {
					const skuIds = pathMap[selectedArr.join(spliter)]
					const sku = props.goodsData.skus.find(sku => sku.id === skuIds[0])
					// 传递
					emit('change', {
						skuId: sku.id,
						price: sku.price,
						oldPrice: sku.oldPrice,
						stock: sku.stock,
						specsText: sku.specs.reduce((p, n) => `${p} ${n.name}：${n.valueName}`, '').replace(' ','')})
				} else {
					// emit('change', {})
				}
			}

			// 根据skuId还原用户选中的规格
			if(props.skuId !=''){
				initSelectedStatus(props.goodsData, props.skuId)
			}
			
			// 生成字典
			// console.log(props.goodsData.skus)
			const pathMap = getPathMap(props.goodsData.skus)

			updateDisabledStatus(pathMap, props.goodsData.specs)

			return {
				clickSpecs
			}
		}
	}
</script>
<style scoped lang="scss">
	.sku-state-mixin{
		border: 1px solid #e4e4e4;
		margin-right: 10px;
		cursor: pointer;
		&.selected {
			border-color: rgba(211, 14, 14, 1);
			color: rgba(211, 14, 14, 1);
		}

		&.disabled {
			opacity: 0.6;
			text-decoration: line-through;
			border-style: dashed;
			cursor: not-allowed;
		}
	}

	.goods-sku {

		dl {
			display: flex;
			padding-bottom: 20px;
			align-items: center;

			dt {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				text-align: left;
				font-family: AlibabaPuHui-regular;
				margin-right: 10px;
			}

			dd {
				flex: 1;
				color: #666;
				>span {
					display: inline-block;
					padding: 0 10px;
					height: 25px;
					line-height: 25px;
					border-radius: 4px;
					color: rgba(16, 16, 16, 1);
					font-size: 12px;
					text-align: center;
					font-family: Roboto;
					border: 1px solid rgba(206, 206, 206, 1);
				}
			}
		}
	}
</style>
