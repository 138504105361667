<template>
	<div class="title">售后订单</div>
	<div class="nav_box flex">
		<span :class="orderActive == 0 ? 'active' :''" @click="changOrderListFn(0)">全部订单</span>
		<span :class="orderActive == 1 ? 'active' :''" @click="changOrderListFn(1)">待审核</span>
		<span :class="orderActive == 2 ? 'active' :''" @click="changOrderListFn(2)">待发货</span>
		<span :class="orderActive == 4 ? 'active' :''" @click="changOrderListFn(4)">待收货</span>
		<span :class="orderActive == 5 ? 'active' :''" @click="changOrderListFn(5)">待退款</span>
		<span :class="orderActive == 6 ? 'active' :''" @click="changOrderListFn(6)">已完成</span>
	</div>
	<div class="searchBox flex">
		<input placeholder="搜索订单号/商品名称" />
		<img src='../../../assets/icon/search.png' @click="getRefundOrderListApiFn(0)"/>
	</div>
	<el-table :data="orderList" border class="orderTable" :cell-style="{'text-align':'center'}"
		:header-cell-style="{'text-align':'center'}" style="width: 100%;" max-height="550" :empty-text="'暂无相关订单'"
		ref='tableRef'  v-loading='loading'>
		<el-table-column prop="info" label="商品名称" width="330">
			<template #default="scope">
				<el-row>
					<el-col :span='3'>
						<img style="width: 100%;" :src="scope.row.product_pic_url" />
					</el-col>
					<el-col :span='8'>
						{{scope.row.product_name}}
					</el-col>
					<el-col :span='8'>
						￥{{scope.row.product_price}}×{{scope.row.refund_count}}
					</el-col>
				</el-row>
			</template>
		</el-table-column>
		<el-table-column prop="order_no" label="订单号" width="230" />
		<el-table-column prop="refund_fee" label="退款金额" width="100">
			<template #default="scope">
				<span class="redText">￥{{scope.row.refund_fee}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="apply_time" label="申请时间" width="230" />
		<el-table-column prop="refund_type_desc" label="服务类型" width="130">
		</el-table-column>
		<el-table-column prop="status_desc" label="交易状态" width="130">
			<template #default="scope">
				<span class="greenText">{{scope.row.status_desc}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="name" label="操作">
			<template #default="scope">
				<template v-for="(comType,index) in statusOptions[scope.row.status].options">
					<orderOptionsCom :comType='comType' :orderType="'afterSales'" :orderId='scope.row.id' :orderNo='scope.row.order_no' @getOrderListApiFn='getRefundOrderListApiFn'></orderOptionsCom>
				</template>
			</template>
		</el-table-column>
	</el-table>
</template>

<script setup>
	import {
		onMounted,
		ref
	} from 'vue'
	import {
		getStatusFn
	} from '@/utils/data.js'
	import {
		getRefundOrderListApi
	} from '@/api/index.js'
	import orderOptionsCom from '@/components/person/order/orderOptionsCom.vue'
	let orderActive = ref(0);
	let keywords = ref('')
	const pageOptions = ref({
		page: 1,
		page_size: 20,
		total: 0,
	})
	const changOrderListFn = (type) => {
		orderActive.value = type;
		getRefundOrderListApiFn(0)
	}
	let loading = ref(false)
	const orderList = ref([]);
	const statusOptions = ref(getStatusFn());
	const tableRef = ref(null);
	const getRefundOrderListApiFn = (type) => {
		loading.value = true;
		if (type == 0) {
			orderList.value = [];
			pageOptions.value.page = 1;
		}
		const data = {
			page: pageOptions.value.page,
			page_size: pageOptions.value.page_size,
			order_status: orderActive.value == 0 ? '' : orderActive.value,
			keywords:keywords.value
		}
		getRefundOrderListApi(data).then(res => {
			pageOptions.value.total = res.data.total
			orderList.value = [...orderList.value, ...res.data.list];
			loading.value = false;
		})
	}
	onMounted(() => {
		getRefundOrderListApiFn(0)
		const target = tableRef.value.$refs.bodyWrapper.getElementsByClassName("el-scrollbar__wrap")[0];
		target.addEventListener("scroll", () => {
			// 滚动距离
			let scrollTop = target.scrollTop;
			// 变量windowHeight是可视区的高度
			let windowHeight = target.clientHeight || target.clientHeight;
			// 变量scrollHeight是滚动条的总高度
			let scrollHeight = target.scrollHeight || target.scrollHeight;
			// 这里是触底 自己按自己业务需求是写逻辑
			if (scrollTop + windowHeight === scrollHeight) {
				// 获取到的不是全部数据 当滚动到底部 继续获取新的数据
				// console.log("到底了！！！");
				if (pageOptions.value.total > orderList.value.length) {
					pageOptions.value.page = pageOptions.value.page + 1;
					getRefundOrderListApiFn(1)
				}
			}
		})
	})
</script>
<style>
	.orderTable .el-table__header th {
		background-color: rgba(51, 51, 51, 1) !important;
		border-right-color: #EFEFEF !important;
	}

	.orderTable .el-table__header th .cell {
		color: #fff !important;
	}
</style>
<style lang="scss" scoped>
	.redText{
		color: rgba(211, 14, 14, 1);
		font-size: 14px;
		text-align: center;
		font-family: Microsoft Yahei;
	}
	.greenText{
		color: #81B337;
		font-size: 14px;
		text-align: center;
		font-family: Microsoft Yahei;
	}
	.searchBox {
		margin: 20px 0;
		width: 348px;
		height: 40px;
		border-radius: 40px;
		background-color: #fff;
		color: rgba(16, 16, 16, 1);
		border: 1px solid rgba(187, 187, 187, 1);
		padding: 0 17px;
		align-items: center;

		input {
			flex: 1;
			margin-right: 5px;
			border: none;
			outline: none;
			height: 30px;
		}

		img {
			width: 16px;
			height: 16px;
			cursor: pointer;
		}
	}

	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
	}

	.nav_box {
		height: 42px;
		margin-top: 20px;
		border-bottom: 1px solid rgba(51, 51, 51, 1);

		span {
			color: #101010;
			font-size: 14px;
			margin-right: 30px;
			text-align: left;
			font-family: AlibabaPuHui-regular;
			cursor: pointer;

			&.active {
				color: #D30E0E;
				position: relative;
			}

			&.active::after {
				content: '';
				position: absolute;
				width: 0%;
				height: 2px;
				bottom: -1px;
				background-color: #D30E0E;
				left: 0px;
				animation: activeAni 0.5s 1 forwards;
			}
		}
	}
	@keyframes activeAni {
		from {
			width: 0%;
		}to{
			width: 100%;
		}
	}
</style>
