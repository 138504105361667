import {
	getInfoApi
} from '@/api/index'
export async function getInfoApiFn(id) {
	// const fn = async () => {
	// 	try {
	// 		const res =await getInfoApi({id});
	// 		return res.data;
	// 	} catch (error) {

	// 	}
	// };
	// fn()
	const res =await getInfoApi({id});
	// console.log("res",res);
	return res.data;
}
