<template>
	<div class="centerBox">
		<div class="container">
			<div class="titleBox flex between_center">
				<span class="title">产品中心</span>
				<div class="typeBox flex">
					<span class='line1' :class='productClassifyId == item.id ? "on":""' v-for="item in classifyList" @click="changeProductClassifyIdFn(item.id)">{{item.name}}</span>
					<span class='line1' @click='goMore("/product_index")'>查看更多 &gt;</span>
				</div>
			</div>
			<div class="productBox flex">
				<productCom :productList='productList'></productCom>
			</div>
			
		</div>
		<div class=" VideoZhongxin">
			<div class="container">
				<div class="titleBox flex between_center" >
					<span class="title">视频中心</span>
					<div class="typeBox flex" style="width: auto;">
						<span @click='goMore2()'>查看更多 &gt;</span>
					</div>
				</div>
				<div class="videoBox flex between_center" >
					<videoCom :videoList='videoList' :firstId='5'></videoCom>
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getProductClassProducts,
		getProductClass
	} from '@/api/index'
	import {
		scientificIndexListApiFn,
		scientificvideoListApiFn,
		getNavIndexTwoPageApiFn
	} from '@/utils/home.js'
	import productCom from './com/product.vue'
	import videoCom from './com/video.vue'
	const classifyList = ref(); //一级分类数据
	let productClassifyId = ref('')
	const getClassifyListFn = async (pid) => {
		let data = {};
		if (pid == 0) {
			data.pid = 0;
			data.level = 1;
		} else {
			data.pid = pid;
			data.level = 2;
		}
		const res = await getProductClass(data);
		return res.data;
	}
	onMounted(async () => {
		classifyList.value = await getClassifyListFn(0);
		getProductListFn();
		getVideoListFn()
	})
	const productList = ref([]); //产品数据
	const videoList = ref([]); //视频数据
	const router = useRouter();
	const goMore = (url) => {
		router.push(url)
	}
	const goMore2 = ()=>{
		let data={
			id: 5,
			type: 2
		}
		router.push({
			name: 'scientific_list',
			query: data
		})
	}
	const changeProductClassifyIdFn = (id)=>{
		productClassifyId.value = id;
		getProductListFn()
	}
	const getProductListFn = () => {
		const data = {
			page: 1,
			page_size: 12,
			class_id:productClassifyId.value,
		}
		getProductClassProducts(data).then(res => {
			productList.value = res.data.list;
		})
	}
	const getVideoListFn =async () => {
		const data = {
			id: 5,
			page: 1,
			page_size: 10
		}
		const res = await scientificvideoListApiFn(data);
		videoList.value =res.data.list
	}
</script>

<style lang="scss" scoped>
	.centerBox {
		// background-color: #efefef;
		padding: 30px 0;

		.titleBox {
			.title {
				height: 40px;
				line-height: 40px;
				color: rgba(51, 51, 51, 1);
				font-size: 28px;
				font-family: SourceHanSansSC-regular;
			}

			.typeBox {
				flex:1;
				justify-content: flex-end;
				span {
					max-width: 100px;
					color: rgba(51, 51, 51, 1);
					font-size: 14px;
					cursor: pointer;
					text-align: right;
					font-family: Roboto;

					&.on , &:hover {
						color: rgb(211, 14, 14);
						font-weight: bolder;
					}
				}
			}

		}

		.productBox {
			margin-top: 30px;
			margin-bottom: 30px;
			flex-wrap: wrap;
			:deep(){
				.one_item{
					margin-right: calc((100% - 265 * 6px)/5);
				}
				.one_item:nth-of-type(6n){
					margin-right: 0px;
				}
			}
		}

		.videoBox {
			flex-wrap: wrap;
			margin-top: 30px;
		}
		
		
	}
	.VideoZhongxin {
		background-color: #efefef;
	}
</style>
