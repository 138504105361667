<template>
	<div class="productNavBox flex between_center">
		<div class="container breadcrumb">
			<el-breadcrumb :separator-icon="ArrowRight">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/product_index' }">商品分类</el-breadcrumb-item>
				<el-breadcrumb-item>{{goodsDeatil.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
	<div class="container centerBox flex" v-loading='loading'>
		<div class='productBox flex'>
			<div class="leftBox">
				<div class="middle" ref='target'>
					<!-- 左侧的大图 -->
					<img class="main_img" :src='skusImg[imgIndex]' />
					<!-- 遮罩层 -->
					<div class="layer" :style='[position]' v-show='isShow'></div>
				</div>
				<div class="large" :style='[bgPosition,`background-image: url(${skusImg[imgIndex]})`]' v-show='isShow'>
				</div>
			</div>
			<div class="productSwiper">
				<div class="product-swiper-button product-swiper-button-prev flex center_center">
					<img src="../../assets/icon/ze-arrow-up.png" />
				</div>
				<swiper style="height: 420px;" :navigation="productSwiper_navigation" :modules="productSwiper_modules"
					:direction='"vertical"' :slides-per-view="4">
					<swiper-slide v-for="(item,index) in skusImg">
						<img class="product_one" :class="index == imgIndex ? 'active':''" :src="item"
							@click="imgIndex=index" />
					</swiper-slide>
				</swiper>
				<div class="product-swiper-button product-swiper-button-next flex center_center">
					<img src="../../assets/icon/ze-arrow-up.png" />
				</div>
			</div>
		</div>
		<div class="infoBox">
			<div class="title">{{goodsDeatil.name}}</div>
			<div class="desc">
				{{goodsDeatil.promotion}}
			</div>
			<div class="priceBox" v-if='goodsDeatil.skus.length>0'>
				<div class="lineText">
					销售价：<span
						class="redText">￥{{goodsDeatil.skus.length == 1 ? goodsDeatil.skus[0].price : skuId =='' ? goodsDeatil.skus[0].price:goodsDeatil.skus.find(obj => obj.id == skuId).price}}</span>
				</div>
				<div class="lineText">
					原价：<span
						class="grayText">￥{{goodsDeatil.skus.length == 1 ? goodsDeatil.skus[0].delete_price : skuId =='' ? goodsDeatil.skus[0].delete_price:goodsDeatil.skus.find(obj => obj.id == skuId).delete_price}}</span>
				</div>
			</div>
			<div class="tagBox" v-if='goodsDeatil.skus.length>0'>
				<span>货号：{{goodsDeatil.skus.length == 1 ? goodsDeatil.skus[0].sku_code : skuId =='' ? goodsDeatil.skus[0].sku_code:goodsDeatil.skus.find(obj => obj.id == skuId).sku_code}}</span>
				<span>品牌：{{goodsDeatil.brand_name}}</span>
			</div>
			<goodSku v-if='goodsDeatil.is_multi == 1' :goodsData='goodsDeatil' :skuId='skuId' @change='changeFn'>
			</goodSku>
			<div class="lineBox flex">
				<div class="text">数量：</div>
				<el-input-number size="small" v-model="num" :min="1" />
			</div>
			<div class="btnBox flex">
				<div class="buyBtn" @click="goSettleFn()">立即购买</div>
				<div class="addBtn" @click="addCarFn()">加入购物车</div>
				<div class="collectBtn" @click="favoriteAddApiFn()">
					<img v-if='!goodsDeatil.is_love' src="../../assets/icon/ze-star.png" />
					<img v-else src="../../assets/icon/ze-star-love.png" />
					{{goodsDeatil.is_love ? '取消收藏':'收藏'}}
				</div>
				<div class="collectBtn" @click="dialogVisible=true">
					<img src='../../assets/icon/ze-service.png' />
					客服
				</div>
			</div>
		</div>
	</div>
	<div class="container detailBox" v-loading='loading'>
		<div class="detail_nav">
			<span :class='detailIndex == 0 ? "active":""' @click="changeDetailIndexFn(0)">商品详情</span>
			<span :class='detailIndex == 1 ? "active":""' @click="changeDetailIndexFn(1)">商品属性</span>
			<span :class='detailIndex == 2 ? "active":""' @click="changeDetailIndexFn(2)">售后保障</span>
		</div>
		<template v-if='detailIndex == 0'>
			<div class="detail" v-html='goodsDeatil.detail_info'></div>
		</template>
		<template v-else-if='detailIndex == 1'>
			<div class="detail" v-if='goodsDeatil.is_multi==1'>
				<div v-for="(item,index) in goodsDeatil.specs" style="margin-bottom: 10px;">
					{{item.name}}:<template
						v-for="values in item.values">&nbsp;&nbsp;{{values.name}}&nbsp;&nbsp;</template>
				</div>
			</div>
			<div class="detail" v-else>
				普通属性
			</div>
		</template>
		<template v-else-if='detailIndex == 2'>
			<div class="detail">
				<div v-for="(item,index) in goodsDeatil.services" style="margin-bottom: 10px;">
					{{index+1}}、<img :src="item.icon_url" style="width: 16px;" />{{item.name}}
				</div>
			</div>
		</template>

	</div>
	<el-dialog
	    v-model="dialogVisible"
	    title="客服微信"
	    width="500"
	  >
	    <img style="width: 400px;margin: 0 auto;display: block;" :src='customer_service'/>
	    
	  </el-dialog>
</template>

<script setup>
	import {
		onMounted,
		ref,
		inject,
		reactive,
		watch,
		computed
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import {
		Navigation
	} from "swiper/modules";
	import 'swiper/css';
	import "swiper/scss/navigation";
	import {
		useMouseInElement
	} from '@vueuse/core'
	import goodSku from '@/components/product/goodSku.vue'
	import {
		getProductDetail,
		favoriteAddApi,
		favoriteDleteProductApi,
		carAddApi
	} from '@/api/index'
	import {
		ElMessage
	} from 'element-plus';
	import {
		getInfoApiFn
	} from '@/utils/getInfoApiFn'
	const route = useRoute();
	const router = useRouter();
	const productSwiper_navigation = ref({
		nextEl: '.product-swiper-button-next',
		prevEl: '.product-swiper-button-prev',
	});
	const productSwiper_modules = [Navigation];
	let dialogVisible = ref(false)
	let num = ref(1);
	const goodsDeatil = ref({
		name: '',
		promotion: '',
		skus: [],
		specs: [],
		brand_name: '',
	});
	let skuId = ref('');
	const goSettleFn = () => {
		let data = {
			count: num.value,
			product_id: goodsDeatil.value.id,
			product_name: goodsDeatil.value.name,
		}
		if (goodsDeatil.value.is_multi == 1) {
			if (skuId.value == '') {
				ElMessage({
					type: 'error',
					message: '请选择完整的规格！'
				})
				return;
			}
			data.spec_id = skuId.value;
			data.sku_pics = goodsDeatil.value.skus.find(obj => obj.id == skuId.value).img;
			data.sale_price = goodsDeatil.value.skus.find(obj => obj.id == skuId.value).price;
			data.spec_code = goodsDeatil.value.skus.find(obj => obj.id == skuId.value).sku_code;
			data.spec_title = goodsDeatil.value.skus.find(obj => obj.id == skuId.value).specs.map(item => item.valueName).join(' ');
		} else {
			data.spec_id = goodsDeatil.value.skus[0].id;
			data.sku_pics = goodsDeatil.value.skus[0].img;
			data.sale_price = goodsDeatil.value.skus[0].price;
			data.spec_code = goodsDeatil.value.skus[0].sku_code;
			data.spec_title = goodsDeatil.value.skus[0].specs.map(item => item.valueName).join(' ')
		}
		sessionStorage.setItem('order_carId', JSON.stringify([data]));
		router.push({
			name: 'cart_order_info'
		})
	}
	const skusImg = computed(() => {
		if (goodsDeatil.value.skus.length == 0) {
			return []
		} else {
			return skuId.value == '' ? goodsDeatil.value.pic_urls : goodsDeatil.value.skus.find(obj => obj.id ==
				skuId.value).img
		}
	})
	const changeFn = (data) => {
		skuId.value = data.skuId;
		// console.log('data', data)
	}
	const addCarFn = async () => {
		let data = {
			product_id: goodsDeatil.value.id,
			count: num.value,
		}
		if (goodsDeatil.value.is_multi == 1) {
			if (skuId.value == '') {
				ElMessage({
					type: 'error',
					message: '请选择完整的规格！'
				})
				return;
			}
			data.spec_id = skuId.value
		} else {
			data.spec_id = goodsDeatil.value.skus[0].id
		}
		const res = await carAddApi(data);
		ElMessage({
			type: 'success',
			message: '成功加入购物车！'
		})
	}
	const detailIndex = ref(0);
	let customer_service = ref('');
	onMounted(async() => {
		customer_service.value =await getInfoApiFn(15);
		customer_service.value = JSON.parse(customer_service.value)[0].url
		getProductDetailFn();
	})
	let loading =ref(true)
	const getProductDetailFn = () => {
		loading.value=true;
		getProductDetail(route.query.id).then(res => {
			// console.log(res);
			goodsDeatil.value = res.data;
			loading.value=false;
		})
	}
	const favoriteAddApiFn = async () => {
		if (goodsDeatil.value.is_love) {
			const data = {
				product_id: goodsDeatil.value.id
			}
			const res = await favoriteDleteProductApi(data);
			ElMessage({
				type: 'success',
				message: '取消收藏',
				onClose() {
					getProductDetailFn();
				}
			})
		} else {
			const data = {
				product_id: goodsDeatil.value.id
			}
			const res = await favoriteAddApi(data);
			ElMessage({
				type: 'success',
				message: '收藏成功',
				onClose() {
					getProductDetailFn();
				}
			})
		}
	}
	const changeDetailIndexFn = (index) => {
		detailIndex.value = index;
	}
	let imgIndex = ref(0);
	const target = ref(null);
	let isShow = ref(true);
	// 遮罩层的坐标
	let position = reactive({
		left: 0,
		top: 0
	})
	// 控制背景图的位置
	let bgPosition = reactive({
		backgroundPositionX: 0,
		backgroundPositionY: 0
	})
	let {
		elementX,
		elementY,
		isOutside
	} = useMouseInElement(target)
	watch([elementX, elementY, isOutside], () => {
		// 每次有值发生变化，就读取新的数据即可
		isShow.value = !isOutside.value
		// 鼠标在图片的区域之外，不需要计算坐标
		if (isOutside.value) return
		// 水平方向
		if (elementX.value < 100) {
			// 左边界
			position.left = 0
		} else if (elementX.value > 353) {
			// 右边界
			position.left = 251
		} else {
			// 中间的状态
			position.left = elementX.value - 100
		}
		// 垂直方向
		if (elementY.value < 100) {
			// 上边界
			position.top = 0
		} else if (elementY.value > 353) {
			// 下边界
			position.top = 251
		} else {
			// 中间的状态
			position.top = elementY.value - 100
		}
		// 计算预览大图背景的位置
		bgPosition.backgroundPositionX = -position.left * 1.6 + 'px'
		bgPosition.backgroundPositionY = -position.top * 1.6 + 'px'
		// 计算左侧遮罩层位置
		position.left += 'px'
		position.top += 'px'
	})
</script>

<style lang='scss' scoped>
	.productNavBox {
		height: 60px;
		background-color: rgba(239, 239, 239, 1);
	}

	.centerBox {
		padding: 30px 0;

		.productBox {
			.leftBox {
				position: relative;

				.middle {
					position: relative;

					.main_img {
						width: 451px;
						height: 451px;
						border: 1px solid rgba(187, 187, 187, 1);
					}

					.layer {
						width: 200px;
						height: 200px;
						background: rgba(0, 0, 0, 0.2);
						left: 0;
						top: 0;
						position: absolute;
						cursor: move;
					}
				}

				.large {
					position: absolute;
					top: 0;
					left: 553px;
					width: 400px;
					height: 400px;
					z-index: 9;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
					background-repeat: no-repeat;
					background-size: 800px 800px;
					background-color: #f8f8f8;
					/* background-image: url('../../assets/img/product.png'); */
				}
			}

			.productSwiper {
				width: 100px;
				height: 453px;
				background-color: rgba(239, 239, 239, 0.5);

				.swiper-slide {
					height: 67px;

					.product_one {
						width: 88px;
						height: 87px;
						/* height: 64px; */
						display: block;
						margin: 0 auto;
						cursor: pointer;
						border: 1px solid transparent;
					}

					.product_one.active {
						border: 1px solid #D30E0E;
					}
				}

				.product-swiper-button {
					cursor: pointer;
				}

				.product-swiper-button-next {
					transform: translateY(-5px);

					img {
						transform: rotate(180deg)
					}
				}
			}
		}

		.infoBox {
			margin-left: 20px;
			flex: 1;

			.title {
				color: rgba(16, 16, 16, 1);
				font-size: 20px;
				font-weight: bolder;
				text-align: left;
				font-family: AlibabaPuHui-medium;
			}

			.desc {
				color: rgba(108, 108, 108, 1);
				font-size: 12px;
				text-align: left;
				margin: 8px 0 13px;
				font-family: AlibabaPuHui-regular;
			}

			.priceBox {
				border-radius: 4px;
				background-color: rgba(239, 239, 239, 0.5);
				padding: 26px 0 26px 21px;

				.lineText {
					color: rgba(16, 16, 16, 1);
					font-size: 12px;
					text-align: left;
					font-family: AlibabaPuHui-regular;

					&:nth-of-type(1) {
						margin-bottom: 16px;
					}

					.redText {
						color: rgba(211, 14, 14, 1);
						font-size: 20px;
						text-align: left;
						font-family: AlibabaPuHui-medium;
					}

					.grayText {
						color: rgba(154, 154, 154, 1);
						font-size: 12px;
						text-align: left;
						font-family: Roboto;
						text-decoration: line-through;
					}
				}
			}

			.tagBox {
				margin: 15px 0;

				span {
					margin: 0 10px 10px 0;
					display: inline-block;
					padding: 0 10px;
					line-height: 27px;
					border-radius: 4px;
					background-color: rgba(242, 244, 251, 1);
					color: rgba(16, 16, 16, 1);
					font-size: 12px;
					text-align: center;
					font-family: Roboto;
				}
			}

			.lineBox {
				.text {
					color: rgba(16, 16, 16, 1);
					font-size: 14px;
					text-align: left;
					font-family: AlibabaPuHui-regular;
					margin-right: 10px;
				}
			}

			.btnBox {
				margin-top: 15px;

				.buyBtn {
					width: 100px;
					height: 40px;
					line-height: 40px;
					border-radius: 4px;
					background-color: rgba(233, 157, 66, 1);
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					text-align: center;
					font-family: Roboto;
					cursor: pointer;
					margin-right: 10px;
				}

				.addBtn {
					width: 120px;
					height: 40px;
					line-height: 40px;
					border-radius: 4px;
					background-color: rgba(211, 14, 14, 1);
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					text-align: center;
					font-family: Roboto;
					margin-right: 10px;
					cursor: pointer;
				}

				.collectBtn {
					height: 38px;
					border-radius: 4px;
					color: rgba(108, 108, 108, 1);
					font-size: 12px;
					padding: 0 8px;
					margin-right: 10px;
					cursor: pointer;
					text-align: center;
					font-family: Roboto;
					border: 1px solid rgba(187, 187, 187, 1);

					img {
						width: 20px;
						height: 20px;
						display: block;
						margin: 0 auto;
					}
				}
			}

		}
	}

	.detailBox {
		margin-top: 20px;
		margin-bottom: 30px;

		.detail_nav {
			border-bottom: 1px solid rgba(242, 244, 251, 1);
			margin-bottom: 30px;

			span {
				display: inline-block;
				width: 120px;
				height: 40px;
				line-height: 40px;
				background-color: rgba(239, 239, 239, 1);
				color: rgba(51, 51, 51, 1);
				font-size: 16px;
				text-align: center;
				font-family: Roboto;
				cursor: pointer;

				&.active {
					background-color: rgba(211, 14, 14, 1);
					color: rgba(255, 255, 255, 1);
				}
			}
		}
	}
</style>
