<template>
	<div class="container">
		<div v-loading='loading'>
			<div class="newBox flex between_center" v-if="currentType == '1'">
				
				<newsCom v-if="newsList" :newsList='newsList'></newsCom>
			</div>
			<div class="videoBox flex" v-if="currentType == '2'">
				<videoCom :videoList='newsList' currentType="2"></videoCom>
			</div>
			<div class="cardBox flex" v-if="currentType == '3'">
				<organismNewsCom :newsList='newsList'></organismNewsCom>
			</div>
			<el-pagination v-if='pageOption.total != 0' background layout="prev, pager, next" :total="pageOption.total"
				class="mt-4" v-model:page-size='pageOption.page_size' @current-change='currentChange' />
		</div>
		<el-empty :description='"暂无内容"' description="description" v-if='pageOption.total == 0' />
	</div>
</template>

<script setup>
	import {
		scientificIndexListApi,
		scientificvideoListApi
	} from '@/api/index.js'
	import newsCom from '@/components/newsList/com/newsCom.vue'
	import videoCom from '@/components/home/com/video.vue'
	import organismNewsCom from '@/components/home/com/organismNews.vue'
	import {
		ref,
		watch,
		onMounted
	} from 'vue'
	import {
		useRouter,
		useRoute
	} from 'vue-router';
	const router = useRouter();
	const route = useRoute();
	const newsList = ref([]);
	let firstId = ref();
	let classifyId = ref();
	let currentType = ref();
	let loading = ref(false);
	const pageOption = ref({
		page: 1,
		page_size: 24,
		total: 0,
	})
	const getScientificListFn = async (name) => {
		loading.value = true;
		const data = {
			id: classifyId.value == -1 ? firstId.value : classifyId.value,
			name,
			page: pageOption.value.page,
			page_size: pageOption.value.page_size
		}
		if (currentType.value == 1 || currentType.value == 3) { //1新闻   3卡片
			const res = await scientificIndexListApi(data);
			pageOption.value.total = res.data.total
			newsList.value = res.data.list;
			loading.value = false;
			// console.log("res", res);
		} else {
			const res = await scientificvideoListApi(data);
			pageOption.value.total = res.data.total
			newsList.value = res.data.list;
			loading.value = false;
			// console.log("res", res);
		}

	}
	const currentChange = (current) => {
		pageOption.value.page = current;
		getScientificListFn()
	}
	onMounted(() => {
		// classifyId.value = route.query.secondId ? route.query.secondId : -1;
		// firstId.value = route.query.id;
		// currentType.value = route.query.type;
		// getScientificListFn(route.query.text)
	})
	watch(() => router.currentRoute.value, (newValue) => {
		firstId.value = newValue.query.id;
		classifyId.value = newValue.query.secondId ? newValue.query.secondId : -1;
		currentType.value = newValue.query.type;
		getScientificListFn(route.query.text)
	}, {
		immediate: true
	})
</script>

<style lang="scss" scoped>
	.newBox {
		margin: 30px 0 15px 0;
		flex-wrap: wrap;
	}

	.videoBox {
		flex-wrap: wrap;
		margin-top: 30px;

		:deep(){
			.one_item {
				margin-right: calc((100% - 322 * 5px)/4);
				border: 1px solid rgba(239, 239, 239, 1);
			}
			.one_item:nth-of-type(5n) {
				margin-right: 0px;
			}
		}
	}

	.cardBox {
		margin: 30px 0 15px 0;
		flex-wrap: wrap;

		:deep() {
			.one-item {
				width: 320px;
				height: 270px;
				margin-right: calc((100% - 1600px)/4);

				img {
					width: 100%;
					height: 200px;
				}

				.item_on_title {
					margin-top: 13px;
				}
			}

			.one-item:nth-of-type(5n) {
				margin-right: 0px;
			}
		}
	}
</style>
