<template>
	<div class="title">订单详情</div>
	<div v-loading='loading'>
		<orderStatusCom :status='status' :orderNo='orderDetail.order_no' :orderId='route.query.id'></orderStatusCom>
		<div class="orderDetail flex">
			<div class="one_item flex">
				<div class="text_tit">订单编号：</div>
				<div class="text_info">{{orderDetail.order_no}} </div>
			</div>
			<div class="one_item flex" v-if='status == 1000 || status == 2000 || status == 3000 || status == 4000'>
				<div class="text_tit">创建时间：</div>
				<div class="text_info">{{orderDetail.order_time}} </div>
			</div>
			<div class="one_item flex" v-if='status == 2000 || status == 3000 || status == 4000'>
				<div class="text_tit">支付时间：</div>
				<div class="text_info">{{orderDetail.pay_time}}</div>
			</div>
			<div class="one_item flex" v-if='status == 4001 || status == 4000'>
				<div class="text_tit">下单时间：</div>
				<div class="text_info">{{orderDetail.order_time}}</div>
			</div>
			<div class="one_item flex" v-if='status == 4001 || status == 4000'>
				<div class="text_tit">订单完成时间：</div>
				<div class="text_info">{{orderDetail.finish_time}}</div>
			</div>
			<div class="one_item flex" v-if='status == 1 || status == 2|| status == 5|| status == 4|| status == 6'>
				<div class="text_tit">申请售后时间：</div>
				<div class="text_info">{{orderDetail.apply_time}}</div>
			</div>
			<!-- <div class="one_item flex" v-if='status == 6000'>
				<div class="text_tit">售后完成时间：</div>
				<div class="text_info">{{orderDetail.apply_time}}</div>
			</div> -->
		</div>
		<!--  收货信息  -->
		<template v-if='status == 4001 || status == 1000 || status == 2000 || status == 3000|| status == 4000'>
			<div class="redText">收货信息：</div>
			<div class="lineInfo"> 收件人姓名：{{orderDetail.address.receive_name}} </div>
			<div class="lineInfo"> 收件人电话：{{orderDetail.address.receive_telephone}} </div>
			<div class="lineInfo">
				收件人地址：{{orderDetail.address.province_name}}{{orderDetail.address.city_name}}{{orderDetail.address.area_name}}{{orderDetail.address.receive_address}}
			</div>
		</template>
		<!--  订单信息  -->
		<div class="redText">订单信息：</div>
		<div class="goodsBox flex" v-for="item in orderDetail.products">
			<img :src="item.product_pic_url" />
			<div class="centerBox">
				<div class="goods_title">{{item.product_name}}</div>
				<div class="goods_text" v-if='status == 4001 || status == 1000 || status == 2000 || status == 3000|| status == 4000'>货号：{{item.product_sku_spec_code}}</div>
				<div class="goods_text" v-if='status == 4001 || status == 1000 || status == 2000 || status == 3000|| status == 4000'>规格：
					<span v-if='item.is_multi == 1'>
						<template v-for="sku in item.product_sku_titles">
							{{sku.spec_title}}:{{sku.spec_value}}&nbsp;&nbsp;
						</template>
					</span>
					<span v-else>普通规格</span>
				</div>
			</div>
			<div class="price" v-if='status == 4001 || status == 1000 || status == 2000 || status == 3000|| status == 4000'>￥{{item.product_price}}×{{item.count}}</div>
			<div class="price" v-if='status == 1 || status == 2|| status == 5|| status == 4 || status == 7'>￥{{item.product_price}}×{{item.product_count}}</div>
			<div class="btn" v-if="item.status == 4001 || item.status == 4000" @click="applySales(item)">申请售后</div>
		</div>
		<template v-if='status == 4001 || status == 1000 || status == 2000 || status == 3000|| status == 4000'>
			<div class="redText">订单留言：</div>
			<div class="lineInfo"> {{orderDetail.buyer_message}}</div>
		</template>
		<template v-if='status == 1 || status == 2|| status == 5|| status == 4 || status == 7|| status == 6'>
			<div class="redText">退货类型：</div>
			<div class="lineInfo"> {{orderDetail.refund_type_desc}}</div>
			<div class="redText">退款原因：</div>
			<div class="lineInfo"> {{orderDetail.reason_desc}}</div>
			<div class="redText">退款说明：</div>
			<div class="lineInfo"> {{orderDetail.refund_remark}}</div>
			<div class="redText">退款流程：</div>
			<el-timeline>
				<el-timeline-item v-for="(activity, index) in orderDetail.logs" :key="index" :timestamp="activity.created_at" color= '#d30e0e'>
					{{ activity.content }}
				</el-timeline-item>
			</el-timeline>
		</template>
		<el-divider></el-divider>
		<div class="priceBox" v-if='status == 4001 || status == 1000 || status == 2000 || status == 3000|| status == 4000'>
			<div class="lineInfo">商品总金额：￥{{orderDetail.total_price}}</div>
			<div class="lineInfo">物流费用：￥{{orderDetail.delivery_money}}</div>
			<div class="lineInfo">应付金额：￥ {{orderDetail.total_price}}</div>
		</div>
		<div class="priceBox" v-if='status == 1 || status == 2|| status == 5|| status == 4 || status == 7'>
			<div class="lineInfo">商品总金额：￥{{orderDetail.products[0].product_price}}</div>
			<div class="lineInfo">预计退款金额：￥ {{orderDetail.products[0].refund_fee}}</div>
		</div>
		<div class="priceBox" v-if='status == 6'>
			<div class="lineInfo">商品总金额：￥{{orderDetail.products[0].product_price}}</div>
			<div class="lineInfo">实际退款金额：￥ {{orderDetail.products[0].refund_fee}}</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref,
		inject,
		provide
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		getOrderDetailApi,
		getOrderRefundInfoApi
	} from '@/api/index.js'
	import orderStatusCom from '@/components/person/order/orderStatusCom.vue'
	const route = useRoute();
	const router = useRouter();
	let loading = ref(false)
	let status = ref('');
	const orderDetail = ref({});
	const getOrderDetailApiFn = async () => {
		loading.value = true;
		let res = {}
		if (route.query.type == 'order') {
			res = await getOrderDetailApi({
				id: route.query.id
			});
			status.value = res.data.order_status;
		} else {
			res = await getOrderRefundInfoApi({
				id: route.query.id
			});
			status.value = res.data.status;
		}
		orderDetail.value = res.data;

		loading.value = false;
		// console.log('res', res)
	}
	provide('getOrderDetailApiFn', getOrderDetailApiFn)
	onMounted(() => {
		// console.log('res--->')
		getOrderDetailApiFn()
	})
	const applySales = (item) => {
		// console.log("orderDetail", orderDetail.value);
		// console.log("item", item);
		const data = {
			order_id: item.order_id,
			order_no: orderDetail.value.order_no,
			products: [{
				count: item.count,
				is_multi: item.is_multi,
				product_pic_url: item.product_pic_url,
				product_price: item.product_price,
				product_name: item.product_name,
				product_id: item.product_id,
				product_sku: item.product_sku,
				product_sku_spec_code: item.product_sku_spec_code,
				product_sku_titles: item.product_sku_titles
			}],
			refund_id: item.refund_id
		}
		sessionStorage.setItem('applySalesInfo', JSON.stringify(data));
		router.push({
			name: 'order_applySales',
		})
	}
</script>

<style lang="scss" scoped>
	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
	}

	.orderDetail {
		margin: 20px 0 20px;

		.one_item {
			margin-right: 20px;

			.text_tit {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-weight: bolder;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}

			.text_info {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}
		}
	}

	.redText {
		color: rgba(211, 14, 14, 1);
		font-size: 18px;
		text-align: left;
		font-family: AlibabaPuHui-regular;
		margin-bottom: 12px;
		margin-top: 20px;
	}

	.lineInfo {
		line-height: 25px;
		color: rgba(16, 16, 16, 1);
		font-size: 14px;
		text-align: left;
		font-family: AlibabaPuHui-regular;
	}

	.goodsBox {
		align-items: center;
		margin-bottom: 10px;

		img {
			width: 90px;
			margin-right: 20px;
		}

		.centerBox {
			width: 400px;
			margin-right: 44px;

			.goods_title {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				font-weight: bolder;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}

			.goods_text {
				color: rgba(16, 16, 16, 1);
				font-size: 14px;
				text-align: left;
				font-family: AlibabaPuHui-regular;
			}
		}

		.price {
			width: 100px;
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: left;
			font-family: AlibabaPuHui-regular;
			margin-right: 20px;
		}

		.btn {
			width: 75px;
			height: 35px;
			line-height: 35px;
			border-radius: 4px;
			background-color: rgba(211, 14, 14, 1);
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			text-align: center;
			font-family: Roboto;
			cursor: pointer;
		}
	}

	.priceBox {
		.lineInfo {
			text-align: right;
		}
	}
</style>
