<template>
	<div class="title">我的收藏</div>
	<el-table :data="orderList" v-loading="loading" border class="orderTable" :cell-style="{'text-align':'center'}"
		:header-cell-style="{'text-align':'center'}" style="width: 100%;" max-height="670" :empty-text="'暂无收藏的商品'"
		ref='tableRef'>
		<el-table-column prop="info" label="商品名称" width="500">
			<template #default="scope">
				<el-row @click="goDetail(scope.row.product_id)" style="cursor: pointer;">
					<el-col :span='3'>
						<img style="width: 100%;" :src="scope.row.product_pic_urls&&scope.row.product_pic_urls.length>0?scope.row.product_pic_urls[0]:''" />
					</el-col>
					<el-col :span='15'>
						{{scope.row.product_name}} 货号：{{scope.row.product_spec_code}}
					</el-col>
				</el-row>
			</template>
		</el-table-column>
		<el-table-column prop="sale_price" label="价格(元)" width="130">
			<template #default="scope">
				￥{{scope.row.sale_price}}
			</template>
		</el-table-column>
		<el-table-column prop="created_at" label="收藏时间" width="200" />
		<el-table-column prop="name" label="操作">
			<template #default="scope">
				<el-space>
					<el-link :underline="false" @click="goDetail(scope.row.product_id)">查看详情</el-link>
					<el-divider direction="vertical"></el-divider>
					<el-link :underline="false" @click="deleteVisibleFn(scope.row)">删除</el-link>
				</el-space>
			</template>
		</el-table-column>
	</el-table>
	<el-dialog class="cancellationBox" v-model="deleteVisible" title="删除提示" width="20%" :append-to-body='true' :lock-scroll='false'>
		<p class="tipsText">是否取消收藏</p>
		<div class="flex btnBox">
			<div class="equitBtn" @click="deleteVisible = false">取消</div>
			<div class="sureBtn" @click='favoriteAddApiFn'>确定</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import {
		onMounted,
		ref,
		inject
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import{favoriteListApi,favoriteDelApi} from '@/api/index'
import { ElMessage } from 'element-plus';
	const router = useRouter();
	const goDetail = (id) => {
		router.push({
			name: 'product_detail',
			query: {
				id,
			}
		})
	}
	let loading = ref(false)
	let orderList = ref();
	const tableRef = ref(null);
	const pageOptions = ref({
		page:1,
		page_size:20,
	})
	onMounted(()=>{
		getFavoriteListApiFn(0)
		const target=tableRef.value.$refs.bodyWrapper.getElementsByClassName("el-scrollbar__wrap")[0];
		target.addEventListener("scroll", () => {
			// 滚动距离
			let scrollTop = target.scrollTop;
			// 变量windowHeight是可视区的高度
			let windowHeight = target.clientHeight || target.clientHeight;
			// 变量scrollHeight是滚动条的总高度
			let scrollHeight = target.scrollHeight || target.scrollHeight;
			// 这里是触底 自己按自己业务需求是写逻辑
			if (scrollTop + windowHeight === scrollHeight) {
				// 获取到的不是全部数据 当滚动到底部 继续获取新的数据
				// console.log( "到底了！！！");
				if (pageOptions.value.total > orderList.value.length) {
					pageOptions.value.page = pageOptions.value.page + 1;
					getFavoriteListApiFn(1)
				}
			}
		})
	})
	const getFavoriteListApiFn=(type)=>{
		loading.value = true;
		loading.value = true;
		if (type == 0) {
			orderList.value = [];
			pageOptions.value.page = 1;
		}
		const data = {
			page: pageOptions.value.page,
			page_size: pageOptions.value.page_size
		}
		favoriteListApi(data).then(res => {
			pageOptions.value.total = res.data.total
			orderList.value = [...orderList.value, ...res.data.list];
			loading.value = false;
		})
	}
	let deleteVisible = ref(false);
	let deleteId =ref('')
	const deleteVisibleFn = (item)=>{
		deleteId.value = item.id
		deleteVisible.value=true;
	}
	const favoriteAddApiFn = async () => {
			const data = {
				id: deleteId.value
			}
			const res = await favoriteDelApi(data);
			ElMessage({
				type: 'success',
				message: '取消收藏',
				onClose() {
					deleteVisible.value=false;
					getFavoriteListApiFn(0)
				}
			})
		}
</script>


<style lang="scss" scoped>
	.title {
		color: #101010;
		font-size: 24px;
		font-weight: bolder;
		text-align: center;
		font-family: AlibabaPuHui-medium;
		margin-bottom: 23px;
	}
</style>